export const toHumanDate = (toformat: string) => {
    const date = new Date(toformat).toISOString()
    const day = date.substr(8, 2)
    const month = date.substr(5, 2)
    const year = date.substr(0, 4)
    const parsedDate = `${day}.${month}.${year}`
    return parsedDate
}

export const YEARS = ["2019", "2020", "2021", "2022", "2023", "2024"]

export const MONTHNAMES = [
    "Januar",
    "Februar",
    "März",
    "April",
    "Mai",
    "Juni",
    "Juli",
    "August",
    "September",
    "Oktober",
    "November",
    "Dezember",
]
export const WEEKDAYS = ["SO", "MO", "DI", "MI", "DO", "FR", "SA"]
export const LUXON_WEEKDAYS = ["MO", "DI", "MI", "DO", "FR", "SA", "SO"]
export const WEEKDAYS_LONG = ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
export const LUXON_WEEKDAYS_LONG = [
    "Sonntag",
    "Montag",
    "Dienstag",
    "Mittwoch",
    "Donnerstag",
    "Freitag",
    "Samstag",
    "Sonntag",
]

export const weekDay = (toformat: string, long: boolean) => {
    const date = new Date(toformat)
    const n = date.getDay()
    const parsedDate = long ? WEEKDAYS_LONG[n] : WEEKDAYS[n]
    return parsedDate
}
