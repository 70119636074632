import { get as getObjectPath } from "object-path"
import { isArrayLike, comparer } from "mobx"
const isEqual = comparer.structural

export type IPredicate =
    | IValuePredicate
    | IJunctorPredicate
    | INegationPredicate
    | IComparatorPredicate
    | IQuantorPredicate

export interface IValuePredicate {
    arg: true | false
}

function isValue(obj: object): obj is IValuePredicate {
    return "arg" in obj && ((obj as IValuePredicate).arg === true || (obj as IValuePredicate).arg === false)
}

export interface IJunctorPredicate {
    op: "and" | "or" // XOR
    args: IPredicate[]
}

function isJunctor(obj: object): obj is IJunctorPredicate {
    return "op" in obj && ((obj as IJunctorPredicate).op === "and" || (obj as IJunctorPredicate).op === "or")
}

export interface INegationPredicate {
    op: "not"
    arg: IPredicate
}

function isNegation(obj: object): obj is INegationPredicate {
    return "op" in obj && (obj as INegationPredicate).op === "not"
}

export interface IComparatorPredicate {
    op: "eq" | "contains"
    path: string
    arg: any
}

function isComparator(obj: object): obj is IComparatorPredicate {
    return (
        "op" in obj &&
        "path" in obj &&
        "arg" in obj &&
        ((obj as IComparatorPredicate).op === "eq" || (obj as IComparatorPredicate).op === "contains")
    )
}

export interface IQuantorPredicate {
    op: "all" | "any"
    path: string
    arg: IPredicate
}

function isQuantor(obj: object): obj is IQuantorPredicate {
    return (
        "op" in obj &&
        "path" in obj &&
        "arg" in obj &&
        ((obj as IQuantorPredicate).op === "all" || (obj as IQuantorPredicate).op === "any")
    )
}

function evaluateJunctor(junctor: IJunctorPredicate, object: any): boolean {
    switch (junctor.op) {
        case "and":
            return junctor.args.every((pred) => evaluate(pred, object))

        case "or":
            return junctor.args.some((pred) => evaluate(pred, object))
    }
}

function evaluateValue(value: IValuePredicate): boolean {
    return value.arg
}

function evaluateNegation(negation: INegationPredicate, object: any): boolean {
    return !evaluate(negation.arg, object)
}

function evaluateComparator(comparator: IComparatorPredicate, object: any): boolean {
    const { path, arg, op } = comparator
    const value = getObjectPath(object, path)

    switch (op) {
        case "eq":
            return isEqual(value, arg)

        case "contains":
            if (isArrayLike(value) || typeof value === "string") {
                return value.includes(arg)
            } else if (value == null) {
                return false
            } else {
                throw new Error(`ComparatorError: Can't check if ${value} "contains" ${arg} at path "${path}"`)
            }
    }
}

function evaluateQuantor(quantor: IQuantorPredicate, object: any): boolean {
    const { path, arg, op } = quantor
    const value = getObjectPath(object, path)

    if (!isArrayLike(value)) {
        throw new Error(`QuantorError: Value ${value} at path ${path} is not an array`)
    }

    switch (op) {
        case "all":
            return value.every((el) => evaluate(arg, el))

        case "any":
            return value.some((el) => evaluate(arg, el))
    }
}

export function evaluate(predicate: IPredicate, object: any): boolean {
    if (isValue(predicate)) {
        return evaluateValue(predicate)
    }

    if (isNegation(predicate)) {
        return evaluateNegation(predicate, object)
    }

    if (isJunctor(predicate)) {
        return evaluateJunctor(predicate, object)
    }

    if (isComparator(predicate)) {
        return evaluateComparator(predicate, object)
    }

    if (isQuantor(predicate)) {
        return evaluateQuantor(predicate, object)
    }

    throw new Error(`Invalid predicate ${predicate}`)
}
