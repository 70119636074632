import { Instance, types } from "mobx-state-tree"
import { history, historyHandler } from "./router"
import { setadditionalcookie, getCookie } from "../utils/cookiemanager"
import { authcheck } from "../api/login"

const GuiState = types
    .model({
        apphead: types.optional(types.string, ""),
        openday: types.optional(types.string, ""),
        username: types.maybeNull(types.string),
        email: types.maybeNull(types.string),
        userID: types.maybeNull(types.string),
        token: types.maybeNull(types.string),
        realName: types.maybeNull(types.string),
        mailsettings: types.maybeNull(types.optional(types.array(types.array(types.number)), [[], [], [], []])),
        recnews: types.optional(types.array(types.number), []),
        isAuth: types.optional(types.boolean, false),
        role: types.maybeNull(types.number),
        testtext: types.optional(types.string, ""),
        activemenu: types.optional(types.string, ""),
        from: types.maybeNull(types.number),
        to: types.maybeNull(types.number),
        meta: types.frozen(),
    })
    .actions((self: any) => {
        const actions = {
            updateTesttext: (data: string) => {
                self.testtext = data
            },
            logout: () => {
                setadditionalcookie({ name: "role", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "username", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "userid", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "realname", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "token", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "mail", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "mailsetting", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "meta", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "recnews", cookiecontent: "" }, -1)
                self.token = null
                self.userID = null
                self.isAuth = false
                history.push("/")
            },
            clearCookies: () => {
                setadditionalcookie({ name: "role", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "username", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "userid", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "realname", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "token", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "mail", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "mailsetting", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "meta", cookiecontent: "" }, -1)
                setadditionalcookie({ name: "recnews", cookiecontent: "" }, -1)
            },
            setLoginData: (
                username: string,
                userid: string,
                role: string,
                realname: string,
                setToken: boolean,
                mail: string,
                recnews: string[],
                mailsettings: number[][],
                meta: { address: string; mobile: string },
                token?: string,
            ) => {
                self.isAuth = true
                self.username = username
                self.userid = userid
                self.realname = realname
                self.email = mail
                self.recnews = recnews
                self.meta = meta
                if (mailsettings[3][0] == null) {
                    mailsettings[3] = []
                }
                self.mailsettings = mailsettings
                self.role = parseInt(role, 10)
                setadditionalcookie({ name: "mail", cookiecontent: self.email }, 7)
                setadditionalcookie({ name: "username", cookiecontent: self.username }, 7)
                setadditionalcookie({ name: "userid", cookiecontent: self.userid }, 7)
                setadditionalcookie({ name: "recnews", cookiecontent: JSON.stringify(recnews) }, 7)
                setadditionalcookie({ name: "mailsetting", cookiecontent: JSON.stringify(mailsettings) }, 7)
                setadditionalcookie({ name: "realname", cookiecontent: self.realname }, 7)
                setadditionalcookie({ name: "meta", cookiecontent: JSON.stringify(meta) }, 7)
                if (setToken) {
                    if (token) {
                        actions.setToken(token)
                    }
                }
                if (mail.length > 0) {
                    historyHandler("/tagesabrechnung")
                }
            },
            load: () => {
                try {
                    actions.setAuth(true)
                    // self.loaded = true
                } catch (err) {
                    console.error("err", err)
                    // if unauthorised clear the session in the local storage
                    // if (session.has && err.response != null && err.response.status === 401) {
                    //     apiCli.setToken(null)
                    // }
                    self.setAuth(false)
                    // self.loaded = true
                }
            },
            setActiveMenu: (menu: string) => {
                self.activemenu = menu
            },
            setAuth: (auth: boolean) => {
                self.auth = auth
            },
            setFrom: (from: number | null) => {
                self.from = from
            },
            setTo: (to: number | null) => {
                self.to = to
            },
            setToken: (token: string | null) => {
                self.token = token
                if (token != null) {
                    setadditionalcookie({ name: "token", cookiecontent: token }, 7)
                } else {
                    setadditionalcookie({ name: "role", cookiecontent: "" }, -1)
                    setadditionalcookie({ name: "token", cookiecontent: "" }, -1)
                    history.push("/")
                }
            },
            setEmail: (mail: string | null) => {
                self.email = mail
            },
            checkAuth: () => {
                return authcheck().then((res) => {
                    if (res === "ok") {
                        // console.log("auth ok")
                        actions.setAuth(true)
                        return true
                    } else {
                        actions.setAuth(false)
                        actions.clearCookies()
                        return false
                    }
                })
            },
            parseMailSettingFromStr(setting: string): string[][] {
                const parsedSetting = setting.split(".")
                const newSetting = parsedSetting.map((item) => {
                    return item.split(",")
                })
                return newSetting
            },
            parseMailSettingToStr(setting: string[][]): string {
                const mailsettingstring = setting.join(".")
                return mailsettingstring
            },
            getToken: (): string => {
                return getCookie("token")
            },
            updateApphead: (apphead: string) => {
                self.apphead = apphead
            },
            updateOpenday: (openday: string) => {
                self.openday = openday
            },
        }
        return actions
    })

export type IGuiState = Instance<typeof GuiState>
const guiInst = GuiState.create({})
guiInst.load()
export default guiInst
