import { getLatestVersion } from "../api/getnews"
import newsitems from "../stores/news"
import dotenv from "dotenv"

export const periodicNewsRequest = () => {
    console.info("live service disabled")
    // getnewsts(userid, nowTs0).then((res) => {
    //     newsitems.addItems(res)
    // })
    // setInterval(() => {
    //     const nowTs = Math.round((new Date()).getTime() / 1000);
    //     getnewsts(userid, nowTs).then((res) => {
    //         newsitems.addItems(res)
    //     })
    // }, 5000);
}

export const periodicVersionRequest = () => {
    console.info("version service enabled")
    dotenv.config()
    const VERSION: string = "v" + process.env.REACT_APP_VERSION_NUM
    setInterval(() => {
        getLatestVersion().then((res) => {
            const lastindex = res.length - 1
            if (res[lastindex].version !== VERSION) {
                newsitems.addVersionItems(res)
            }
        })
    }, 10000)
}
