import $ from "jquery"
import { getCookie } from "../utils/cookiemanager"

export interface ILoginResult {
    response: {
        username: string
        id: string
        token: string
        role: string
        realname: string
        mailsettings: number[][]
    }
}

export const login = (username: string, password: string): Promise<ILoginResult> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/abrlogin.php"
    const name = username.length > 0 ? username : ""
    const pw = password.length > 0 ? password : ""
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                name,
                pw,
                type: "json",
            },
            dataType: "json",
        }).then((res, err) => {
            resolve(res)
        })
    })
}

export const authcheck = (): Promise<any> => {
    const cookie = getCookie("token")
    const id = getCookie("userid")
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/auth.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: "checkauth",
                token: cookie,
                id,
                type: "json",
            },
            dataType: "json",
        })
            .then((result) => {
                resolve(result)
            })
            .always((abc: any) => {
                // return abc.responseText
                resolve(abc.responseText)
            })
    })
}
