import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect, Route, RouteComponentProps } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import genGetUrl, { TGetUrl } from "../../utils/geturl"
import userdata from "../../stores/userdata"
import { updateApphead, updateDay } from "../../utils/dommanip"
import ViewProfile from "./viewProfile"
import EditProfile from "./editProfile"

const basepath = "/profil"

export const { getUrl, jumpTo } = genGetUrl((opt: TGetUrl): string => {
    switch (opt.type) {
        case "view":
            return `${basepath}/view`
        case "edit":
            return `${basepath}/edit`
        default:
            return basepath
    }
})

interface IProfileProps extends RouteComponentProps<{}> { }

@observer
class Profile extends Component<IProfileProps, {}> {
    constructor(props: any, context?: any) {
        super(props, context)

        this.showMain = this.showMain.bind(this)
        this.viewProfile = this.viewProfile.bind(this)
        this.editProfile = this.editProfile.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"> </Redirect>
        }
        return (
            <React.Fragment>
                <Route path={getUrl({ type: "" })} exact render={this.showMain} />
                <Route path={getUrl({ type: "view" })} exact render={this.viewProfile} />
                <Route path={getUrl({ type: "edit" })} exact render={this.editProfile} />
            </React.Fragment>
        )
    }

    public componentDidMount() {
        updateDay("")
        updateApphead("Profil", "")
        userdata.load()
    }

    private showMain(): JSX.Element {
        return this.viewProfile()
    }

    private viewProfile(): JSX.Element {
        return <ViewProfile></ViewProfile>
    }

    private editProfile(): JSX.Element {
        return <EditProfile></EditProfile>
    }
}

export default Profile
