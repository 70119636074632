import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import "./personalverzehr.scss"

import "react-toastify/dist/ReactToastify.css"
import DetailView from "../../components/detailview"
import { jumpTo } from "."
import { IUserItem } from "../../stores/userdata"
import personalverzehr from "../../stores/allpersverzehr"
import bind from "bind-decorator"
import Loading from "../../components/loading"
import Table from "../../components/table"
import { isNum } from "../../utils/numvalidator"
import { DateTime } from "luxon"
import LocationPicker from "../../components/locationpicker"

interface IEmployeesViewProps {
    user: IUserItem
}

interface IPersonalEditState {
    inputamount: string
    verzehramount: string
    locationID: string
}

@observer
class PersonalEdit extends Component<IEmployeesViewProps, IPersonalEditState> {
    constructor(props: any) {
        super(props)
        this.state = {
            inputamount: "",
            verzehramount: "",
            locationID: "1",
        }
    }

    public render() {
        const { user } = this.props
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        return (
            <DetailView
                header={() => this.renderHeader(user)}
                content={() => this.renderContent(user)}
                onClose={() => jumpTo({ type: "view", id: user.userID })}
            ></DetailView>
        )
    }

    public componentDidMount() {
        const { user } = this.props
        personalverzehr.load(user.personalID)
    }

    private renderHeader(user: IUserItem) {
        return <h3>{user.realName}</h3>
    }
    @bind
    private renderContent(user: IUserItem) {
        const { inputamount, verzehramount, locationID } = this.state
        const date = DateTime.local().toFormat("yyyy-MM-dd")
        if (personalverzehr.loading) {
            return <Loading></Loading>
        }

        const { columns, data } = personalverzehr.tableView
        return (
            <div>
                <span>Gesamt: {personalverzehr.gesamt} €</span>
                <br></br>
                <br></br>
                <div className="input-areas">
                    <span className="operation">Einzahlung</span>
                    <input
                        type="number"
                        value={inputamount}
                        placeholder="0"
                        onChange={(ev) => {
                            ev.persist()
                            let value = inputamount
                            if (isNum(ev.target.value)) {
                                value = ev.target.value
                            }
                            this.setState({
                                inputamount: value,
                            })
                        }}
                    ></input>
                    <LocationPicker
                        onSelect={(ev) => {
                            ev.persist()
                            this.setState({
                                locationID: ev.target.value,
                            })
                        }}
                    ></LocationPicker>
                    <button
                        onClick={() => {
                            if (inputamount !== "0" && inputamount !== "") {
                                personalverzehr.update(user.personalID, "-" + inputamount, date, locationID)
                                this.setState({
                                    inputamount: "0",
                                })
                                personalverzehr.load(user.personalID)
                            }
                        }}
                    >
                        OK
                    </button>
                    <br></br>
                    <span className="operation">Konsum</span>
                    <input
                        type="number"
                        value={verzehramount}
                        placeholder="0"
                        onChange={(ev) => {
                            ev.persist()
                            let value = verzehramount
                            if (isNum(ev.target.value)) {
                                value = ev.target.value
                            }
                            this.setState({
                                verzehramount: value,
                            })
                        }}
                    ></input>
                    <LocationPicker
                        onSelect={(ev) => {
                            ev.persist()
                            this.setState({
                                locationID: ev.target.value,
                            })
                        }}
                    ></LocationPicker>
                    <button
                        onClick={() => {
                            if (verzehramount !== "0" && verzehramount !== "") {
                                personalverzehr.update(user.personalID, verzehramount, date, locationID)
                                this.setState({
                                    verzehramount: "0",
                                })
                                personalverzehr.load(user.personalID)
                            }
                        }}
                    >
                        OK
                    </button>
                </div>
                <br></br>
                <Table
                    columns={columns}
                    data={data}
                    // onSort={this.onSort}
                    // unfolded={tag.unfolded}
                    sortKey={personalverzehr.sortCol}
                    colSort={personalverzehr.sortAsc}
                // onOpen={this.onOpen}
                ></Table>
            </div>
        )
    }
}

export default PersonalEdit
