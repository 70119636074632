import "./notepopout.scss"

import { observer } from "mobx-react"
import React, { Component } from "react"

import { IOverview } from "../../stores/overviews"

interface IOverviewPopoutState {
    open: boolean
    currentcontent: string
}

interface IOverviewPopoutProps {
    overview: IOverview
}

interface IOverviewLine {
    title: string,
    subtitle?: string,
    value: string | number,
    unit?: string
}

const renderOverviewLine = ({ title, subtitle, value, unit }: IOverviewLine) => {
    return <span>
        <div className="overview-key">
            <span className="title">{title}</span>
            {subtitle != null && <span className="text-xs subtitle">{subtitle}</span>}
        </div>

        <div className="overview-value">{value}</div>
        {unit != null && <div className="format">{unit}</div>}
    </span>
}

@observer
class OverviewPopout extends Component<IOverviewPopoutProps, IOverviewPopoutState> {
    constructor(props: any) {
        super(props)

        this.state = {
            open: false,
            currentcontent: "",
        }
    }

    public render() {
        const { open } = this.state
        const { overview } = this.props

        return (
            <React.Fragment>
                <div className="top-action-button"
                    onClick={() => {
                        this.setState({
                            open: true,
                        })
                    }}
                >
                    Übersicht
                </div>
                {open && (
                    <div className={"popout-dialog overview-popout"}>
                        <h3>Übersicht</h3>
                        <ul>
                            {overview.detailView().map((el) => {
                                return <li>{renderOverviewLine(el)}</li>
                            })}
                        </ul>
                        <div className="dialog-buttons">
                            <div
                                className="dialog-button"
                                onClick={() => {
                                    this.setState({
                                        open: false,
                                    })
                                }}
                            >
                                Schließen
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default OverviewPopout
