import $ from "jquery"

import { getCookie } from "../utils/cookiemanager"

export interface IOverviewApi {
    id: string | null
    overviewID: string
    date: string
    locationID: string
    guestcount: string
    guestlistcount: string
    entrancePrice: string
    earnings: string
    pku: string
    entranceEarnings: string
    eventName: string
    gainPositions: string
    salaries: string
    barrechnungen: string
    garderobeneinnahmen: string
    gastroumsatz: string
    cardpayments: string
    personalverzehr: string
    savedBy: string
}

// export interface ILocationResponse {
//     response: ILocation
// }

export const saveoverview = (data: { overviewdata: IOverviewApi }): Promise<any> => {
    const { overviewdata } = data
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=saveoverview"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `saveOverview`,
                overviewdata,
                savedBy: getCookie("userid"),
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const getoverviews = (from?, to?): Promise<IOverviewApi[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getoverviews"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getOverviewsTest`,
                from,
                to,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const getsingleoverview = (date, location): Promise<IOverviewApi[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getsingleoverview"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getSingleOverviewTest`,
                locationID: location,
                date,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}
