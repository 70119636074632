import "./notepopout.scss"

import { observer } from "mobx-react"
import React, { Component } from "react"

import overviews, { Overview } from "../../stores/overviews"
import { getCookie } from "../../utils/cookiemanager"

interface IGLPopoutState {
    open: boolean
    currentcontent: string
}

interface IGLPopoutProps {
    location: string
    date: string
}

@observer
class GLPopout extends Component<IGLPopoutProps, IGLPopoutState> {
    constructor(props: any) {
        super(props)

        this.state = {
            open: false,
            currentcontent: "",
        }
    }

    public render() {
        const { open } = this.state
        const { date, location } = this.props
        let overview = overviews.findByLocAndDate(location, date)
        if (!overview) {
            const userid = getCookie("userid")
            overview = Overview.create({
                locationID: location,
                date,
                savedBy: userid,
            })
        }
        return (
            <React.Fragment>
                <div className="top-action-button" onClick={() => {
                    // overviews.loadSingle(date, location)
                    this.setState({
                        open: true,
                    })
                }}
                >
                    Gästeliste
                </div>
                {open && (
                    <div className={"popout-dialog gl-popout"}>
                        <h4>Gästeliste</h4>
                        <input
                            type="number"
                            value={overview.guestlistcount}
                            onChange={(ev) => {
                                ev.persist()
                                if (overview) {
                                    overview.updateGL(ev.target.value)
                                }
                            }}
                        />
                        <div className="dialog-buttons">
                            <div
                                className="dialog-button"
                                onClick={() => {
                                    this.setState({
                                        open: false,
                                    })
                                }}
                            >
                                Schließen
                            </div>
                            <div
                                className="dialog-button"
                                onClick={() => {
                                    // const userid = getCookie("userid")
                                    if (overview) {
                                        overview.save(() => this.setState({ open: false }))
                                    }
                                }}
                            >
                                Speichern
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default GLPopout
