import { Add } from "@material-ui/icons"
import { DateTime } from "luxon"
import { observer } from "mobx-react"
import React, { Component } from "react"
import { Redirect } from "react-router"

import { jumpTo } from "."
import HeaderFilter from "../../components/Headerfilter"
import Loading from "../../components/loading"
import Table from "../../components/table"
import einteilungforms, { IAbrechnungForm } from "../../stores/abrechnungforms"
import guistate from "../../stores/guistate"
import wallets from "../../stores/wallets"
import { getCookie } from "../../utils/cookiemanager"
import { updateDay } from "../../utils/dommanip"

interface IListState {
    statusfilter: string[]
    clubfilter: string[]
}

export const renderStatus = (obj) => {
    switch (obj.status) {
        case "0":
            return "Entwurf"
        case "1":
            return "Offen"
        case "2":
            return "Geschlossen"
        default:
            return ""
    }
}

@observer
class DayList extends Component<{}, IListState> {
    constructor(props: any) {
        super(props)

        this.state = {
            statusfilter: ["0", "1", "2"],
            clubfilter: ["1", "2", "3", "4", "6"],
        }

        this.filter = this.filter.bind(this)
        this.confirmFilter = this.confirmFilter.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        const { clubfilter, statusfilter } = this.state
        const { columns, data } = einteilungforms.tableView(true, clubfilter, null, statusfilter)
        return (
            <React.Fragment>
                {!einteilungforms.loaded && <Loading />}
                {
                    <HeaderFilter
                        filteroptions={einteilungforms.filteroptions(true)}
                        currentstate={{
                            Status: this.state.statusfilter,
                            Club: this.state.clubfilter,
                        }}
                        onConfirm={this.confirmFilter}
                        onDateConfirm={this.onDateConfirm}
                        onOptionFilter={this.filter}
                        withdatefilter={true}
                    />
                }
                <Table
                    columns={columns}
                    data={data}
                    onSort={this.onSort}
                    // unfolded={tag.unfolded}
                    sortKey={einteilungforms.sortCol}
                    colSort={einteilungforms.sortAsc}
                    onOpen={this.onOpen}
                />
                {/* this.renderNewEinteilungButton() */}
            </React.Fragment>
        )
    }

    public componentDidMount() {
        updateDay("")
        // if (!userdata.loaded) {
        //     userdata.load()
        // }
        if (einteilungforms.loaded) {
            einteilungforms.setFilterObj({ status: ["0", "1", "2"] })
        } else {
            einteilungforms.load({})
        }
    }

    private renderNewEinteilungButton() {
        return (
            <div
                className="action-icon"
                onClick={() => {
                    jumpTo({ type: "new" })
                }}
            >
                <Add></Add>
                <span>Neue Abrechnung</span>
            </div>
        )
    }

    private onSort(key: string, dir: boolean) {
        einteilungforms.setSortCol(key)
        einteilungforms.setSortDir(dir)
    }

    private onOpen(item: IAbrechnungForm) {
        wallets.resetWallets()
        jumpTo({ type: "viewlist", id: item.einteilungID })
    }

    private onDateConfirm(data) {
        let from = Math.round(DateTime.fromISO(data.from).toMillis() / 1000)
        let to = Math.round(DateTime.fromISO(data.to).toMillis() / 1000)
        if (data.from == null) {
            from = Math.round(
                DateTime.local()
                    .plus({ weeks: 2 })
                    .toMillis() / 1000,
            )
        }
        if (data.to == null) {
            to = Math.round(
                DateTime.local()
                    .minus({ weeks: 2 })
                    .toMillis() / 1000,
            )
        }
        guistate.setFrom(from)
        guistate.setTo(to)
        einteilungforms.load({ from, to })
    }

    private filter(filterstr: string, add: boolean, truncate: boolean, source: string) {
        const { clubfilter, statusfilter } = this.state
        const newfilter: string[] = []
        const newstatusfilter: string[] = []
        clubfilter.forEach((el) => {
            newfilter.push(el)
        })
        statusfilter.forEach((el) => {
            newstatusfilter.push(el)
        })

        if (truncate) {
            this.setState({
                clubfilter: [],
                statusfilter: [],
            })
        } else {
            if (source === "Club") {
                if (add) {
                    newfilter.push(filterstr)
                    this.setState({
                        clubfilter: newfilter,
                    })
                } else if (!add) {
                    newfilter.splice(newfilter.indexOf(filterstr), 1)
                    this.setState({
                        clubfilter: newfilter,
                    })
                }
            } else if (source === "Status") {
                if (add) {
                    newstatusfilter.push(filterstr)
                    this.setState({
                        statusfilter: newstatusfilter,
                    })
                } else if (!add) {
                    newstatusfilter.splice(newstatusfilter.indexOf(filterstr), 1)
                    this.setState({
                        statusfilter: newstatusfilter,
                    })
                }
            }
        }
    }

    private confirmFilter(filter) {
        einteilungforms.setFilterObj({
            locationIDs: filter.Club,
            status: filter.Status,
        })
    }
}

export default DayList
