import $ from "jquery"

import { getCookie } from "../utils/cookiemanager"

export interface IPositionApi {
    positionID: string
    positionName: string
    positionShort: string
    positionType: string
    sortNo: string
    locationID: string
    starttime: string
    showineinteilung: string
    modes: string
}

export interface IPositionTypeApi {
    typeID: string
    description: string
    umsatzgenerierend: string
    stundenlohn_assistent: string
    stundenlohn_theke: string
    umsatzanteil: string
}

export const getpositions = (): Promise<IPositionApi[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getpositions"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getPositions`,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const getpositiontypes = (): Promise<IPositionTypeApi[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getpositiontypes"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getPositionTypes`,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}
