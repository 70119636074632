import jsPDF from "jspdf"
import { IAbrechnungForm } from "../stores/abrechnungforms"
import { IPosition } from "../stores/positions"
import locations from "../stores/locations"
import { toHumanDate, weekDay } from "./dateformatter"

export const exportSingleEinteilung = (einteilung: IAbrechnungForm, positions: IPosition[]) => {
    const doc = new jsPDF()
    const parseddate = weekDay(einteilung.date, true) + " " + toHumanDate(einteilung.date)
    const location = locations.find(einteilung.locationID)
    if (location) {
        doc.setFontSize(16)
        doc.text(location.locationName, 10, 10)
        doc.setFontSize(15)
        doc.text(parseddate, 70, 10)
        if (positions) {
            positions.forEach((item, idx) => {
                const eingeteilt = einteilung.findForPos(item.positionID)
                if (item.showineinteilung === "1") {
                    if (eingeteilt) {
                        const einteilungPos = einteilung.findForPos(item.positionID)
                        const time = einteilungPos
                            ? einteilungPos.time !== ""
                                ? einteilungPos.time
                                : item.starttime
                            : item.starttime
                        doc.setFontSize(12)
                        doc.text(item.positionName, 10, 20 + idx * 11)
                        doc.setFontSize(10)
                        doc.text(eingeteilt.name, 90, 20 + idx * 11)
                        doc.text(time, 140, 20 + idx * 11)
                    } else {
                        doc.setFontSize(12)
                        doc.text(item.positionName, 10, 20 + idx * 11)
                        doc.setFontSize(10)
                        doc.text("offen", 90, 20 + idx * 11)
                        doc.text(item.starttime, 140, 20 + idx * 11)
                    }
                }
            })
            doc.save(`Einteilung ${parseddate}.pdf`)
        }
    }
}

export const printListAsPdf = (list: Array<string | undefined>, month: string, year: string) => {
    const doc = new jsPDF()
    doc.setFontSize(12)
    let index = 0
    list.filter((elem) => elem != null).forEach((el, idx) => {
        index++
        doc.text(el, 10, index * 8)
        if (index === 35) {
            doc.addPage()
            index = 0
        }
    })
    doc.save(`Nicht gearbeitet ${month} ${year}.pdf`)
}
