import "./notepopout.scss"

import { observer } from "mobx-react"
import React, { Component } from "react"

import { IOverview } from "../../stores/overviews"

interface ITitlePopoutState {
    open: boolean
    currentcontent: string
}

interface ITitlePopoutProps {
    overview: IOverview
}

@observer
class TitlePopout extends Component<ITitlePopoutProps, ITitlePopoutState> {
    constructor(props: any) {
        super(props)

        this.state = {
            open: false,
            currentcontent: "",
        }
    }

    public render() {
        const { open } = this.state
        const { overview } = this.props
        return (
            <React.Fragment>
                <div
                    className="top-action-button"
                    onClick={() => {
                        this.setState({
                            open: true,
                        })
                    }}
                >
                    {overview.eventName.length > 0 ? overview.eventName : "Eventname"}
                </div>
                {open && (
                    <div className={"popout-dialog entrance-popout"}>
                        <h4>Eventname</h4>
                        <input
                            type="text"
                            value={overview.eventName}
                            onChange={(ev) => {
                                ev.persist()
                                if (overview) {
                                    overview.updateEventName(ev.target.value)
                                }
                            }}
                        />
                        <div className="dialog-buttons">
                            <div
                                className="dialog-button"
                                onClick={() => {
                                    this.setState({
                                        open: false,
                                    })
                                }}
                            >
                                Schließen
                            </div>
                            <div
                                className="dialog-button"
                                onClick={() => {
                                    // const userid = getCookie("userid")
                                    if (overview) {
                                        overview.save(() => this.setState({ open: false }))
                                    }
                                }}
                            >
                                Speichern
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default TitlePopout
