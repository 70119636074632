import { history } from "../stores/router"

interface IGetUrlBase {
    type: string
    [key: string]: string | number | undefined
}
interface IGetUrlList extends IGetUrlBase {
    type: "list"
}
interface IGetUrlNew extends IGetUrlBase {
    type: "new"
}
interface IGetUrlView extends IGetUrlBase {
    type: "view"
    id: string
}
interface IGetUrlEdit extends IGetUrlBase {
    type: "edit"
    id: string
}
export type TGetUrl = IGetUrlBase | IGetUrlList | IGetUrlNew | IGetUrlView | IGetUrlEdit

export type TFnSwitch = (opt: TGetUrl) => string

// eslint-disable-next-line import/no-anonymous-default-export
export default (fnSwitch: TFnSwitch) => {
    const jumpTo = (opt: TGetUrl): void => {
        const path = fnSwitch(opt)
        if (path) {
            history.push(path)
        }
    }
    return { getUrl: fnSwitch, jumpTo }
}
