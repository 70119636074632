import ReactHTMLTableToExcel from "react-html-table-to-excel"
import React, { Component } from "react"
import Tooltip from "rc-tooltip"

import userdata from "../stores/userdata"
interface IUserRows {
    id: string
    days: Array<{ day: number; salary: number; hours: number }>
}

export interface ITableInterface {
    month: number
    year: number
    club: string
    data: IUserRows[]
}

export class TextTable extends Component<ITableInterface, {}> {

    public render() {
        const { data, club } = this.props
        return (
            <Tooltip overlay="Zusammenfassung Monat" placement="bottom">
                <div>
                    <ReactHTMLTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="table-to-xls"
                        filename="table"
                        sheet="table"
                        buttonText={club}
                    />
                    <table style={{ display: "none" }} id="table-to-xls">
                        {/* <tr>
                        <th>Firstname</th>
                    </tr> */}
                        <tbody>
                            {data.map((el, rowindex) => {
                                const user = userdata.findByPersonalID(el.id)
                                return (
                                    <tr key={rowindex}>
                                        <td>{user ? user.realName : el.id}</td>
                                        {el.days.map((singleday, tdindex) => {
                                            return (
                                                <React.Fragment key={tdindex}>
                                                    <td>{singleday.hours}</td>
                                                    <td>{singleday.salary}</td>
                                                </React.Fragment>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </Tooltip>
        )
    }
}
