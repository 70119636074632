import "./tagesabrechnung.scss"
import "rc-tooltip/assets/bootstrap.css"

import { AccountBalance, BusinessCenter, FolderShared, Info, Receipt } from "@material-ui/icons"
import Tooltip from "rc-tooltip"
import React from "react"

import abrechnungen from "../../stores/abrechnung"
import locations from "../../stores/locations"
import positions, { IPosition } from "../../stores/positions"
import userdata from "../../stores/userdata"
import { IDayExportSingle } from "../../utils/excelexport"

export interface IAbrechnungItemPos {
    name: string | JSX.Element,
    start: string,
    end: string,
    uberweisung: string,
    rechnung: string,
    hours: number,
    earnings: string | number,
    salary: string | number | JSX.Element,
    sum: string | number | JSX.Element,
    paidFrom: string,
    pays: string[],
    positionname: string | JSX.Element,
    misc: boolean
}


export const formatAbrForExport = (obj: IPosition, entered): IDayExportSingle => {
    const entry = abrechnungen.items.filter((el) => el.positionID === obj.positionID)
    const locData = locations.find(obj.locationID)
    // let defaultPays: string[] = []
    if (locData) {
        // defaultPays = locData.defaultPays
    }
    let positionName = obj.positionName
    if (entry.length > 0) {
        // const user = userdata.find(entry[0].personalID)
        const user = userdata.items.filter((el) => el.personalID === entry[0].personalID && entry[0].personalID !== "")
        if (user.length > 0) {
            const start = entry[0].start !== "" ? entry[0].start : obj.starttime
            const name = user[0].realName
            const ende = entry[0].end !== "" ? entry[0].end : "00:00"
            const stunden = entry[0].hours !== "" ? parseFloat(entry[0].hours) : 0
            const umsatz = entry[0] ? parseFloat(entry[0].earnings) : 0
            const lohn = entry[0] ? parseFloat(entry[0].salary) : 0
            const summe = entry[0] ? parseFloat(entry[0].sum) : 0
            const ueberweisung = entry[0].ueberweisung === "1"
            const rechnung = entry[0].rechnung === "1"
            positionName = entry[0].miscTitle === "" ? obj.positionName : entry[0].miscTitle
            if (obj.positionType === "17" && name === "") {
                return {
                    personalID: "",
                    name,
                    positionName: "",
                    start: "",
                    ende: "",
                    stunden: 0,
                    umsatz: 0,
                    lohn: 0,
                    summe: 0,
                    ueberweisung: false,
                    rechnung: false,
                }
            }
            return {
                personalID: user[0].personalID,
                name,
                positionName,
                start,
                ende,
                stunden,
                umsatz,
                lohn,
                summe,
                ueberweisung,
                rechnung
            }
        }
    }
    return {
        personalID: "",
        name: "",
        positionName: "",
        start: "",
        ende: "",
        stunden: 0,
        umsatz: 0,
        lohn: 0,
        summe: 0,
        ueberweisung: false,
        rechnung: false

    }
}


export const renderAbrechnungData = (
    obj: IPosition,
    entered: Array<{
        positionID: string,
        personalID: string,
        // tslint:disable-next-line: trailing-comma
        starttime: string
    }>,
): IAbrechnungItemPos => {
    abrechnungen.gatherPaysEntries()
    const entry = abrechnungen.items.filter((el) => el.positionID === obj.positionID)
    // const enteredPersonalArray = entered.filter((el) => el.positionID === obj.positionID)
    const locData = locations.find(obj.locationID)
    let defaultPays: string[] = []
    if (locData) {
        defaultPays = locData.defaultPays
    }
    if (entry.length > 0) {
        // const user = userdata.find(entry[0].personalID)
        const user = userdata.items.filter((el) => el.personalID === entry[0].personalID && entry[0].personalID !== "" && entry[0].personalID !== "0")

        const starttime = entry[0].start !== "" ? entry[0].start : obj.starttime
        const cvd = entry[0].iscvd !== "" ? entry[0].iscvd : "0"
        let name: string | JSX.Element = user.length > 0 ? user[0].realName : entry[0].miscName
        name = (
            <span
                className={`salaryInfo ${cvd === "1" ? "cvd" : ""} ${abrechnungen.subjectsToChange.indexOf(obj.positionID) !== -1 ? "changed" : ""
                    }`}
            >
                {name}
                {cvd === "1" && (
                    <Tooltip placement="left" overlay="CVD">
                        <BusinessCenter />
                    </Tooltip>
                )}
            </span>
        )
        const endtime = entry[0].end !== "" ? entry[0].end : "00:00"
        const hours = entry[0].hours !== "" ? entry[0].hours : "0"
        const earnings = entry[0] ? entry[0].earnings : "0"
        const salary = entry[0] ? entry[0].salary : "0"
        const paidFrom = entry[0].paidFromPosition !== "" ? entry[0].paidFromPosition : ""
        let sum: string | JSX.Element = entry[0] ? entry[0].sum : "0"
        const paidFromData = positions.find(paidFrom)
        const isDefaultPay = defaultPays.includes(paidFrom) || paidFrom === obj.positionID
        const paysother = entry[0].pays().length > 1
        sum = (
            <span
                className={`salaryInfo ${abrechnungen.subjectsToChange.indexOf(obj.positionID) !== -1
                    ? "changed with-paidfrom"
                    : "with-paidfrom"
                    }`}
            >
                <span className="sum">{sum}€</span>
                {paidFromData && (
                    <Tooltip placement="left" overlay={paidFromData.positionName}>
                        <Info className={!isDefaultPay ? "info" : "standard"}></Info>
                    </Tooltip>
                )}
                {paysother && <Tooltip placement="left" overlay={
                    <ul className="pays-other-tooltip">
                        {entry[0].fullPaysData().map((el, idx) => {
                            return <li key={idx}>
                                <span className="pays-position">{el.positionname}</span>
                                <span className="pays-name">{el.personalData ? el.personalData.realName : el.miscName}</span>
                                <span className="pays-salary">{el.salary} €</span>
                            </li>
                        })}
                    </ul>
                }>
                    <FolderShared className={"pays-other-tooltip-icon"}></FolderShared>
                </Tooltip>}
            </span>
        )

        const uberweisung = entry[0].ueberweisung !== "" ? entry[0].ueberweisung : "0"
        const rechnung = entry[0].rechnung !== "" ? entry[0].rechnung : "0"
        const pays = entry[0].pays()
        const positionname = (
            <span id={"pos-" + obj.positionID} className={`highlight-${obj.color}`}>
                {entry[0].miscTitle === "" ? entry[0].positionname : entry[0].miscTitle}
            </span>
        )
        const salaryInfo = (
            <span className="salaryInfo">
                {salary}€
                {rechnung === "1" && (
                    <Tooltip placement="left" overlay="Rechnung">
                        <Receipt />
                    </Tooltip>
                )}
                {uberweisung === "1" && (
                    <Tooltip placement="left" overlay="Überweisung">
                        <AccountBalance />
                    </Tooltip>
                )}
            </span>
        )
        return {
            name,
            start: starttime,
            end: endtime,
            uberweisung,
            rechnung,
            hours: parseFloat(hours),
            earnings: earnings + "€",
            salary: salaryInfo,
            sum: typeof sum === "string" ? sum + "€" : sum,
            paidFrom,
            pays,
            positionname,
            misc: user.length === 0 && entry[0].miscName !== ""
        }
    }
    return {
        name: "",
        start: obj.starttime,
        end: "00:00",
        uberweisung: "0",
        rechnung: "0",
        hours: 0,
        earnings: "0€",
        salary: "0€",
        sum: "0€",
        paidFrom: "",
        pays: [],
        positionname: <span className={`highlight-${obj.color}`}>{obj.positionName}</span>,
        misc: false
    }
}