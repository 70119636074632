import "./personalverzehr.scss"
import "rc-tooltip/assets/bootstrap.css"

import bind from "bind-decorator"
import { observer } from "mobx-react"
import React, { Component } from "react"
import { Redirect, Route, RouteComponentProps } from "react-router"

import Loading from "../../components/loading"
import userdata from "../../stores/userdata"
import { updateApphead } from "../../utils/dommanip"
import genGetUrl, { TGetUrl } from "../../utils/geturl"
import PersonalEdit from "./personalverzehr_edit"
import PersonalList from "./personalverzehr_list"
import PersonalView from "./personalverzehr_view"

const basepath = "/personalverzehr"

export interface IAbrechnungItemPos {
    name: string | JSX.Element
    start: string
    end: string
    uberweisung: string
    rechnung: string
    hours: number
    earnings: string | number
    salary: string | number | JSX.Element
    sum: string | number | JSX.Element
    paidFrom: string
    pays: string[]
    positionname: string
}

export const { getUrl, jumpTo } = genGetUrl((opt: TGetUrl): string => {
    switch (opt.type) {
        case "view":
            return `${basepath}/view/${opt.id}`
        case "edit":
            return `${basepath}/edit/${opt.id}`
        case "list":
            return `${basepath}/list/`
        default:
            return basepath
    }
})

interface IEmployeeProps
    extends RouteComponentProps<{
        id: string
    }> { }

@observer
class Personalverzehr extends Component<IEmployeeProps, {}> {
    public render() {
        // if (getCookie("token").length === 0) {
        //     return <Redirect to="/"></Redirect>
        // }
        if (!userdata.loaded) {
            return <Loading></Loading>
        }
        return (
            <React.Fragment>
                <Route path={getUrl({ type: "" })} exact render={this.list} />
                <Route path={getUrl({ type: "list" })} exact render={this.list} />
                <Route path={getUrl({ type: "view", id: ":id" })} exact render={this.viewItem} />
                <Route path={getUrl({ type: "edit", id: ":id" })} exact render={this.editItem} />
            </React.Fragment>
        )
    }

    public componentDidMount() {
        updateApphead("Personalverzehr", basepath)
        userdata.load()
    }

    private list(): JSX.Element {
        if (userdata.loaded) {
            return <PersonalList />
        }
        return <Loading></Loading>
    }

    @bind
    private viewItem({ match }: IEmployeeProps): JSX.Element {
        const { id } = match.params
        const user = userdata.find(id)
        if (user) {
            return (
                <React.Fragment>
                    <div className="leftsection">{this.list()}</div>
                    <div className="rightsection">{<PersonalView user={user}></PersonalView>}</div>
                </React.Fragment>
            )
        }
        return <Redirect to={getUrl({ type: "list" })}></Redirect>
    }

    @bind
    private editItem({ match }: IEmployeeProps): JSX.Element {
        const { id } = match.params
        const user = userdata.find(id)
        if (user) {
            return (
                <React.Fragment>
                    <div className="leftsection">{this.list()}</div>
                    <div className="rightsection">{<PersonalEdit user={user}></PersonalEdit>}</div>
                </React.Fragment>
            )
        }
        return <Redirect to={getUrl({ type: "list" })}></Redirect>
    }
}

export default Personalverzehr
