import { DateTime } from "luxon"
import { applySnapshot, Instance, types } from "mobx-state-tree"
import { get as oGet } from "object-path"

import { getPersverzehrFor, savePersverzehrForID } from "../api/persverzehr"
import { ITableObject } from "../components/table"
import { sortFn } from "../utils/sort"
import locations from "./locations"
import userdata from "./userdata"

export interface IPosition extends Instance<typeof PersonalverzehrItem> {}
export interface IPositions extends Instance<typeof PersonalverzehrItems> {}

let listInstance: IPositions

export const PersonalverzehrItem = types
    .model({
        verzehrID: types.identifier,
        personalID: types.string,
        amount: types.string,
        consumedAt: types.string,
        consumedLocationID: types.string,
        savedBy: types.string,
    })
    .volatile((self) => ({
        selected: false,
    }))
    .actions((self) => {
        const actions = {
            select() {
                self.selected = true
            },
            unselect() {
                self.selected = false
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            get personalName() {
                const personal = userdata.findByPersonalID(self.personalID)
                if (personal) {
                    return personal.realName
                }
                return `(${self.personalID})`
            },
            get locationName() {
                const location = locations.find(self.consumedLocationID)
                if (location) {
                    return location.locationName
                }
                return ""
            },
            get dateTimestamp() {
                return DateTime.fromFormat(self.consumedAt, "yyyy-MM-dd").toSeconds()
            },
            get readableDate() {
                return DateTime.fromFormat(self.consumedAt, "yyyy-MM-dd").toFormat("dd.MM.yyyy")
            },
            get parsedAmount() {
                if (!self.amount.includes("-")) {
                    return `${parseFloat(self.amount) * 0.5} €`
                }
                return `${self.amount} € Einzahlung`
            },
            get amountPercentage() {
                if (!self.amount.includes("-")) {
                    return `50%`
                }
                return `100%`
            },
        }
        return views
    })

export const getSortEl = (item: IPosition) => {
    if (item.personalName != null) {
        return item.personalName || 0
    }
    return 0
}

const sortGetVAl = (obj: IPosition, options: string): null | number | string => {
    return oGet(obj, options)
}

export interface IClientFilter {
    query?: string
    id?: string[]
}

const filterItems = (items: IPosition[], query: string | null, sortCol: string, sortAsc: boolean): IPosition[] => {
    if (query == null || !query.length) {
        return items.sort(sortFn(sortGetVAl, sortAsc, sortCol))
    }
    return items
        .filter((el) => {
            // const q = query.toLowerCase()
            if (el.personalName !== query) {
                return false
            }
            return true
        })
        .sort(sortFn(sortGetVAl, sortAsc, sortCol))
}

export const PersonalverzehrItems = types
    .model({
        items: types.array(PersonalverzehrItem),
    })
    .volatile((self) => ({
        searchstring: "",
        filterObj: {},
        sortAsc: true,
        sortCol: "dateTimestamp",
        loaded: false,
        loading: false,
    }))
    .actions((self) => {
        const actions = {
            setSearchString: (crit: string) => {
                return (self.searchstring = crit)
            },
            setSortCol: (col: string) => {
                return (self.sortCol = col)
            },
            setSortDir: (asc: boolean) => {
                return (self.sortAsc = asc)
            },
            setFilterObj: (fObj: IClientFilter) => {
                return (self.filterObj = fObj)
            },
            clearList: () => {
                self.items.clear()
            },
            setLoaded: () => {
                self.loading = false
                self.loaded = true
            },
            setLoading: () => {
                self.loading = true
            },
            load: (id) => {
                actions.setLoading()
                // if (guistate.client_id == null || loaded) {
                //     return
                // }
                try {
                    actions.clearList()
                    getPersverzehrFor(id).then((res: any[] | null) => {
                        if (res) {
                            applySnapshot(self.items, res)
                        }
                        // res.map((item) => actions.addItems(item))
                        actions.setLoaded()
                    })
                } catch (err) {
                    console.error(err)
                }
            },
            update(personalID: string, amount: string, date: string, locationID) {
                savePersverzehrForID({ personalID, amount, date, locationID })
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            count: () => {
                return self.items.length
            },
            get gesamt() {
                let amount = 0
                self.items.forEach((el) => {
                    if (el.amount.includes("-")) {
                        amount += parseFloat(el.amount)
                    } else {
                        amount += parseFloat(el.amount) * 0.5
                    }
                })
                return amount.toFixed(2)
            },
            find: (id: string) => {
                const res = self.items.filter((el) => el.verzehrID === id)
                if (res.length > 0) {
                    return res[0]
                }
                return null
            },
            first: () => {
                return self.items[0]
            },
            filteroptions: () => {
                return []
            },
            filter: (query: string | null, sortCol: string) => {
                return filterItems(self.items, query, self.sortCol, self.sortAsc)
            },
            filtered: () => {
                return views.filter(null, self.sortCol)
            },
            selected: () => {
                return views.filtered().filter((el) => el)
            },
            get tableView() {
                const data: ITableObject = {
                    columns: [
                        {
                            label: "Datum",
                            small: true,
                            valkey: "readableDate",
                            sortable: false,
                        },
                        {
                            label: "",
                            small: true,
                            valkey: "parsedAmount",
                            sortable: false,
                        },
                        {
                            label: "entspricht",
                            small: true,
                            valkey: "amountPercentage",
                            sortable: false,
                        },
                        {
                            label: "Club",
                            small: true,
                            valkey: "locationName",
                            sortable: false,
                        },
                    ],
                    data: views.filtered(),
                }
                return data
            },
        }
        return views
    })

listInstance = PersonalverzehrItems.create({})
export default listInstance
// export default Locations
