import "./tagesabrechnung.scss"
import "rc-tooltip/assets/bootstrap.css"

import bind from "bind-decorator"
import { DateTime } from "luxon"
import { observer } from "mobx-react"
import React, { Component } from "react"
import { Redirect, Route, RouteComponentProps } from "react-router"

import Loading from "../../components/loading"
import abrechnungen, { AbrechnungItem } from "../../stores/abrechnung"
import abrechnungforms from "../../stores/abrechnungforms"
import locations, { LOCATIONNAMES } from "../../stores/locations"
import positions from "../../stores/positions"
import positiontypes from "../../stores/positiontypes"
import userdata from "../../stores/userdata"
import { getCookie } from "../../utils/cookiemanager"
import { LUXON_WEEKDAYS_LONG } from "../../utils/dateformatter"
import { updateApphead, updateDay } from "../../utils/dommanip"
import genGetUrl, { TGetUrl } from "../../utils/geturl"
import DayList from "./daylist"
import DayManage from "./daymanage"
import EditEinteilungDetail from "./editdata"
import ViewEinteilungDetail from "./viewdata"

// import EinteilungManage from "./einteilung_fill"
const basepath = "/tagesabrechnung"

export const { getUrl, jumpTo } = genGetUrl((opt: TGetUrl): string => {
    switch (opt.type) {
        case "new":
            return `${basepath}/new`
        case "view":
            return `${basepath}/view/${opt.id}/${opt.posid}/${opt.location}`
        case "viewlist":
            return `${basepath}/viewlist/${opt.id}${opt.anchor ? "/" + opt.anchor : "/0"}`
        case "edit":
            return `${basepath}/edit/${opt.id}/${opt.posid}/${opt.location}`
        case "list":
            return `${basepath}/list/`
        default:
            return basepath
    }
})

interface ITagesabrechnungProps
    extends RouteComponentProps<{
        id: string,
        posid: string,
        // tslint:disable-next-line: trailing-comma
        location: string
        anchor?: string
    }> { }

interface ITagesabrechnungState {
    loadtry: number
}

@observer
class Tagesabrechnung extends Component<ITagesabrechnungProps, ITagesabrechnungState> {

    constructor(props: any, context?: any) {
        super(props, context)

        this.state = {
            loadtry: 0
        }

        this.list = this.list.bind(this)
        this.showMain = this.showMain.bind(this)
        this.newItem = this.newItem.bind(this)
        this.viewItem = this.viewItem.bind(this)
        this.viewList = this.viewList.bind(this)
        this.editItem = this.editItem.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        if (!abrechnungforms.loaded) {
            return <Loading></Loading>
        }
        return (
            <React.Fragment>
                <Route path={getUrl({ type: "" })} exact render={this.showMain} />
                <Route path={getUrl({ type: "list" })} exact render={this.list} />
                <Route path={getUrl({ type: "new" })} exact render={this.newItem} />
                <Route
                    path={getUrl({ type: "edit", id: ":id", posid: ":posid", location: ":location" })}
                    exact
                    render={this.editItem}
                />
                <Route
                    path={getUrl({ type: "view", id: ":id", posid: ":posid", location: ":location" })}
                    exact
                    render={this.viewItem}
                />
                <Route path={getUrl({ type: "viewlist", id: ":id", anchor: ":anchor" })} exact render={this.viewList} />
                <Route path={getUrl({ type: "manage", id: ":id" })} exact render={this.manageItem} />
            </React.Fragment>
        )
    }

    public componentDidMount() {
        updateApphead("Tagesabrechnung", basepath)
        if (!userdata.loaded) {
            userdata.load()
        }
        // DateTime.local().setZone("local");
        abrechnungforms.load({})
        if (!positions.loaded) {
            positions.load()
        }
        if (!positiontypes.loaded) {
            positiontypes.load()
        }
        if (!locations.loaded) {
            locations.load()
        }
    }

    private showMain(): JSX.Element {
        return this.list()
    }

    private list(): JSX.Element {
        return <DayList />
    }

    private newItem(): JSX.Element {
        return (
            <React.Fragment>
                <div className="leftsection">{this.list()}</div>
                <div className="rightsection">{/* <EinteilungNew></EinteilungNew> */}</div>
            </React.Fragment>
        )
    }

    private editItem({ match }): JSX.Element {
        const { id, location, posid } = match.params
        const einteilung = abrechnungforms.find(id)
        const locationData = locations.find(location)

        abrechnungen.items.forEach((el) => {
            const relevPos = abrechnungen.items.filter((item) => item.positionID === el.paidFromPosition)
            if (el.paidFromPosition !== "") {
                if (relevPos[0]) {
                    // relevPos[0].addPays(el.positionID)
                }
            }
        })
        if (locationData) {
            if (einteilung) {
                const abrechnungItem = abrechnungen.get(posid)
                if (!abrechnungItem) {
                    const posData = positions.find(posid)
                    let umsatzgen = false
                    let paidType = posid
                    if (posData) {
                        const posType = positiontypes.get(posData.positionType)
                        if (posType) {
                            umsatzgen = posType.umsatzgenerierend === "1"
                            if (location === "1" || location === "6") {
                                const weekday = DateTime.fromFormat(einteilung.date, "yyyy-MM-dd").weekday
                                if ([7, 6, 5].indexOf(weekday) > -1) {
                                    paidType = !umsatzgen ? locationData.defaultPays[1] : posid
                                } else {
                                    paidType = !umsatzgen ? locationData.defaultPays[0] : posid
                                }
                            } else {
                                paidType = !umsatzgen ? locationData.defaultPays[0] : posid
                            }
                        }
                    }
                    const newItem = AbrechnungItem.create({
                        locationID: location,
                        date: einteilung.date,
                        positionID: posid,
                        paidType,
                        paidFromPosition: paidType,
                        timestamp: DateTime.fromFormat(einteilung.date, "yyyy-MM-dd").toMillis().toString(),
                    })
                    abrechnungen.addItems(newItem)
                }
                const entered: Array<{
                    positionID: string
                    personalID: string
                    // tslint:disable-next-line: trailing-comma
                    starttime: string
                }> = einteilung.entered.map((el) => {
                    return {
                        positionID: el.position,
                        personalID: el.id,
                        starttime: el.time,
                    }
                })
                const position = positions.find(posid)
                if (position) {
                    return (
                        <React.Fragment>
                            <div className="leftsection">
                                <DayManage
                                    date={einteilung.date}
                                    id={id}
                                    locationID={einteilung.locationID}
                                    entered={entered}
                                    einteilung={einteilung}
                                // onClick={this.onClick}
                                ></DayManage>
                            </div>
                            <div className="rightsection">
                                <EditEinteilungDetail
                                    date={einteilung.date}
                                    location={einteilung.locationID}
                                    abrechnungid={posid}
                                    position={position}
                                    umsatzanteilPerc={locationData.umsatzanteilPerc}
                                    einteilung={einteilung}
                                    entered={entered}
                                ></EditEinteilungDetail>
                            </div>
                        </React.Fragment>
                    )
                }
            }
        }
        return <Redirect to={getUrl({ type: "list" })}></Redirect>
    }

    private manageItem({ match }: ITagesabrechnungProps): JSX.Element {
        const { id } = match.params
        const einteilung = abrechnungforms.find(id)
        if (einteilung) {
            return <React.Fragment>{/* EinteilungManage einteilung={einteilung}></EinteilungManage> */}</React.Fragment>
        }
        return <Redirect to={getUrl({ type: "list" })}></Redirect>
    }

    @bind
    private viewList({ match }: ITagesabrechnungProps): JSX.Element {
        const { id, anchor } = match.params

        let einteilung = abrechnungforms.find(id)

        if (einteilung) {
            const date = DateTime.fromFormat(einteilung.date, "yyyy-MM-dd")
            const weekday = LUXON_WEEKDAYS_LONG[date.weekday]
            updateDay(
                `${LOCATIONNAMES[parseInt(einteilung.locationID, 10) - 1]} ${weekday} ${date.toFormat("dd.MM.yyyy")}`,
            )
            abrechnungen.load({ date: einteilung.date, locationID: einteilung.locationID })
            const entered: Array<{
                positionID: string
                personalID: string
                starttime: string
            }> = einteilung.entered.map((el) => {
                return { positionID: el.position, personalID: el.id, starttime: el.time }
            })
            return (
                <DayManage
                    id={id}
                    date={einteilung.date}
                    locationID={einteilung.locationID}
                    entered={entered}
                    einteilung={einteilung}
                    scrollTo={anchor}
                // onClick={this.onClick}
                />
            )
        }

        return <Redirect to={getUrl({ type: "list" })} />
    }

    private viewItem({ match }: ITagesabrechnungProps): JSX.Element {
        const { id, posid } = match.params
        const einteilung = abrechnungforms.find(id)
        abrechnungen.items.forEach((el) => {
            if (el.paidFromPosition !== "") {
                const relevPos = abrechnungen.items.filter((item) => item.positionID === el.paidFromPosition)
                if (relevPos[0]) {
                    // relevPos[0].addPays(el.positionID)
                }
            }
        })
        if (einteilung) {
            const entered: Array<{
                positionID: string
                personalID: string
                // tslint:disable-next-line: trailing-comma
                starttime: string
            }> = einteilung.entered.map((el) => {
                return {
                    positionID: el.position,
                    personalID: el.id,
                    starttime: el.time,
                }
            })
            const position = positions.find(posid)
            if (position) {
                return (
                    <React.Fragment>
                        <div className="leftsection">
                            <DayManage
                                date={einteilung.date}
                                id={id}
                                posOpen={posid}
                                locationID={einteilung.locationID}
                                entered={entered}
                                einteilung={einteilung}
                            // onClick={this.onClick}
                            />
                        </div>
                        <div className="rightsection">
                            <ViewEinteilungDetail
                                position={position}
                                einteilung={einteilung}
                                entered={entered}
                                onClose={() => jumpTo({
                                    type: "viewlist",
                                    id: einteilung.einteilungID,
                                    anchor: position.positionID
                                })}
                            />
                        </div>
                    </React.Fragment>
                )
            }
        }
        return <Redirect to={getUrl({ type: "list" })}></Redirect>
    }
}

export default Tagesabrechnung
