import { Instance, types } from "mobx-state-tree"
import { get as oGet } from "object-path"

import { removeLeadingZero } from "../utils/numvalidator"
import { sortFn } from "../utils/sort"
import abrechnung from "./abrechnung"

export interface IWallet extends Instance<typeof Wallet> {}
export interface IWalletList extends Instance<typeof WalletList> {}

let listInstance: IWalletList

export const Wallet = types
    .model({
        positionID: types.string,
        coins: types.optional(types.string, "0"),
        fives: types.optional(types.number, 0),
        tens: types.optional(types.number, 0),
        twenties: types.optional(types.number, 0),
        fifties: types.optional(types.number, 0),
        hundreds: types.optional(types.number, 0),
        twohundreds: types.optional(types.number, 0),
        fivehundreds: types.optional(types.number, 0),
        billsum: types.optional(types.number, 0),
        earnings: types.optional(types.string, "0"),
    })
    .volatile((self) => ({
        selected: false,
    }))
    .actions((self) => {
        const actions = {
            select() {
                self.selected = true
            },
            unselect() {
                self.selected = false
            },
            setCoins: (coins: string) => {
                self.coins = coins
                actions.calcEarnings()
            },
            setFives: (fives: number) => {
                self.fives = fives
                actions.updateBills()
            },
            setTens: (tens: number) => {
                self.tens = tens
                actions.updateBills()
            },
            setTwenties: (twenties: number) => {
                self.twenties = twenties
                actions.updateBills()
            },
            setFifties: (fifties: number) => {
                self.fifties = fifties
                actions.updateBills()
            },
            setHundreds: (hundreds: number) => {
                self.hundreds = hundreds
                actions.updateBills()
            },
            setTwohundreds: (twohundreds: number) => {
                self.twohundreds = twohundreds
                actions.updateBills()
            },
            setFivehundreds: (fivehundreds: number) => {
                self.fivehundreds = fivehundreds
                actions.updateBills()
            },
            calcEarnings: () => {
                // tslint:disable-next-line: max-line-length
                const earnings: number = self.billsum + parseFloat(self.coins.replace(",", "."))
                const abrechnungItem = abrechnung.get(self.positionID)
                let addedearnings = earnings
                if (abrechnungItem) {
                    addedearnings += abrechnungItem.payssum()
                }
                actions.setEarnings(addedearnings.toFixed(2))
            },
            setEarnings: (manualEarnings: string) => {
                self.earnings = removeLeadingZero(manualEarnings)
                const abrechnungItem = abrechnung.get(self.positionID)
                if (abrechnungItem) {
                    abrechnungItem.setEarnings(manualEarnings)
                }
            },
            updateBills: () => {
                self.billsum =
                    self.fives * 5 +
                    self.tens * 10 +
                    self.twenties * 20 +
                    self.fifties * 50 +
                    self.hundreds * 100 +
                    self.twohundreds * 200 +
                    self.fivehundreds * 500
                actions.calcEarnings()
            },
            setBillsum: (manualBillsum: number) => {
                self.billsum = manualBillsum
                actions.calcEarnings()
            },
            reset: () => {
                self.fives = 0
                self.tens = 0
                self.twenties = 0
                self.fifties = 0
                self.hundreds = 0
                self.twohundreds = 0
                self.billsum = 0
                self.coins = "0"
                actions.calcEarnings()
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            walletsum: () => {
                const earnings =
                    parseFloat(self.coins.replace(",", ".")) +
                    self.fives * 5 +
                    self.tens * 10 +
                    self.twenties * 20 +
                    self.fifties * 50 +
                    self.hundreds * 100 +
                    self.twohundreds * 200 +
                    self.fivehundreds * 500
                return earnings
            },
        }
        return views
    })

export const getSortEl = (item: IWallet) => {
    return 0
}

const sortGetVAl = (obj: IWallet, options: string): null | number | string => {
    return oGet(obj, options)
}

// const loadItems = (): Promise<IPositionApi[]> => getpositions()

export interface IClientFilter {
    query?: string
    id?: string[]
    domain?: string
}

const filterItems = (items: IWallet[], query: string | null, sortCol: string, sortAsc: boolean): IWallet[] => {
    if (query == null || !query.length) {
        return items.sort(sortFn(sortGetVAl, sortAsc, sortCol))
    }
    return items
        .filter((el) => {
            // const q = query.toLowerCase()

            return true
        })
        .sort(sortFn(sortGetVAl, sortAsc, sortCol))
}

export const WalletList = types
    .model({
        items: types.array(Wallet),
    })
    .volatile((self) => ({
        searchstring: "",
        filterObj: {},
        sortAsc: true,
        sortCol: "created",
        loaded: false,
    }))
    .actions((self) => {
        const actions = {
            get: (id: string) => {
                const res = self.items.filter((el) => el.positionID === id)
                if (res.length > 0) {
                    return res[0]
                }
                return
            },
            addWallet: (obj) => {
                self.items.push(obj)
            },
            setSearchString: (crit: string) => {
                return (self.searchstring = crit)
            },
            setSortCol: (col: string) => {
                return (self.sortCol = col)
            },
            setSortDir: (asc: boolean) => {
                return (self.sortAsc = asc)
            },
            setFilterObj: (fObj: IClientFilter) => {
                return (self.filterObj = fObj)
            },
            setLoaded: () => {
                self.loaded = true
            },
            resetWallets: () => {
                self.items.forEach((el) => {
                    el.reset()
                })
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            count: () => {
                return self.items.length
            },
            first: () => {
                return self.items[0]
            },
            filteroptions: () => {
                return []
            },
            filter: (query: string | null, sortCol: string) => {
                return filterItems(self.items, query, self.sortCol, self.sortAsc)
            },
            filtered: (club: string) => {
                return views.filter(club, self.sortCol)
            },
        }
        return views
    })

listInstance = WalletList.create({})
export default listInstance
// export default Locations
