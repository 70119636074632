import "./personalverzehr.scss"
import "react-toastify/dist/ReactToastify.css"

import bind from "bind-decorator"
import { observer } from "mobx-react"
import React, { Component } from "react"
import { Redirect } from "react-router"

import { jumpTo } from "."
import DetailView from "../../components/detailview"
import Loading from "../../components/loading"
import Table from "../../components/table"
import personalverzehr from "../../stores/allpersverzehr"
import locations from "../../stores/locations"
import { IUserItem } from "../../stores/userdata"
import { getCookie } from "../../utils/cookiemanager"

interface IEmployeesViewProps {
    user: IUserItem
}

@observer
class PersonalView extends Component<IEmployeesViewProps, {}> {
    public render() {
        const { user } = this.props
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        return (
            <DetailView
                header={() => this.renderHeader(user)}
                content={() => this.renderContent(user)}
                onClose={() => jumpTo({ type: "list" })}
                onEdit={() =>
                    jumpTo({
                        type: "edit",
                        id: user.userID,
                    })
                }
            ></DetailView>
        )
    }

    public componentDidMount() {
        const { user } = this.props
        if (!locations.loaded) {
            locations.load()
        }
        personalverzehr.load(user.personalID)
    }

    private renderHeader(user: IUserItem) {
        return <h3>{user.realName}</h3>
    }

    @bind
    private renderContent(user: IUserItem) {
        if (personalverzehr.loading) {
            return <Loading></Loading>
        }
        const { columns, data } = personalverzehr.tableView
        return (
            <div>
                <span>Gesamt: {personalverzehr.gesamt} €</span>
                <br></br>
                <Table
                    columns={columns}
                    data={data}
                    // onSort={this.onSort}
                    // unfolded={tag.unfolded}
                    sortKey={personalverzehr.sortCol}
                    colSort={personalverzehr.sortAsc}
                // onOpen={this.onOpen}
                ></Table>
            </div>
        )
    }
}

export default PersonalView
