import "./notepopout.scss"

import { observer } from "mobx-react"
import Tooltip from "rc-tooltip"
import React, { Component } from "react"
import ReactMarkdown from "react-markdown"

import note from "../../stores/note"
import { getCookie } from "../../utils/cookiemanager"
import mdOff from "./img/markdown_off.svg"
import mdOn from "./img/markdown_on.svg"

interface INotePopoutState {
    open: boolean
    currentcontent: string
    mdon: boolean
}

interface INotePopoutProps {
    location: string
    date: string
}

@observer
class NotePopout extends Component<INotePopoutProps, INotePopoutState> {
    constructor(props: any) {
        super(props)

        this.state = {
            open: false,
            currentcontent: note.notecontent,
            mdon: false,
        }
    }

    public render() {
        const { open, mdon } = this.state
        const { date, location } = this.props
        return (
            <React.Fragment>
                {note.notecontent !== "" && <Tooltip placement="bottom" overlay={
                    <div className="md-wrp">
                        <ReactMarkdown source={note.notecontent} />
                    </div>
                }>
                    <div className="top-action-button" onClick={() => {
                        note.load({ date, locationID: location })
                        this.setState({
                            open: true,
                        })
                    }}>
                        Notizen
                    </div>
                </Tooltip>}
                {note.notecontent === "" &&
                    <div className="top-action-button" onClick={() => {
                        note.load({ date, locationID: location })
                        this.setState({
                            open: true,
                        })
                    }}>
                        Notizen
                    </div>}
                {open && (
                    <div className={"popout-dialog"}>
                        {!mdon && (
                            <>
                                <h4>
                                    <span className="note-title">Notizen</span>
                                    <Tooltip
                                        placement="top"
                                        overlay={
                                            <div className="md-tooltip">
                                                <h1># Überschrift</h1>
                                                <h2>## Überschrift</h2>
                                                <h3>### Überschrift</h3>
                                                <h3>* Liste</h3>
                                            </div>
                                        }
                                    >
                                        <div onClick={() => this.setState({ mdon: true })}>
                                            <img className="md-logo md-off" alt="" src={mdOff} />
                                            <span className="md-logo-caption">Bearbeitung</span>
                                        </div>
                                    </Tooltip>
                                </h4>
                                <textarea
                                    rows={20}
                                    cols={250}
                                    value={note.notecontent}
                                    onChange={(ev) => {
                                        ev.persist()
                                        note.updatecontent(ev.target.value)
                                    }}
                                ></textarea>
                            </>
                        )}
                        {mdon && (
                            <>
                                <h4>
                                    <span className="note-title">Notizen</span>
                                    <Tooltip
                                        placement="top"
                                        overlay={
                                            <div className="md-tooltip">
                                                <h1># Überschrift</h1>
                                                <h2>## Überschrift</h2>
                                                <h3>### Überschrift</h3>
                                                <h3>* Liste</h3>
                                            </div>
                                        }
                                    >
                                        <div onClick={() => this.setState({ mdon: false })}>
                                            <img className="md-logo md-on" alt="" src={mdOn} />
                                            <span className="md-logo-caption">Vorschau</span>
                                        </div>
                                    </Tooltip>
                                </h4>
                                <div className="md-wrp">
                                    <ReactMarkdown source={note.notecontent} />
                                </div>
                            </>
                        )}
                        <div className="dialog-buttons">
                            <div
                                className="dialog-button"
                                onClick={() => {
                                    this.setState({
                                        open: false,
                                    })
                                }}
                            >
                                Schließen
                            </div>
                            <div
                                className="dialog-button"
                                onClick={() => {
                                    const userid = getCookie("userid")
                                    note.save(date, location, userid, () => this.setState({ open: false }))
                                }}
                            >
                                Speichern
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }
}

export default NotePopout
