/* <reference types="webpack-env" /> */
import "./index.scss"
import "react-app-polyfill/stable"
import dotenv from "dotenv"
import * as React from "react"
import * as ReactDOM from "react-dom"

import AuthCheck from "./auth_check"


dotenv.config()

// import registerServiceWorker from "./serviceWorker"

// registerServiceWorker()


const renderApp = () => {
    ReactDOM.render(<AuthCheck />, document.getElementById("root"))
}

renderApp()

// if (module.hot) {
//     module.hot.accept(["./App"], () => {
//         // new components
//         renderApp()
//     })
// }

// serviceWorker.unregister()


// // if (process.env.NODE_ENV === "development" && module.hot) {
// //     module.hot.accept(["./components/App"], () => {
// //         // new components
// //         renderApp()
// //     })
// // }

// export { renderApp }
