import { Instance, types, applySnapshot } from "mobx-state-tree"
import uuid from "uuid"
import { getnotes, savenotes, ISaveNoteApi } from "../api/notes"
import { toast } from "react-toastify"

export interface INote extends Instance<typeof Note> {}

let noteInstance: INote

export const Note = types
    .model({
        noteID: types.optional(types.string, uuid()),
        date: types.optional(types.string, ""),
        locationID: types.optional(types.string, ""),
        notecontent: types.optional(types.string, ""),
    })
    .actions((self) => {
        const actions = {
            load: ({ locationID, date }) => {
                getnotes(locationID, date).then((res: any[]) => {
                    if (res) {
                        if (res.length > 0) {
                            applySnapshot(self, res[0])
                        }
                    }
                })
            },
            updatedate: (date: string) => {
                self.date = date
            },
            updatelocationid: (id: string) => {
                self.locationID = id
            },
            updatecontent: (content: string) => {
                self.notecontent = content
            },
            save: (date: string, location, userID, onSuccess: () => void) => {
                const noteObj: ISaveNoteApi = {
                    noteID: date.replace("-", "").replace("-", "") + "-" + location,
                    locationID: location,
                    date,
                    content: self.notecontent,
                    userID,
                }
                savenotes(noteObj).then((res) => {
                    if (res === "ok") {
                        // tslint:disable-next-line: no-unused-expression
                        toast.success("Änderungen wurden gespeichert", { autoClose: 2500 })
                        onSuccess()
                    } else {
                        toast.error("Änderungen wurden nicht gespeichert", { autoClose: 2500 })
                    }
                })
            },
        }
        return actions
    })

noteInstance = Note.create({})
export default noteInstance
