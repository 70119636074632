import { IExtendedPersonalHours } from "apps/employees/employees_view"
import { DateTime } from "luxon"
import React, { Component } from "react"
import ReactExport from "react-export-excel"
import { IUserItem } from "stores/userdata"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

export interface IMonthExcelExportSingle {
    stbID: string
    personalID: string
    name: string
    hoursHeart: number
    salaryHeart: number
    hoursMia: number
    salaryMia: number
    hoursZK: number
    salaryZK: number
    hoursZC: number
    salaryZC: number
    hoursSum: number
    salarySum: number
    rechnung: boolean
    uberweisung: boolean
}

export interface IDayExportSingle {
    personalID: string
    name: string
    positionName: string
    start: string
    ende: string
    stunden: number
    umsatz: number
    lohn: number
    summe: number
    ueberweisung: boolean
    rechnung: boolean
}

interface IAbrechnungDay {
    hours: number
    salary: number
}

interface IExportMonthProps {
    month: number
    year: string
    data: IMonthExcelExportSingle[]
    exportbutton: JSX.Element
}

export interface IAllDaysExportProps {
    month: number
    year: number
    clubname: string
    exportbutton: JSX.Element
    day: number[]
    data: IAbrechnungDay[]
}

export interface IUserHoursExportProps {
    month: number
    year: number
    exportbutton: JSX.Element
    data: IExtendedPersonalHours[]
    user: IUserItem
    hourssum: number
}

interface IDayExportProps {
    month: number
    day: number
    year: number
    clubname: string
    data: IDayExportSingle[]
    exportbutton: JSX.Element
}

export class MonthExport extends Component<IExportMonthProps, {}> {
    public render() {
        const { data, month, year, exportbutton } = this.props

        return (
            <ExcelFile filename={`${month}.${year}`} element={exportbutton}>
                <ExcelSheet data={data} name={`${month}.${year}`}>
                    <ExcelColumn label="" value={(col) => col.stbID} />
                    <ExcelColumn label="" value={(col) => col.name} />
                    {/* <ExcelColumn label="HEART Std." value={(col) => (col.hoursHeart === 0 ? "" : col.hoursHeart)} /> */}
                    <ExcelColumn label="HEART Lohn" value={(col) => (col.salaryHeart === 0 ? "" : col.salaryHeart)} />
                    {/* <ExcelColumn
                        style={{
                            font: {
                                sz: "24",
                            },
                        }}
                        label="MIA Std."
                        value={(col) => (col.hoursMia === 0 ? "" : col.hoursMia)}
                    /> */}
                    <ExcelColumn label="MIA Lohn" value={(col) => (col.salaryMia === 0 ? "" : col.salaryMia)} />
                    {/* <ExcelColumn label="Z Cham Std." value={(col) => (col.hoursZK === 0 ? "" : col.hoursZK)} /> */}
                    <ExcelColumn label="Z Cham Lohn" value={(col) => (col.salaryZC === 0 ? "" : col.salaryZC)} />
                    {/* <ExcelColumn label="Z Köz Std." value={(col) => (col.hoursZC === 0 ? "" : col.hoursZC)} /> */}
                    <ExcelColumn label="Z Köz Lohn" value={(col) => (col.salaryZK === 0 ? "" : col.salaryZK)} />
                    {/* <ExcelColumn label="Gesamt Std." value={(col) => (col.hoursSum === 0 ? "" : col.hoursSum)} /> */}
                    <ExcelColumn label="Gesamt Lohn" value={(col) => (col.salarySum === 0 ? "" : col.salarySum)} />
                    <ExcelColumn label="" value={(col) => (col.uberweisung ? "Überweisung" : "")} />
                </ExcelSheet>
            </ExcelFile>
        )
    }
}

export class DayExport extends Component<IDayExportProps, {}> {
    public render() {
        const { data, day, month, year, exportbutton, clubname } = this.props

        return (
            <ExcelFile filename={`${day}.${month}.${year} ${clubname}`} element={exportbutton}>
                <ExcelSheet data={data} name={`${month}.${year}`}>
                    <ExcelColumn label="Position" value={(col) => col.positionName} />
                    <ExcelColumn label="Personalname" value={(col) => col.name} />
                    <ExcelColumn label="Start" value={(col) => (col.start === 0 ? "" : col.start)} />
                    <ExcelColumn label="Ende" value={(col) => (col.ende === 0 ? "" : col.ende)} />
                    <ExcelColumn label="Stunden" value={(col) => (col.stunden === 0 ? "" : col.stunden)} />
                    <ExcelColumn label="Umsatz" value={(col) => (col.umsatz === 0 ? "" : col.umsatz)} />
                    <ExcelColumn label="Lohn" value={(col) => (col.lohn === 0 ? "" : col.lohn)} />
                    <ExcelColumn label="Summe" value={(col) => (col.summe === 0 ? "" : col.summe)} />
                    <ExcelColumn label="" value={(col) => (col.uberweisung ? "Überweisung" : "")} />
                </ExcelSheet>
            </ExcelFile>
        )
    }
}

export class AllDaysExport extends Component<IAllDaysExportProps, {}> {
    public render() {
        const { data, month, year, exportbutton, clubname } = this.props

        return (
            <ExcelFile filename={`${month}.${year} ${clubname}`} element={exportbutton}>
                <ExcelSheet data={data} name={`${month}.${year}`}>
                    <ExcelColumn label="" value={(col) => col.stbID} />
                    <ExcelColumn label="Personalname" value={(col) => col.name} />
                    {data.map((el) => {
                        return (
                            <>
                                <ExcelColumn label="Stunden" value={(col) => (col.stunden === 0 ? "" : col.stunden)} />
                                <ExcelColumn label="Lohn" value={(col) => (col.lohn === 0 ? "" : col.lohn)} />
                            </>
                        )
                    })}
                    <ExcelColumn label="Summe Std." value={(col) => (col.summe === 0 ? "" : col.summe)} />
                    <ExcelColumn label="Summe Lohn" value={(col) => (col.summe === 0 ? "" : col.summe)} />
                    <ExcelColumn label="" value={(col) => (col.uberweisung ? "Überweisung" : "")} />
                </ExcelSheet>
            </ExcelFile>
        )
    }
}

export class UserHoursExport extends Component<IUserHoursExportProps, {}> {
    public render() {
        const { data, month, year, exportbutton, user } = this.props

        const personalName = user.username

        let formattedName = personalName[0].toUpperCase() + "." + personalName[1].toUpperCase() + personalName.substring(2, personalName.length)

        const filename = `Std. ${formattedName} ${month}.${year}`

        return (
            <ExcelFile filename={filename} element={exportbutton}>
                <ExcelSheet data={data} name={`${month}.${year}`}>
                    <ExcelColumn label={""} value={(col) => col.stbid} />
                    <ExcelColumn label={""} value={(col) => col.name} />
                    <ExcelColumn label="Club" value={(col) => col.club} />
                    <ExcelColumn label="Datum" value={(col) => col.date === "" ? "" : DateTime.fromFormat(col.date, "yyyy-MM-dd").toFormat("dd.MM.yyyy")} />
                    <ExcelColumn label="Beginn" value={(col) => col.start} />
                    <ExcelColumn label="Ende" value={(col) => col.end} />
                    <ExcelColumn label="Stunden" value={(col) => (col.hours === 0 ? "" : col.hours)} />
                </ExcelSheet>
            </ExcelFile>
        )
    }
}
