import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import "./employees.scss"

import "react-toastify/dist/ReactToastify.css"
import DetailView from "../../components/detailview"
import { jumpTo } from "."
import { addUser } from "../../api/getpersonaldata"
import bind from "bind-decorator"
import guistate from "../../stores/guistate"
import { LOCATIONS } from "stores/locations"
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons"


const formKeys = [
    { key: "first_name", title: "Vorname" },
    { key: "last_name", title: "Nachname" },
    { key: "mail", title: "E-Mail" },
    { key: "locations", title: "Clubs" }
]
interface IEmployeesNewProps {
}

interface IEmployeesNewState {
    form: {
        locations: string[],
        first_name: string,
        last_name: string,
        mail: string
    }
}

@observer
class EmployeesNew extends Component<IEmployeesNewProps, IEmployeesNewState> {
    constructor(props: any) {
        super(props)
        this.state = {
            form: {
                locations: [],
                first_name: "",
                last_name: "",
                mail: ""
            }
        }
    }

    public render() {

        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        return (
            <DetailView
                header={() => {
                    return <h3>Neuer Mitarbeiter</h3>
                }}
                content={() => this.renderNewUserForm()}
                onSave={() => {
                    this.submitNewEmployee()
                }}
                onCancel={() => {
                    jumpTo({
                        type: "list"
                    })
                }}
            ></DetailView>
        )
    }

    @bind
    private renderNewUserForm() {

        return (
            <div className="">
                <p>

                </p>
                <br></br>
                <div>
                    {formKeys.map((el, idx) => {
                        if (el.key === "locations") {
                            return this.renderLocationSelector()
                        }
                        return this.renderInputElem(el.key, el.title, idx)
                    })}
                </div>
            </div>
        )
    }

    @bind
    private renderLocationSelector() {

        const { form } = this.state

        return <div className="flex flex-col mt-3">
            {["2", "3", "4", "6"].map((el) => {
                let selected = form["locations"].includes(el)
                return <div className="flex cursor-pointer" onClick={() => {
                    let newlist = form["locations"]
                    if (selected) {
                        newlist = newlist.filter((locationID) => locationID !== el)
                    } else {
                        newlist.push(el)
                    }
                    this.setState({
                        ...this.state,
                        form: {
                            ...form,
                            locations: newlist
                        }
                    })
                }}>
                    {selected && <CheckBox />}
                    {!selected && <CheckBoxOutlineBlank />}
                    <span>{LOCATIONS[el]}</span>
                </div>
            })}
        </div>
    }

    @bind
    private renderInputElem(key, title, idx) {

        const { form } = this.state

        return <div key={idx} className="form-input-elem">
            <span>
                {title}
            </span>
            <input value={form[key]} onChange={(evt) => {

                const newForm = form

                newForm[key] = evt.target.value

                this.setState({
                    form: newForm
                })
            }} />
        </div>
    }

    @bind
    private submitNewEmployee() {

        const { form } = this.state

        const shortcode = (form["first_name"].toLowerCase()[0] + form["last_name"].toLowerCase()).replace(" ", "")
        const fullname = form["first_name"] + " " + form["last_name"]
        const userid = guistate.userID
        const mail = form["mail"] === "" ? null : form["mail"]
        const locations = "[" + form["locations"].join(",") + "]"

        addUser({
            shortcode,
            fullname,
            userid,
            mail,
            locations
        }).then(() => {
            jumpTo({
                type: "list"
            })
        })
    }
}

export default EmployeesNew
