import { applySnapshot, Instance, types } from "mobx-state-tree"
import { get as oGet } from "object-path"

import { getpositions, IPositionApi } from "../api/positions"
import { formatAbrForExport, renderAbrechnungData } from "../apps/tagesabrechnung/tablerowelem"
import { ITableObject } from "../components/table"
import { sortFn } from "../utils/sort"
import positiontypes from "./positiontypes"

export interface IPosition extends Instance<typeof Position> {}
export interface IPositions extends Instance<typeof Positions> {}

export const UMSATZPOSITIONS = [
    "1",
    "2",
    "3",
    "4",
    "8",
    "9",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "21",
    "22",
    "23",
    "24",
    "25",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "57",
    "58",
    "59",
    "60",
    "61",
    "62",
    "63",
    "64",
    "65",
    "70",
    "71",
    "72",
    "73",
    "74",
    "75",
    "76",
    "77",
    "78",
    "100",
    "101",
    "102",
    "103",
    "104",
    "105",
    "106",
    "107",
    "108",
    "109",
    "110",
    "111",
    "112",
    "113",
    "114",
    "115",
    "116",
    "117",
    "118",
    "119",
    "120",
    "121",
    "122",
    "123",
    "124",
    "125",
    "126",
    "127",
    "128",
    "129",
    "130",
    "131",
    "132",
    "133",
    "134",
    "135",
    "136",
    "137",
    "138",
    "139",
    "140",
    "141",
    "142",
    "143",
    "144",
    "145",
    "146",
    "147",
    "148",
    "154",
    "155",
    "156",
    "157",
    "169",
    "170",
    "171",
    "172",
    "173",
    "174",
    "175",
    "176",
    "177",
    "178",
    "199",
    "200",
    "201",
    "202",
    "203",
    "209",
    "210",
    "211",
    "214",
    "215",
    "218",
    "219",
    "222",
    "223",
    "227",
    "228",
    "240",
    "241",
    "242",
    "243",
    "244",
    "245",
    "246",
    "247",
    "248",
    "249",
    "250",
]

let listInstance: IPositions

export const Position = types
    .model({
        positionID: types.string,
        positionName: types.string,
        positionShort: types.string,
        positionType: types.string,
        locationID: types.string,
        sortNo: types.string,
        starttime: types.optional(types.string, ""),
        customstarttime: types.optional(types.string, ""),
        showineinteilung: types.optional(types.string, "0"),
        modes: types.string,
    })
    .preProcessSnapshot((snapshot) => {
        // const ltTypes = ["AC", "TZ", "FS"]
        const overwrite = {
            customstarttime: snapshot.starttime,
        }
        return Object.assign({}, snapshot, overwrite)
    })
    .volatile((self) => ({
        selected: false,
    }))
    .actions((self) => {
        const actions = {
            select() {
                self.selected = true
            },
            unselect() {
                self.selected = false
            },
            changeStarttime(time) {
                self.starttime = time
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            stundenlohnFull: () => {
                const posType = positiontypes.get(self.positionType)
                if (posType) {
                    return parseFloat(posType.stundenlohn_assistent)
                }
                return 0
            },
            stundenlohnTheke: () => {
                const posType = positiontypes.get(self.positionType)
                if (posType) {
                    return parseFloat(posType.stundenlohn_theke)
                }
                return 0
            },
            modesList: (): number[] => {
                if (self.modes === "") {
                    return []
                }
                return JSON.parse(self.modes)
            },
            haswallet: () => {
                const posType = positiontypes.get(self.positionType)
                if (posType) {
                    switch (posType.typeID) {
                        case "1":
                        case "3":
                        case "4":
                        case "11":
                        case "12":
                        case "13":
                        case "16":
                            return true
                    }
                }
                return false
            },
            umsatzbet: () => {
                const posType = positiontypes.get(self.positionType)
                if (posType) {
                    return parseFloat(posType.umsatzanteil)
                }
                return 0
            },
            get color() {
                switch (self.positionType) {
                    case "1":
                    case "11":
                        return "blue"
                    case "2":
                    case "3":
                    case "4":
                    case "12":
                        return "green"
                    case "6":
                    case "7":
                    case "14":
                        return "yellow"
                }
                return "gray"
            },
        }
        return views
    })
    .views((self) => {
        const views = {
            get sortPosition() {
                if (self.locationID === "1" || self.locationID === "6") {
                    return parseInt(self.sortNo, 10)
                }
                return parseInt(self.positionID, 10)
            },
        }
        return views
    })

export const getSortEl = (item: IPosition) => {
    if (item.positionName != null) {
        return item.positionName || 0
    }
    return 0
}

const sortGetVAl = (obj: IPosition, options: string): null | number | string => {
    return oGet(obj, options)
}

// const loadItems = (): Promise<IPositionApi[]> => getpositions()

export interface IClientFilter {
    query?: string
    id?: string[]
    domain?: string
}

const filterItems = (items: IPosition[], query: string | null, sortCol: string, sortAsc: boolean): IPosition[] => {
    if (query == null || !query.length) {
        return items.sort(sortFn(sortGetVAl, sortAsc, sortCol))
    }
    return items
        .filter((el) => {
            // const q = query.toLowerCase()
            if (el.locationID !== query) {
                return false
            }
            return true
        })
        .sort(sortFn(sortGetVAl, sortAsc, sortCol))
}

export const Positions = types
    .model({
        items: types.array(Position),
        filled: types.optional(types.boolean, false),
    })
    .volatile((self) => ({
        searchstring: "",
        filterObj: {},
        sortAsc: false,
        sortCol: "sortPosition",
        loaded: false,
    }))
    .actions((self) => {
        const actions = {
            setSearchString: (crit: string) => {
                return (self.searchstring = crit)
            },
            setSortCol: (col: string) => {
                return (self.sortCol = col)
            },
            setSortDir: (asc: boolean) => {
                return (self.sortAsc = asc)
            },
            setFilterObj: (fObj: IClientFilter) => {
                return (self.filterObj = fObj)
            },
            addItems: (item: IPosition) => {
                if (!actions.isContained(item.positionID)) {
                    self.items.push(item)
                }
            },
            clearList: () => {
                self.items.clear()
            },
            isContained: (id: string) => {
                let contains = 0
                self.items.toJSON().forEach((el) => {
                    if (el.positionID === id) {
                        contains++
                    }
                })
                return contains !== 0
            },
            setLoaded: () => {
                self.filled = true
                self.loaded = true
            },
            load: () => {
                // if (guistate.client_id == null || loaded) {
                //     return
                // }
                try {
                    actions.clearList()
                    getpositions().then((res: IPositionApi[]) => {
                        // res.map((item) => actions.addItems(item))
                        applySnapshot(self.items, res)
                        actions.setLoaded()
                    })
                } catch (err) {
                    console.error(err)
                }
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            count: () => {
                return self.items.length
            },
            find: (id: string) => {
                const res = self.items.filter((el) => el.positionID === id)
                if (res.length > 0) {
                    return res[0]
                }
                return null
            },
            first: () => {
                return self.items[0]
            },
            filteroptions: () => {
                return []
            },
            filter: (query: string | null, mode: number, sortCol: string) => {
                return filterItems(self.items, query, self.sortCol, self.sortAsc).filter((obj) => {
                    return obj.modesList().includes(mode)
                })
            },
            filtered: (club: string, mode: number) => {
                return views.filter(club, mode, self.sortCol)
            },
            selected: () => {
                return views.filtered("", 0).filter((el) => el)
            },
            exportData: (
                filter,
                club,
                entered: Array<{ positionID: string; personalID: string; starttime: string }>,
            ) => {
                const data = self.items.filter((el) => el.locationID === club)

                let hourssum = 0
                let earningssum = 0
                let salarysum = 0

                let kasse = 0
                let garderobe = 0
                let personalverzehr = 0

                const exportData = data
                    .map((el) => {
                        const res = formatAbrForExport(el, entered)
                        const { umsatz, stunden, lohn } = res
                        if (el.positionType === "3" || el.positionType === "12") {
                            kasse += umsatz
                        }
                        if (el.positionType === "4" || el.positionType === "13") {
                            garderobe += umsatz
                        }
                        if (el.positionType === "16") {
                            personalverzehr += umsatz
                        }

                        earningssum += umsatz
                        hourssum += stunden
                        if (res.rechnung === false && res.ueberweisung === false) salarysum += lohn

                        return res
                    })
                    .filter((el) => {
                        return el.name !== ""
                    })

                exportData.push({
                    personalID: "",
                    name: "",
                    positionName: "",
                    start: "",
                    ende: "",
                    stunden: hourssum,
                    umsatz: earningssum,
                    lohn: salarysum,
                    summe: 0,
                    ueberweisung: false,
                    rechnung: false,
                })

                exportData.push({
                    personalID: "",
                    name: "",
                    positionName: "",
                    start: "",
                    ende: "",
                    stunden: 0,
                    umsatz: 0,
                    lohn: 0,
                    summe: 0,
                    ueberweisung: false,
                    rechnung: false,
                })
                exportData.push({
                    personalID: "",
                    name: "",
                    positionName: "",
                    start: "",
                    ende: "",
                    stunden: 0,
                    umsatz: 0,
                    lohn: 0,
                    summe: 0,
                    ueberweisung: false,
                    rechnung: false,
                })

                exportData.push({
                    personalID: "",
                    name: `${Math.round((earningssum - (kasse + garderobe + personalverzehr)) * 100) / 100}`,
                    positionName: "Gastroumsatz",
                    start: "",
                    ende: "",
                    stunden: 0,
                    umsatz: 0,
                    lohn: 0,
                    summe: 0,
                    ueberweisung: false,
                    rechnung: false,
                })

                exportData.push({
                    personalID: "",
                    name: `${kasse.toFixed(2)}`,
                    positionName: "Eintritt",
                    start: "",
                    ende: "",
                    stunden: 0,
                    umsatz: 0,
                    lohn: 0,
                    summe: 0,
                    ueberweisung: false,
                    rechnung: false,
                })

                exportData.push({
                    personalID: "",
                    name: `${garderobe.toFixed(2)}`,
                    positionName: "Garderobe",
                    start: "",
                    ende: "",
                    stunden: 0,
                    umsatz: 0,
                    lohn: 0,
                    summe: 0,
                    ueberweisung: false,
                    rechnung: false,
                })

                exportData.push({
                    personalID: "",
                    name: `${personalverzehr.toFixed(2)}`,
                    positionName: "Personalverzehr",
                    start: "",
                    ende: "",
                    stunden: 0,
                    umsatz: 0,
                    lohn: 0,
                    summe: 0,
                    ueberweisung: false,
                    rechnung: false,
                })

                exportData.push({
                    personalID: "",
                    name: `${Math.round(earningssum * 100) / 100}`,
                    positionName: "= Gesamt",
                    start: "",
                    ende: "",
                    stunden: 0,
                    umsatz: 0,
                    lohn: 0,
                    summe: 0,
                    ueberweisung: false,
                    rechnung: false,
                })

                let filteredData = () => {
                    return exportData
                        .filter((el) => {
                            if (filter.show_ueberweisung) {
                                return true
                            }
                            return !el.ueberweisung
                        })
                        .filter((el) => {
                            if (filter.show_rechnung) {
                                return true
                            }
                            return !el.rechnung
                        })
                }

                return filteredData()
            },
            tableView: (
                club: string,
                entered: Array<{ positionID: string; personalID: string; starttime: string }>,
                mode: number,
            ) => {
                // const isSubjectToChange = (obj: IAbrechnungItem): string => {
                //     return abrechnungen.subjectsToChange.indexOf(obj.positionID) !== -1 ? "changed" : ""
                // }

                const data: ITableObject = {
                    columns: [
                        {
                            label: "Position",
                            small: true,
                            valkey: "positionName",
                            // className: (obj): string => {
                            //     return (obj.color as string)
                            // },
                            viewFn: (obj) => renderAbrechnungData(obj, entered).positionname,
                            sortable: false,
                        },
                        // {
                        //   label: "ID",
                        //   small: true,
                        //   valkey: "sortPosition",
                        //   className: "hidden",
                        //   sortable: true,
                        // },
                        {
                            label: "Personal",
                            small: true,
                            viewFn: (obj) => renderAbrechnungData(obj, entered).name,
                            valkey: "",
                            sortable: false,
                        },
                        {
                            label: "Zeit",
                            small: true,
                            viewFn: (obj) => {
                                const start = renderAbrechnungData(obj, entered).start
                                const end = renderAbrechnungData(obj, entered).end

                                if (start === "" || end === "") {
                                    return "/"
                                }
                                if (start === end) {
                                    return "/"
                                }

                                return start + " - " + end
                            },
                            valkey: "",
                            sortable: false,
                        },
                        // {
                        //     label: "Start",
                        //     small: true,
                        //     viewFn: (obj) => renderAbrechnungData(obj, entered).start,
                        //     valkey: "starttime",
                        //     sortable: false,
                        // },
                        // {
                        //     label: "Ende",
                        //     small: true,
                        //     viewFn: (obj) => renderAbrechnungData(obj, entered).end,
                        //     valkey: "starttime",
                        //     sortable: false,
                        // },
                        {
                            label: "Stunden",
                            small: true,
                            viewFn: (obj) => renderAbrechnungData(obj, entered).hours,
                            valkey: "starttime",
                            sortable: false,
                        },
                        {
                            label: "Umsatz",
                            small: true,
                            viewFn: (obj) => renderAbrechnungData(obj, entered).earnings,
                            valkey: "starttime",
                            sortable: false,
                        },
                        {
                            label: "Lohn",
                            small: true,
                            viewFn: (obj) => renderAbrechnungData(obj, entered).salary,
                            valkey: "starttime",
                            sortable: false,
                        },
                        {
                            label: "Summe",
                            small: true,
                            viewFn: (obj) => renderAbrechnungData(obj, entered).sum,
                            valkey: "starttime",
                            sortable: false,
                        },
                    ],
                    data: views.filtered(club, mode),
                }
                return data
            },
        }
        return views
    })

listInstance = Positions.create({})
export default listInstance
// export default Locations
