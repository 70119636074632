import $ from "jquery"

import { getCookie } from "../utils/cookiemanager"

export interface IAbrechnungResponse {
    id: string
    date: string
    timestamp: string
    positionID: string
    personalID: string
    locationID: string
    earnings: string
    salary: string
    customwage: string
    sum: string
    start: string
    end: string
    hours: string
    savedBy: string
    miscType: string
    miscName: string
    miscTitle: string
    rechnung: string
    ueberweisung: string
    iscvd: string
    paidType: string
    savedAtDate: string
    paidFromPosition: string
}

export interface IRequestAbrechnung {
    date: string
    locationID: string
}

export const getAbrechnungen = (data: IRequestAbrechnung): Promise<IAbrechnungResponse[]> => {
    const { date, locationID } = data
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getabrechnung"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `getTagesabrechnungJSONtest`,
                date,
                locationID,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            if (res !== "fail") {
                //  console.log("done");
                //   einteilungforms.addToEinteilungen(res[0]);
            }
            resolve(result)
        })
        //  .always((res: any) => resolve(res.responseText));
    })
}
