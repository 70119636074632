import React, { Component } from "react"
import { observer } from "mobx-react"
import "./card.scss"

interface ICardProps {
    icon?: JSX.Element
    content: string | JSX.Element
    label: string
    dark?: boolean
    type?: "input" | "select"
    className?: string
}

@observer
class Card extends Component<ICardProps, {}> {
    public render() {
        const { label, content, dark, type, className = "" } = this.props
        return (
            <div className={`card ${dark ? "dark" : ""} ${type ? type : ""} ${className}`}>
                <span className={`card-content`}>{content}</span>
                <span className="card-label">{label}</span>
            </div>
        )
    }
}

export default Card
