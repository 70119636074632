import { TableChart } from "@material-ui/icons"
import bind from "bind-decorator"
import { DateTime } from "luxon"
import { observer } from "mobx-react"
import React, { Component } from "react"
import { Redirect } from "react-router"

import { jumpTo } from "."
import Loading from "../../components/loading"
import NotePopout from "../../components/popout"
import ECPopout from "../../components/popout/ecpopout"
import EntrancePopout from "../../components/popout/entrancepopout"
import GLPopout from "../../components/popout/glpopout"
import OverviewPopout from "../../components/popout/overviewpopout"
import PersVerzPopout from "../../components/popout/persverzpopout"
import TitlePopout from "../../components/popout/titlepopout"
import Table from "../../components/table"
import einteilungforms, { IAbrechnungForm } from "../../stores/abrechnungforms"
import guistate from "../../stores/guistate"
import locations, { LOCATIONNAMES } from "../../stores/locations"
import overviews, { Overview } from "../../stores/overviews"
import positions, { IPosition } from "../../stores/positions"
import { getCookie } from "../../utils/cookiemanager"
import { DayExport } from "../../utils/excelexport"

interface IDayManageState {
    statusfilter: string[],
    clubfilter: string[],
    exportfilter: {
        show_ueberweisung: boolean,
        show_rechnung: boolean
    }
}

interface IDayManageProps {
    id: string,
    date: string,
    locationID: string,
    posOpen?: string,
    einteilung: IAbrechnungForm,
    scrollTo?: string
    entered: Array<{ positionID: string; personalID: string; starttime: string }>
}

export const renderStatus = (obj) => {
    switch (obj.status) {
        case "0":
            return "Entwurf"
        case "1":
            return "Offen"
        case "2":
            return "Geschlossen"
        default:
            return ""
    }
}

@observer
class DayManage extends Component<IDayManageProps, IDayManageState> {
    constructor(props: any) {
        super(props)

        this.state = {
            statusfilter: ["0", "1", "2"],
            clubfilter: ["1", "2", "3", "4", "6"],
            exportfilter: {
                show_ueberweisung: true,
                show_rechnung: true
            }
        }

        this.filter = this.filter.bind(this)
        this.confirmFilter = this.confirmFilter.bind(this)
        this.onOpen = this.onOpen.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        const { locationID, entered, date, einteilung, posOpen, scrollTo } = this.props
        const formattedDate = DateTime.fromFormat(date, "yyyy-MM-dd")
        const { exportfilter } = this.state
        const { columns, data } = positions.tableView(locationID, entered, einteilung.mode)
        let overview = overviews.findByLocAndDate(locationID, date)
        let defaulttitle = ""
        if (locationID === "1") {
            const dayOfTheWeek = DateTime.fromFormat(date, "yyyy-MM-dd").weekday
            switch (dayOfTheWeek) {
                case 6:
                    defaulttitle = "Supreme Saturday"
                    break
                case 5:
                    defaulttitle = "Friyay@Heart"
                    break
                case 4:
                    defaulttitle = "Thumbs Up Thursday"
                    break
                default:
                    defaulttitle = "Nightclubbing"
                    break
            }
        } else {
            defaulttitle = "Nightclubbing"
        }
        if (!overview) {
            const userid = getCookie("userid")
            overview = Overview.create({
                locationID,
                date,
                savedBy: userid,
                eventName: einteilung.title.length === 0 ? defaulttitle : einteilung.title,
                entrancePrice: (locationID === "1" || locationID === "6") ? "9" : "7",
            })
        }

        return (
            <React.Fragment>
                {!positions.loaded && <Loading />}
                {positions.loaded && (
                    <React.Fragment>
                        <div className="top-action-buttons">
                            <NotePopout date={date} location={locationID}></NotePopout>
                            <ECPopout date={date} location={locationID}></ECPopout>
                            <GLPopout date={date} location={locationID}></GLPopout>
                            <EntrancePopout date={date} location={locationID}></EntrancePopout>
                            <PersVerzPopout date={date} location={locationID}></PersVerzPopout>
                            <TitlePopout overview={overview}></TitlePopout>
                            <OverviewPopout overview={overview}></OverviewPopout>
                            <div className="top-action-button">
                                <DayExport
                                    day={formattedDate.day}
                                    month={formattedDate.month}
                                    year={formattedDate.year}
                                    clubname={LOCATIONNAMES[parseInt(locationID, 10) - 1]}
                                    data={positions.exportData(exportfilter, locationID, entered)}
                                    exportbutton={
                                        this.exportButton()
                                    }
                                />
                            </div>
                            {/* <SettingsPopout date={date} location={locationID}></SettingsPopout> */}
                        </div>
                        <Table
                            scrollTo={scrollTo ? scrollTo : posOpen}
                            columns={columns}
                            data={data}
                            openId={posOpen ? posOpen : scrollTo}
                            onSort={this.onSort}
                            // unfolded={tag.unfolded}
                            sortKey={einteilungforms.sortCol}
                            colSort={einteilungforms.sortAsc}
                            onOpen={this.onOpen}
                        />
                    </React.Fragment>
                )}
                {/* this.renderNewEinteilungButton() */}
            </React.Fragment>
        )
    }

    public componentDidMount() {
        const { date, locationID } = this.props
        if (!locations.loaded) {
            locations.load()
        }
        // if (!userdata.loaded) {
        //     userdata.load()
        // }
        if (einteilungforms.loaded) {
            einteilungforms.setFilterObj({ status: ["0", "1", "2"] })
        } else {
            einteilungforms.load({})
        }
        if (!positions.loaded) {
            positions.load()
        }

        overviews.loadSingle(date, locationID)
    }

    @bind
    private exportButton() {
        return <span className="flex text-center">Tag exportieren<TableChart className="w-4 h-4 ml-1" /></span>
    }

    private onSort(key: string, dir: boolean) {
        einteilungforms.setSortCol(key)
        einteilungforms.setSortDir(dir)
    }

    private onOpen(item: IPosition) {
        const { id, locationID } = this.props
        jumpTo({ type: "view", id, posid: item.positionID, location: locationID })
    }

    private onDateConfirm(data) {
        let from = Math.round(DateTime.fromISO(data.from).toMillis() / 1000)
        let to = Math.round(DateTime.fromISO(data.to).toMillis() / 1000)
        if (data.from == null) {
            from = Math.round(
                DateTime.local()
                    .plus({ month: 2 })
                    .toMillis() / 1000,
            )
        }
        if (data.to == null) {
            to = Math.round(
                DateTime.local()
                    .minus({ month: 2 })
                    .toMillis() / 1000,
            )
        }
        guistate.setFrom(from)
        guistate.setTo(to)
        einteilungforms.load({ from, to })
        // console.log(from, to)
    }

    private filter(filterstr: string, add: boolean, truncate: boolean, source: string) {
        const { clubfilter, statusfilter } = this.state
        const newfilter: string[] = []
        const newstatusfilter: string[] = []
        clubfilter.forEach((el) => {
            newfilter.push(el)
        })
        statusfilter.forEach((el) => {
            newstatusfilter.push(el)
        })

        if (truncate) {
            this.setState({
                clubfilter: [],
                statusfilter: [],
            })
        } else {
            if (source === "Club") {
                if (add) {
                    newfilter.push(filterstr)
                    this.setState({
                        clubfilter: newfilter,
                    })
                } else if (!add) {
                    newfilter.splice(newfilter.indexOf(filterstr), 1)
                    this.setState({
                        clubfilter: newfilter,
                    })
                }
            } else if (source === "Status") {
                if (add) {
                    newstatusfilter.push(filterstr)
                    this.setState({
                        statusfilter: newstatusfilter,
                    })
                } else if (!add) {
                    newstatusfilter.splice(newstatusfilter.indexOf(filterstr), 1)
                    this.setState({
                        statusfilter: newstatusfilter,
                    })
                }
            }
        }
    }

    private confirmFilter(filter) {
        einteilungforms.setFilterObj({
            locationIDs: filter.Club,
            status: filter.Status,
        })
    }
}

export default DayManage
