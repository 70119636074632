import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import userdata, { IUserItem } from "../../stores/userdata"
import personalverzehr from "../../stores/allpersverzehr"
import Loading from "../../components/loading"
import { jumpTo } from "."
import Table from "../../components/table"

interface IEmployeesListState {
    searchstring: string
}

@observer
class PersonalList extends Component<{}, IEmployeesListState> {
    constructor(props: any) {
        super(props)

        this.state = {
            searchstring: "",
        }
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        const { columns, data } = userdata.verzehrTable
        return (
            <React.Fragment>
                {!userdata.loaded && <Loading />}
                <div className="searchbar">
                    <input
                        placeholder={"Name"}
                        value={userdata.searchstring}
                        onChange={(ev) => this.updateSearch(ev.target.value)}
                    />
                </div>
                <Table
                    columns={columns}
                    data={data}
                    onSort={this.onSort}
                    // unfolded={tag.unfolded}
                    sortKey={userdata.sortCol}
                    colSort={userdata.sortAsc}
                    onOpen={this.onOpen}
                ></Table>
            </React.Fragment>
        )
    }

    private onSort(key: string, dir: boolean) {
        userdata.setSortCol(key)
        userdata.setSortDir(dir)
    }

    private updateSearch(val: string) {
        userdata.setSearchString(val)
    }

    private onOpen(item: IUserItem) {
        personalverzehr.load(item.personalID)
        jumpTo({ type: "view", id: item.userID })
    }
}

export default PersonalList
