import $ from "jquery"

import { getCookie } from "../utils/cookiemanager"

export interface ISaveAbrechnung {
    id: string | null
    date: string
    timestamp: string
    positionID: string
    personalID: string
    locationID: string
    earnings: string
    salary: string
    sum: string
    start: string
    end: string
    hours: string
    savedBy: string
    miscType: string
    miscName: string
    miscTitle: string
    rechnung: string
    ueberweisung: string
    iscvd: string
    paidType: string
    savedAtDate: string
    paidFromPosition: string
    userid: string
}

// export interface IRequestAbrechnung {
//     date: string,
//     locationID: string,
// }

export const saveAbrechnung = (data: ISaveAbrechnung): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=saveabrechnung"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `saveTagesabrechnung`,
                data,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                if (res !== "fail") {
                    // fail
                }
                resolve(result)
            })
            .always((res: any) => {
                resolve(res.responseText)
            })
    })
}

export const deleteAbrechnungItem = (id): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=deleteabrechnungitem"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `deleteTagesabrechnungSingle`,
                id,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                // console.log(res)
                if (res !== "fail") {
                    // filler
                }
                resolve(result)
            })
            .always((res: any) => {
                // console.log(res.responseText)
                resolve(res.responseText)
            })
    })
}
