import { DateTime } from "luxon"
import { applySnapshot, Instance, types } from "mobx-state-tree"
import { get as oGet } from "object-path"

import { IEinteilungFormResponse, requestActiveAbrechnungByID, requestActiveEinteilungen } from "../api/geteinteilungen"
import { ITableObject } from "../components/table"
import { getCookie } from "../utils/cookiemanager"
import { toHumanDate, weekDay } from "../utils/dateformatter"
import { sortFn } from "../utils/sort"
import locations from "./locations"
import ListLoader from "./mixins/listloader"

// import Abrechnungstate from "./Abrechnungstate"
// import { renderInIcon, renderOutIcon, renderInAndClosedIcon } from "../apps/enterAbrechnung/enterAbrechnung_list"
// import { renderStatus } from "../apps/Abrechnung/Abrechnung_list"
export type IAbrechnungForm = Instance<typeof AbrechnungForm>
export type IAbrechnungForms = Instance<typeof AbrechnungForms>
// const IConfirmedAbrechnung = types.model({
//     userID: types.string,
//     positionID: types.string,
// })

const TAbrechnungItem = types.model({
    id: types.string,
    name: types.string,
    position: types.optional(types.string, ""),
    time: types.optional(types.string, ""),
})

const TAdditionalPosItem = types.model({
    additionalposition: types.string,
    personalid: types.string,
    title: types.string,
    name: types.string,
    time: types.string,
})

export const AbrechnungForm = types
    .model({
        einteilungID: types.string,
        locationID: types.string,
        title: types.string,
        date: types.string,
        ts: types.number,
        status: types.string,
        additionalInfo: types.string,
        additionalPos: types.optional(types.array(TAdditionalPosItem), []),
        available: types.optional(types.array(TAbrechnungItem), []),
        entered: types.optional(types.array(TAbrechnungItem), []),
        mode: types.number,
    })
    .volatile((self) => ({
        selected: false,
    }))
    .actions((self) => {
        const actions = {
            select: () => {
                self.selected = true
            },
            unselect: () => {
                self.selected = false
            },
            updateStatus: (newstatus: string) => {
                self.status = newstatus
            },
            removeFromEntered: (id, name) => {
                applySnapshot(
                    self.entered,
                    self.entered.filter((el) => el.id !== id),
                )
                // removeMeFromAbrechnung({AbrechnungID: self.AbrechnungID, name, myID: id})
            },
            removeFromAvailable: (id, name) => {
                applySnapshot(
                    self.available,
                    self.available.filter((el) => el.id !== id),
                )
            },
            addToEntered: (id, name, cat, time) => {
                self.entered.push({ id, name, position: cat, time })
                // addMeToAbrechnungConfirmed({ AbrechnungID: self.AbrechnungID, name, myID: id })
            },
            addToAvailable: (id, name) => {
                self.available.push({ id, name, position: "", time: "" })
                // addToMyEntered(id, self.AbrechnungID)
            },
            findForPos: (pos: string) => {
                return self.entered.find((el) => {
                    return el.position === pos
                })
            },
            updateEntered: (id, cat, time) => {
                const newentered = self.entered.map((el) => {
                    if (el.id === id) {
                        return { id: el.id, name: el.name, position: cat, time }
                    }
                    return { id: el.id, name: el.name, position: el.position, time }
                })
                self.entered.clear()
                applySnapshot(self.entered, newentered)
            },
            updateEnteredTime: (posid, time) => {
                const newentered = self.entered.map((el) => {
                    if (el.position === posid) {
                        return { id: el.id, name: el.name, position: el.position, time }
                    }
                    return { id: el.id, name: el.name, position: el.position, time: el.time }
                })
                self.entered.clear()
                applySnapshot(self.entered, newentered)
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            readableDate: () => {
                return toHumanDate(self.date)
            },
        }
        return views
    })

export const getSortEl = (item: IAbrechnungForm) => {
    if (item.title !== null) {
        return item.title || 0
    }
    return 0
}

const sortGetVAl = (obj: IAbrechnungForm, options: string): null | number | string => {
    return oGet(obj, options)
}

// const loadItems = (): Promise<any> => getlocations()

export interface IAbrechnungformFilter {
    query?: string
    locationIDs?: string[]
    status?: string[]
    mystatus?: string[]
}

const filterItems = (
    items: IAbrechnungForm[],
    filter: IAbrechnungformFilter = {},
    sortCol: string = "date",
    sortAsc: boolean,
): IAbrechnungForm[] => {
    if (Object.keys(filter).length <= 0) {
        return items.slice().sort(sortFn(sortGetVAl, sortAsc, sortCol))
    }
    return items
        .filter((item) => {
            // let returnindex = 0
            if (filter.locationIDs) {
                if (!(filter.locationIDs.indexOf(item.locationID) > -1)) {
                    return false
                }
            }
            if (filter.status) {
                if (!(filter.status.indexOf(item.status) > -1)) {
                    return false
                }
            }
            if (filter.mystatus) {
                const myID = getCookie("userid")
                if (filter.mystatus.indexOf("0") > -1 && filter.mystatus.indexOf("1") > -1) {
                    // filler
                } else {
                    if (filter.mystatus.indexOf("0") > -1) {
                        const enteredin = item.entered.filter((el) => el.id === myID)
                        const availableIn = item.available.filter((el) => el.id === myID)
                        if (enteredin.length > 0) {
                            // returnindex++
                            return false
                        }
                        if (availableIn.length > 0) {
                            // returnindex++
                            return false
                        }
                    }
                    if (filter.mystatus.indexOf("1") > -1) {
                        const enteredin = item.entered.filter((el) => el.id === myID)
                        const availableIn = item.available.filter((el) => el.id === myID)
                        if (enteredin.length === 0 && availableIn.length === 0) {
                            return false
                        }
                    }
                }
            }
            return true
        })
        .sort(sortFn(sortGetVAl, sortAsc, sortCol))
}

const loadAbrechnungForms = (): Promise<IEinteilungFormResponse[]> => requestActiveEinteilungen()

export const AbrechnungForms = types.compose(
    types
        .model({
            items: types.optional(types.array(AbrechnungForm), []),
            loaded: types.optional(types.boolean, false),
            loading: types.optional(types.boolean, false),
        })
        .volatile((self) => ({
            searchstring: "",
            filterObj: {},
            sortAsc: true,
            sortCol: "date",
        }))
        .actions((self) => {
            const actions = {
                setSearchString: (crit: string) => {
                    return (self.searchstring = crit)
                },
                setSortCol: (col: string) => {
                    return (self.sortCol = col)
                },
                setSortDir: (asc: boolean) => {
                    return (self.sortAsc = asc)
                },
                setFilterObj: (fObj: IAbrechnungformFilter) => {
                    return (self.filterObj = fObj)
                },
                addItems: (item: IAbrechnungForm) => {
                    self.items.push(item)
                },
                clearList: () => {
                    self.items.clear()
                },
                setLoaded: () => {
                    self.loaded = true
                },
                setLoading: () => {
                    self.loading = true
                },
                unsetLoading: () => {
                    self.loading = false
                },
                getAbrechnungenForUser: () => {
                    // filler
                },
                addToAbrechnungStates: (res) => {
                    //  Abrechnungstate.addAbrechnungen(res);
                },
                addToAbrechnungen: (res: IAbrechnungForm) => {
                    self.items.push(res)
                },
                loadSingle: (loadopts: { id: string }) => {
                    let id = loadopts.id
                    requestActiveAbrechnungByID(id).then((res: IEinteilungFormResponse[]) => {
                        try {
                            applySnapshot(self.items, res)
                            try {
                                actions.addToAbrechnungStates(res)
                            } catch (error) {
                                console.info(error)
                            }
                            actions.unsetLoading()
                            actions.setLoaded()
                        } catch (err) {
                            console.info(err)
                        }
                    })
                },
                load: (loadopts: { from?: number; to?: number; id?: string }) => {
                    let from = loadopts.from
                    let to = loadopts.to
                    if (loadopts.from == null) {
                        from = Math.round(DateTime.local().plus({ week: 3 }).toMillis() / 1000)
                    }
                    if (loadopts.to == null) {
                        to = Math.round(DateTime.local().minus({ week: 8 }).toMillis() / 1000)
                    }

                    try {
                        actions.clearList()
                        requestActiveEinteilungen(from, to).then((res: IEinteilungFormResponse[]) => {
                            try {
                                applySnapshot(self.items, res)
                                try {
                                    actions.addToAbrechnungStates(res)
                                } catch (error) {
                                    console.info(error)
                                }
                                actions.unsetLoading()
                                actions.setLoaded()
                            } catch (err) {
                                console.info(err)
                            }
                        })
                    } catch (err) {
                        console.error(err)
                    }
                },
            }
            return actions
        })
        .views((self) => {
            const views = {
                count: () => {
                    return self.items.length
                },
                countSelected: () => {
                    const selected = self.items.filter((el) => el.selected === true)
                    return selected.length
                },
                find: (id: string) => {
                    let res = self.items.find((el) => {
                        return el.einteilungID === id
                    })

                    if (res == null) {
                        self.setLoading()
                        self.loadSingle({ id })
                        res = self.items.find((el) => {
                            return el.einteilungID === id
                        })
                        return res
                    } else {
                        return res
                    }
                },
                filter: (query: string | null | any): IAbrechnungForm[] => {
                    if (query === null) {
                        return self.items
                    }
                    // return self.items
                    if (typeof query === "string") {
                        return filterItems(self.items, { query }, self.sortCol, self.sortAsc)
                    }
                    return filterItems(self.items, query, self.sortCol, self.sortAsc)
                },
                onlyOpen: (): IAbrechnungForm[] => {
                    return views.filter({ status: "1" })
                },
                onlyClosed: (): IAbrechnungForm[] => {
                    return views.filter({ status: "2" })
                },
                filtered: (status): IAbrechnungForm[] => {
                    if (self.filterObj !== {}) {
                        return views.filter(self.filterObj)
                    }
                    return views.filter(self.searchstring)
                },
                first: () => {
                    return self.items[0]
                },
                filteroptions: (showstatus: boolean, showmystatus?: boolean) => {
                    const filteroptions = [
                        {
                            filtertitle: "Club",
                            type: "multi",
                            filterlist: [
                                { value: "1", label: "Heart (alt)" },
                                { value: "2", label: "MIA" },
                                { value: "3", label: "Z Cham" },
                                { value: "4", label: "Z Köz" },
                                { value: "6", label: "Z Köz" },
                            ],
                        },
                    ]
                    if (showstatus) {
                        filteroptions.push({
                            filtertitle: "Status",
                            type: "multi",
                            filterlist: [
                                { value: "0", label: "Entwurf" },
                                { value: "1", label: "Offen" },
                                { value: "2", label: "Geschlossen" },
                            ],
                        })
                    }
                    if (showmystatus) {
                        filteroptions.push({
                            filtertitle: "MyStatus",
                            type: "multi",
                            filterlist: [
                                { value: "0", label: "Nicht eingetragen" },
                                { value: "1", label: "Eingetragen" },
                                //  { value: "2", label: "Eingeteilt" }
                            ],
                        })
                    }
                    return filteroptions
                },
                mailView: (type: string) => {
                    const weekday = (obj: IAbrechnungForm) => {
                        return weekDay(obj.date, true)
                    }

                    const parsedDate = (obj: IAbrechnungForm) => {
                        return toHumanDate(obj.date) + " " + weekday(obj)
                    }

                    const parsedClub = (obj: IAbrechnungForm) => {
                        const location = locations.find(obj.locationID)
                        if (location) {
                            return location.locationName
                        }
                        return ""
                    }

                    const viewtype = () => {
                        switch (type) {
                            case "all":
                                return views.filter({})
                            case "onlyopen":
                                return views.onlyOpen()
                            default:
                                return views.onlyClosed()
                        }
                    }

                    const data: ITableObject = {
                        columns: [
                            {
                                label: "Datum",
                                small: true,
                                viewFn: parsedDate,
                                valkey: "date",
                                sortable: true,
                            },
                            {
                                label: "Club",
                                small: true,
                                viewFn: parsedClub,
                                valkey: "location",
                                sortable: false,
                            },
                            {
                                label: "Titel",
                                small: true,
                                valkey: "title",
                                sortable: false,
                            },
                        ],
                        data: viewtype(),
                    }
                    return data
                },
                tableView: (
                    showentered: boolean,
                    filter: string[] | null,
                    userid: string | null,
                    statusfilter: string[],
                ) => {
                    // const countEntered = (obj: IAbrechnungForm) => {
                    //     const location = locations.find(obj.locationID)
                    //     if (location) {
                    //         const posCount = location.positionIDs.split(";").length
                    //         return obj.available.length + " | " + obj.entered.length + " | " + posCount
                    //     }
                    //     return ""
                    // }

                    const weekday = (obj: IAbrechnungForm) => {
                        return weekDay(obj.date, true)
                    }

                    const parsedDate = (obj: IAbrechnungForm) => {
                        return toHumanDate(obj.date) + " " + weekday(obj)
                    }

                    const parsedClub = (obj: IAbrechnungForm) => {
                        const location = locations.find(obj.locationID)
                        if (location) {
                            return location.locationName
                        }
                        return ""
                    }

                    const data: ITableObject = {
                        columns: [
                            {
                                label: "Datum",
                                small: true,
                                viewFn: parsedDate,
                                valkey: "date",
                                sortable: true,
                            },
                            {
                                label: "Club",
                                small: true,
                                viewFn: parsedClub,
                                valkey: "location",
                                sortable: false,
                            },
                            {
                                label: "Titel",
                                small: true,
                                valkey: "title",
                                sortable: false,
                            },
                        ],
                        data: views.filtered(statusfilter),
                    }
                    return data
                },
            }
            return views
        }),
    ListLoader<any>(AbrechnungForm, loadAbrechnungForms),
)

const iInstance: IAbrechnungForms = AbrechnungForms.create({})
export default iInstance
