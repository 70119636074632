import { destroy, IType, types } from "mobx-state-tree"

// eslint-disable-next-line import/no-anonymous-default-export
export default <T>(model: IType<{}, {}, {}>, fnLoad: () => Promise<T[]>, loadOnCreate: boolean = true) => {
    return types
        .model({
            items: types.array(model),
            loaded: types.optional(types.boolean, false),
            loading: types.optional(types.boolean, false),
        })
        .actions((self) => {
            const actions = {
                add: (item: T) => {
                    self.items.push(item)
                },
                remove: (item: T) => {
                    destroy(item)
                },
                reset: () => {
                    self.loaded = false
                },
            }
            return actions
        })
}
