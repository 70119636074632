import { Instance, types, applySnapshot } from "mobx-state-tree"
import { getPersverzehr, savePersverzehr, ISavePersonalverzehrApi } from "../api/persverzehr"

export interface IPersonalverzehrItem extends Instance<typeof PersonalVerzehrItem> {}

let noteInstance: IPersonalverzehrItem

const TPersverzehrItemDetail = types.model({
    amount: types.string,
    personalID: types.string,
})

export const PersonalVerzehrItem = types
    .model({
        date: types.optional(types.string, ""),
        locationID: types.optional(types.string, ""),
        content: types.array(TPersverzehrItemDetail),
    })
    .actions((self) => {
        const actions = {
            load: ({ locationID, date }) => {
                getPersverzehr(locationID, date).then((res: any[]) => {
                    if (res) {
                        if (res.length > 0) {
                            applySnapshot(self.content, res)
                        }
                    }
                })
            },
            updatedate: (date: string) => {
                self.date = date
            },
            updatelocationid: (id: string) => {
                self.locationID = id
            },
            addToList: (data: { amount: string; personalID: string }) => {
                const containedList = self.content.filter((el) => el.personalID === data.personalID)
                if (containedList.length === 0) {
                    self.content.push(data)
                } else {
                    containedList[0].amount = (parseFloat(containedList[0].amount) + parseFloat(data.amount)).toFixed(2)
                }
            },
            save: (date: string, location, userID, onSuccess: () => void) => {
                const noteObj: ISavePersonalverzehrApi = {
                    locationID: location,
                    date,
                    content: self.content,
                    userID,
                }
                savePersverzehr(noteObj).then((res) => {
                    if (res === "ok") {
                        // tslint:disable-next-line: no-unused-expression
                        onSuccess()
                    } else {
                        alert("Fehlgeschlagen")
                    }
                })
            },
        }
        return actions
    })

noteInstance = PersonalVerzehrItem.create({})
export default noteInstance
