import { Instance, types } from "mobx-state-tree"
export type TMenuIcons = "edit" | "eintragen" | "meineeinteilung" | "einteilung" | "mail"

interface IMenu {
    title: string
    path: string
    icon: string
    minrole: number
}

export type IGuiState = Instance<typeof Menu>
export type IMenus = Instance<typeof Menus>

const Menu = types
    .model({
        title: types.optional(types.string, ""),
        path: types.optional(types.string, ""),
        icon: types.optional(types.string, ""),
        minrole: types.optional(types.number, 0),
    })
    .actions((self: any) => {
        const actions = {}
        return actions
    })

const Menus = types
    .model({
        items: types.array(Menu),
    })
    .volatile((self) => ({
        loaded: false,
    }))
    .actions((self) => {
        const actions = {
            load: (userrole: number) => {
                actions.clearList()
                const allmenus = [
                    { title: "Abrechnung", icon: "calc", path: "tagesabrechnung", minrole: 3 },
                    { title: "Übersicht", icon: "overview", path: "overview", minrole: 3 },
                    { title: "Löhne", icon: "wallet", path: "loehne", minrole: 2 },
                    { title: "Umsätze", icon: "money", path: "umsatze", minrole: 2 },
                    { title: "Mitarbeiter", icon: "users", path: "employees", minrole: 2 },
                    { title: "Personalverzehr", icon: "personalverzehr", path: "personalverzehr", minrole: 3 },
                ]
                allmenus.forEach((el) => {
                    if (el.minrole >= userrole) {
                        actions.addItem(el)
                    }
                })
                self.loaded = true
            },
            clearList: () => {
                self.items.clear()
            },
            addItem: (elem: IMenu) => {
                self.items.push(elem)
            },
        }
        return actions
    })

const iInstance: IMenus = Menus.create({})
export default iInstance
