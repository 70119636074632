import { observer } from "mobx-react"
import { get as getObj } from "object-path"
import * as React from "react"
import { ITableColumn, ITableData } from "."
import { Visibility, VisibilityOff } from "@material-ui/icons"

interface ITableDataContentProps {
    col: ITableColumn
    el: ITableData
}

@observer
export class TableDataContent extends React.Component<ITableDataContentProps, {}> {
    public render() {
        const { col, el } = this.props
        return <span>{typeof col.viewFn === "function" ? col.viewFn(el) : getObj(el, col.valkey)}</span>
    }
}

interface ITableDataProps {
    col: ITableColumn
    onSelect?: (el: any, state: boolean) => void
    onOpen?: (el: any) => void
    toggleShowDetail?: () => void
    detailOpen?: boolean
    className?: string
    el: ITableData
}

@observer
export default class TableData extends React.Component<ITableDataProps, {}> {
    constructor(props: ITableDataProps, context?: any) {
        super(props, context)
        this.handleOpen = this.handleOpen.bind(this)
    }
    public render() {
        const { col, onSelect, onOpen, el, toggleShowDetail } = this.props

        if (onSelect && col.valkey === "_sel") {
            return (
                <td key={col.valkey} className="select-toggle">
                    <span>
                        <input
                            type="checkbox"
                            checked={el.selected}
                            onChange={(ev) => {
                                onSelect(el, ev.target.checked)
                            }}
                        />
                    </span>
                </td>
            )
        }

        if (toggleShowDetail && col.valkey === "_detail") {
            const detailOpen = this.props.detailOpen == null ? false : this.props.detailOpen
            return (
                <td key={col.valkey} className={"detail-toggle" + (detailOpen ? " open" : "")}>
                    {detailOpen ? (
                        <VisibilityOff onClick={toggleShowDetail} />
                    ) : (
                        <Visibility onClick={toggleShowDetail} />
                    )}
                </td>
            )
        }

        if (onOpen != null) {
            return (
                <td key={col.valkey} className={col.className} onClick={this.handleOpen}>
                    <TableDataContent col={col} el={el} />
                </td>
            )
        }

        return (
            <td key={col.valkey} className={col.className}>
                <TableDataContent col={col} el={el} />
            </td>
        )
    }

    private handleOpen(ev: React.MouseEvent<HTMLTableDataCellElement>) {
        const { onOpen, el } = this.props
        const target = ev.target as HTMLBaseElement
        if (target != null && target.tagName === "A") {
            return
        }

        if (el != null && onOpen != null) {
            onOpen(el)
        }
    }
}
