import "./newsbutton.scss"

import { Notifications } from "@material-ui/icons"
import dotenv from "dotenv"
import { observer } from "mobx-react"
import React, { Component } from "react"

import { getLatestVersion } from "../../api/getnews"
import guistate from "../../stores/guistate"
import newsitems from "../../stores/news"
import { getCookie } from "../../utils/cookiemanager"

interface INewsButtonState {
    dropopen: boolean
}

interface INewsButtonProps {
    setauth: (auth: boolean) => void
}

@observer
class NewsButton extends Component<INewsButtonProps, INewsButtonState> {
    public nameRef: React.RefObject<HTMLInputElement>
    public pwRef: React.RefObject<HTMLInputElement>
    public wrapperRef: React.RefObject<any>
    constructor(props: any) {
        super(props)

        this.state = {
            dropopen: false,
        }

        this.nameRef = React.createRef()
        this.pwRef = React.createRef()
        this.wrapperRef = React.createRef()
        this.openProfileDrop = this.openProfileDrop.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    public render() {
        return (
            <div className={this.renderClassname()}>
                <Notifications className={"newsbutton" + (this.checkNews() ? " shake" : " nonews")} onClick={
                    () => {
                        if (this.checkNews()) {
                            this.openProfileDrop()
                        }
                    }
                } />
                {this.state.dropopen &&
                    <div className="newsitems" ref={this.wrapperRef}>
                        {newsitems.items.length > 0 &&
                            newsitems.items.map((el) => {
                                const readableDate = new Date(el.ts * 1000).toLocaleString()
                                return (
                                    <div className="newsitem">
                                        <span className="news_content">{el.content}</span>
                                        <span className="news_date">{readableDate}</span>
                                    </div>
                                )
                            })
                        }
                        {newsitems.versionitems.length > 0 &&
                            <div className="versionitem">
                                <span className="news_content">
                                    {newsitems.versionitems[newsitems.versionitems.length - 1].version}
                                </span>
                                <span className="news_content">
                                    {newsitems.versionitems[newsitems.versionitems.length - 1].content}
                                </span>
                                <span className="news_date">
                                    {newsitems.versionitems[newsitems.versionitems.length - 1].readabledate()}
                                </span>
                                <br></br>
                                <h4>Bitte neu laden</h4>
                            </div>
                        }
                    </div>
                }
            </div>
        )
    }

    public componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside)
        dotenv.config()
        const VERSION: string = "v" + (process.env.REACT_APP_VERSION_NUM)
        getLatestVersion().then((res) => {
            const lastindex = res.length - 1
            if (res[lastindex].version !== VERSION) {
                newsitems.addVersionItems(res)
            }
        },
        )
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside)
    }

    private checkNews(): boolean {
        if (newsitems.items.length > 0 || newsitems.versionitems.length > 0) {
            return true
        }
        return false
    }

    private renderClassname() {
        if (guistate.token == null) {
            if (getCookie("token").length === 0) {
                return "pbhidden"
            }
        }
    }

    private handleClickOutside(event) {
        if (event.target.classList[0] !== undefined) {
            this.setState({
                dropopen: false,
            })
        }
    }

    private openProfileDrop() {
        this.setState({
            dropopen: true,
        })
    }
}

export default NewsButton
