export const isNum = (value: string) => {
    const re = new RegExp(/^[0-9]*[.|,]?[0-9]*?$/g)
    return re.test(value)
}

export const removeLeadingZero = (value: string) => {
    const re = new RegExp(/0*[1-9]*?/g)
    let teststring = value
    let decimals = "00"
    if (value.includes(",")) {
        teststring = value.split(",")[0]
        decimals = value.split(",")[1]
        if (re.test(teststring)) {
            return parseInt(teststring, 10).toString() + "," + decimals
        }
    } else if (value.includes(".")) {
        teststring = value.split(".")[0]
        decimals = value.split(".")[1]
        if (re.test(teststring)) {
            return parseInt(teststring, 10).toString() + "." + decimals
        }
    }
    if (re.test(teststring)) {
        return parseInt(teststring, 10).toString()
    }
    return value
}
