import $ from "jquery"

import { getCookie } from "../utils/cookiemanager"

export interface IEinteilungFormResponse {
    einteilungID: string
    title: string
    date: string
    ts: number
    additionalInfo: string
    additionalPos: Array<{
        additionalposition: string
        personalid: string
        title: string
        name: string
        time: string
    }>
    status: string
    locationID: string
    namesentered: string[]
    available: Array<{ id: string; name: string; position: string; time?: string | "" }>
    entered: Array<{ id: string; name: string; position: string; time?: string | "" }>
    mode: number
}

export const requestActiveEinteilungen = (from?, to?): Promise<IEinteilungFormResponse[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getactiveeinteilungen"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getEinteilungsForms`,
                from,
                to,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(res)
        })
    })
}

export const requestLatestAbrechnungen = (): Promise<IEinteilungFormResponse[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getlatestabrechnungen"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getLatestAbrechnungen`,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(res)
        })
    })
}

export const requestActiveAbrechnungByID = (id: string): Promise<IEinteilungFormResponse[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=requestabrechnungbyid"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getActiveAbrechnungByID`,
                id,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(res)
        })
    })
}
