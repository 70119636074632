import React, { Component } from "react"
import { observer } from "mobx-react"
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons"
import "./headerfilter.scss"
import HeaderDatePicker from "../datepicker"
import bind from "bind-decorator"

interface IHeaderFilterState {
    filteropen: boolean
}

interface IHeaderFilterProps {
    filteroptions?: Array<{ filtertitle: string; type: string; filterlist: any[] }>
    currentstate: any
    withdatefilter?: boolean
    onReset?: () => void
    onConfirm: (filter: any) => void
    onDateConfirm?: (data: any) => void
    onOptionFilter: (filteroption: string, add: boolean, truncate: boolean, source: string) => void
}

@observer
class HeaderFilter extends Component<IHeaderFilterProps, IHeaderFilterState> {
    constructor(props: any) {
        super(props)

        this.state = {
            filteropen: false,
        }

        this.confirmDate = this.confirmDate.bind(this)
    }

    public render() {
        const { filteropen } = this.state
        const { currentstate, withdatefilter } = this.props
        return (
            <React.Fragment>
                <div className="header-filter">
                    {withdatefilter && <HeaderDatePicker onDateConfirm={this.confirmDate}></HeaderDatePicker>}
                    <div
                        className="filter-button"
                        onClick={() => {
                            this.setState({
                                filteropen: true,
                            })
                        }}
                    >
                        Filter
                    </div>
                </div>
                {filteropen && (
                    <div className={"filter-dialog" + this.getSizeClass(currentstate)}>
                        <p>Filter</p>
                        {this.renderOptions()}
                        <div className="dialog-buttons">
                            <div
                                className="dialog-button"
                                onClick={() => {
                                    this.setState({
                                        filteropen: false,
                                    })
                                }}
                            >
                                Schließen
                            </div>
                            <div
                                className="dialog-button"
                                onClick={() => {
                                    this.confirmFilter(currentstate)
                                    this.setState({
                                        filteropen: false,
                                    })
                                }}
                            >
                                Anwenden
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }

    private renderOptions() {
        const { filteroptions } = this.props
        if (filteroptions) {
            return (
                <React.Fragment>
                    {filteroptions.map((item, idx) => {
                        return (
                            <React.Fragment key={idx}>
                                <hr></hr>
                                <div className={"filter-area " + idx} key={idx}>
                                    {item.type === "multi" && this.renderCheckboxes(item.filterlist, item.filtertitle)}
                                    {item.type === "single" &&
                                        this.renderRadiobuttons(item.filterlist, item.filtertitle)}
                                </div>
                            </React.Fragment>
                        )
                    })}
                </React.Fragment>
            )
        }
        return undefined
    }

    @bind
    private renderCheckboxes(options: Array<{ label: string; value: string }>, src) {
        const { currentstate, onOptionFilter } = this.props
        return options.map((el, idx) => {
            return (
                <React.Fragment key={idx}>
                    <div className="filter-checkboxes">
                        <div className="checkbox-wrapper">
                            {currentstate[src] != null && this.renderChecked(currentstate[src], el.value) && (
                                <CheckBox
                                    onClick={() => {
                                        onOptionFilter(el.value, false, false, src)
                                    }}
                                ></CheckBox>
                            )}
                            {currentstate[src] != null && !this.renderChecked(currentstate[src], el.value) && (
                                <CheckBoxOutlineBlank
                                    onClick={() => {
                                        onOptionFilter(el.value, true, false, src)
                                    }}
                                ></CheckBoxOutlineBlank>
                            )}
                        </div>
                        <label htmlFor={"checkbox_" + idx}>{el.label}</label>
                    </div>
                </React.Fragment>
            )
        })
    }

    private confirmFilter(currentstate) {
        const { onConfirm } = this.props
        onConfirm(currentstate)
    }

    private confirmDate(date) {
        const { onDateConfirm } = this.props
        if (onDateConfirm) {
            onDateConfirm(date)
        }
    }

    private renderChecked(currentstate, value): boolean {
        let checked = false
        if (currentstate.indexOf("" + value) > -1) {
            checked = true
        }
        return checked
    }

    private renderRadiobuttons(options: Array<{ label: string; value: string }>, src) {
        return options.map((el, idx) => {
            return (
                <input key={idx} type="radio" value={el.value}>
                    {el.label}
                </input>
            )
        })
    }

    private getSizeClass(options) {
        let sizeclass = " small"
        const len = Object.keys(options).length
        if (len === 2) {
            sizeclass = " medium"
        } else if (len === 3) {
            sizeclass = " large"
        }
        return sizeclass
    }
}

export default HeaderFilter
