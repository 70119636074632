import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import "./employees.scss"

import "react-toastify/dist/ReactToastify.css"
import DetailView from "components/detailview"
import { jumpTo } from "."
import { IUserItem } from "stores/userdata"
import bind from "bind-decorator"
import Card from "components/card"
// import Filelist from "components/filelist"
import { CloudDownloadOutlined } from "@material-ui/icons"
import { getHoursForYear, IPersonalHoursResponse } from "api/getpersonaldata"
import { DateTime } from "luxon"
import DatePicker from "react-datepicker"
import { UserHoursExport } from "utils/excelexport"
import { LOCATIONNAMES, LOCATIONS } from "stores/locations"

interface IEmployeesViewProps {
    user: IUserItem,
    userhours: IPersonalHoursResponse[]
}

export interface IExtendedPersonalHours extends IPersonalHoursResponse {
    name?: string,
    club?: string
    stbid?: string
}

interface IEmployeesViewState {
    date: Date
    userhours: IExtendedPersonalHours[]
    hourssum: number
}

@observer
class EmployeesView extends Component<IEmployeesViewProps, IEmployeesViewState> {
    constructor(props: any) {
        super(props)

        this.state = {
            date: DateTime.now().toJSDate(),
            userhours: this.sortAndReturn(this.props.userhours, this.props.user),
            hourssum: 0
        }
    }

    public render() {
        const { user } = this.props
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        return (
            <DetailView
                header={() => this.renderHeader(user)}
                content={() => this.renderInfo()}
                onClose={() => jumpTo({ type: "list" })}
                onEdit={() =>
                    jumpTo({
                        type: "edit",
                        id: user.userID,
                    })
                }
            />
        )
    }

    @bind
    private renderHeader(user: IUserItem) {
        const { date, hourssum } = this.state
        return <div className="flex justify-between">
            <h3>{user.realName}</h3>
            <div className="flex items-center">
                <DatePicker
                    filterDate={(date) => {
                        return date.getFullYear() >= 2019
                    }}
                    selected={date}
                    onChange={(selectedDate) => {
                        this.setState({
                            date: selectedDate
                        })
                        let month = (selectedDate.getMonth() + 1).toString()
                        if (month.length === 1) {
                            month = "0" + month
                        }
                        getHoursForYear(month, selectedDate.getFullYear().toString(), user.personalID).then((res) => {
                            this.sortHours(res, user)
                        })
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    showFullMonthYearPicker />

                <div className="cursor-pointer export-excel-month">
                    <UserHoursExport
                        month={date.getMonth() + 1}
                        year={date.getFullYear()}
                        data={this.state.userhours}
                        user={user}
                        hourssum={hourssum}
                        exportbutton={
                            <CloudDownloadOutlined className="mx-2" onClick={() => {

                            }} />
                        }
                    />
                </div>
            </div>
        </div>
    }

    private sortAndReturn(res: IPersonalHoursResponse[], user: IUserItem) {

        let hourssum = 0
        const resSorted = res.sort((a, b) => {
            return parseInt(a.date.replaceAll("-", "")) - parseInt(b.date.replaceAll("-", ""))
        })

        resSorted.forEach(el => {
            hourssum += parseFloat(el.hours)
        })

        const byLocation = {
            "1": [],
            "2": [],
            "3": [],
            "4": [],
            "5": [],
            "6": [],
        }

        resSorted.forEach((el) => {
            if (el.rechnung === "0") {
                byLocation[el.location].push(el)
            }
        })

        for (let i = 1; i < 7; i++) {
            if (byLocation[i.toString()].length === 0) {
                byLocation[i.toString()] = []
            } else {
                byLocation[i.toString()][0].club = LOCATIONNAMES[i - 1]
            }
        }

        let result = [
            ...byLocation["1"],
            ...byLocation["2"],
            ...byLocation["3"],
            ...byLocation["4"],
            ...byLocation["6"],
            {
                stbid: "",
                name: "",
                club: "",
                personalID: "",
                date: "",
                location: "",
                hours: hourssum.toString(),
                start: "",
                end: "Summe",
                rechnung: ""
            }
        ]

        if (result.length > 1) {
            result[0].stbid = user.stbID ? user.stbID : ""
            result[1].stbid = user.realName
        } else {
            result = []
        }

        return result
    }

    private sortHours(res: IPersonalHoursResponse[], user: IUserItem) {

        let hourssum = 0
        const resSorted = res.sort((a, b) => {
            return parseInt(a.date.replaceAll("-", "")) - parseInt(b.date.replaceAll("-", ""))
        })

        resSorted.forEach(el => {
            hourssum += parseFloat(el.hours)
        })

        const byLocation = {
            "1": [],
            "2": [],
            "3": [],
            "4": [],
            "6": []
        }

        resSorted.forEach((el) => {
            if (el.rechnung === "0") {
                byLocation[el.location].push(el)
            }
        })

        for (let i = 1; i < 5; i++) {
            if (byLocation[i.toString()].length === 0) {
                byLocation[i.toString()] = []
            } else {
                byLocation[i.toString()][0].club = LOCATIONNAMES[i - 1]
            }
        }

        let result = [
            ...byLocation["1"],
            ...byLocation["2"],
            ...byLocation["3"],
            ...byLocation["4"],
            ...byLocation["6"],
            {
                stbid: "",
                name: "",
                club: "",
                personalID: "",
                date: "",
                location: "",
                hours: hourssum.toString(),
                start: "",
                end: "Summe",
                rechnung: ""
            }
        ]

        if (result.length > 1) {
            result[0].stbid = user.stbID ? user.stbID : ""
            result[1].stbid = user.realName
        } else {
            result = []
        }

        this.setState({
            userhours: result,
            hourssum
        })
    }

    @bind
    private renderInfo() {
        const { user } = this.props
        return (
            <>
                <Card dark={true} content={user.realName} label="Name"></Card>
                <br></br>
                <Card dark={true} content={user.username} label="Nutzername"></Card>
                <br></br>
                <Card dark={true} content={user.readableMail} label="E-Mail"></Card>
                <br></br>
                <Card dark={true} content={user.readableonline} label="Zuletzt online"></Card>
                <br></br>
                <Card
                    dark={true}
                    content={user.parsedMeta.address !== "" ? user.parsedMeta.address : "-"}
                    label="Adresse"
                ></Card>
                <br></br>
                <Card
                    dark={true}
                    content={user.parsedMeta.mobile !== "" ? user.parsedMeta.mobile : "-"}
                    label="Telefon"
                ></Card>
                <br></br>
                <Card dark={true} content={user.stbID !== "" ? user.stbID : "-"} label="ID Steuerbüro"></Card>
                <br />
                <Card
                    className={"h-36"}
                    dark={true}
                    content={<div className="flex flex-col">{user.preferredLocations().map((locationID) => {
                        return <span className="text-sm font-semibold">{LOCATIONS[locationID]}</span>
                    })}</div>}
                    label="Clubs"
                />
                {/* <Filelist edit={false} userID={user.userID} path="contracts/" /> */}
            </>
        )
    }
}

export default EmployeesView
