import "./tagesabrechnung.scss"

import { observer } from "mobx-react"
import React, { Component } from "react"
import { Redirect } from "react-router"

import { jumpTo } from "."
import DetailView from "../../components/detailview"
import abrechnungen, { IAbrechnungItem } from "../../stores/abrechnung"
import { IAbrechnungForm } from "../../stores/abrechnungforms"
import locations from "../../stores/locations"
import positions, { IPosition } from "../../stores/positions"
import userdata from "../../stores/userdata"
import { getCookie } from "../../utils/cookiemanager"
import Tooltip from "rc-tooltip"

// import { myeinteilung } from "../../stores/";
interface IListState {
    filter: "0" | "1" | "2",
    locationID: string
}

interface IEnterEinteilungProps {
    einteilung: IAbrechnungForm,
    position: IPosition,
    entered: Array<{ positionID: string; personalID: string; starttime: string }>,
    onClose: () => void
}

@observer
class ViewAbrechnungDetail extends Component<IEnterEinteilungProps, IListState> {
    constructor(props: any) {
        super(props)

        this.state = {
            filter: "1",
            locationID: "",
        }
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        const { einteilung, position, onClose } = this.props
        const location = locations.find(einteilung.locationID)
        // const abrData = renderAbrechnungData(position, entered)

        return (
            <DetailView
                header={() => this.renderHeader(einteilung, location, position)}
                content={() => this.renderInputs()}
                onClose={onClose}
                onEdit={() =>
                    jumpTo({
                        type: "edit",
                        id: einteilung.einteilungID,
                        posid: position.positionID,
                        location: einteilung.locationID,
                    })
                }
            />
        )
    }

    private renderHeader(_einteilung, _location, position: IPosition) {
        const abrItem = abrechnungen.get(position.positionID)
        let name = ""
        let lastDay = ""
        let showWarning = false
        let posTitle = position.positionName
        if (abrItem) {
            const personalid = abrItem.personalID
            const user = userdata.items.filter((el) => el.personalID === personalid && el.personalID !== "0")
            posTitle = abrItem.miscTitle === "" ? abrItem.positionname : abrItem.miscTitle
            if (user.length > 0) {
                name = user[0].realName
                lastDay = user[0].readableLastDay
                showWarning = user[0].warnLastDaySince
            } else {
                name = abrItem.miscName
            }
        }
        return (
            <div className="view-abr-item-header">
                <div>
                    <div className="abrechnung-item-pos">{posTitle}</div>
                    <br></br>
                    <div className="abrechnung-item-name">{name}</div>
                </div>
                {showWarning && <div className={"last-day"}>
                    <Tooltip placement="bottomLeft" overlay="Der letzte Arbeitstag liegt länger als 2 Monate zurück. Muss der Mitarbeiter neu angemeldet werden?">
                        <span>Letzter Tag: {lastDay}</span>
                    </Tooltip>
                </div>}
            </div>
        )
    }

    private renderHoursEdit(abrechnung) {
        return (
            <div className="timeframe">
                <span>von {abrechnung.start}</span>
                <span>bis {abrechnung.end}</span>
                <span>{abrechnung.hours} Stunden</span>
            </div>
        )
    }

    private renderLohnResult(abrechnung: IAbrechnungItem) {
        return <div className="viewdata-summary-content">
            <span>Lohn</span>
            <span>{abrechnung.salary} €</span>
        </div>
    }

    private renderPaidFrom(paidfrom) {
        const position = positions.find(paidfrom)
        return <div>bez. aus {position ? position.positionName : "eigen"}</div>
    }

    private renderPays(abrechnung: IAbrechnungItem) {
        return <>
            {abrechnung.position.haswallet() && this.renderPaysList(abrechnung)}
        </>
    }

    private renderPaysList(abrechnung: IAbrechnungItem) {
        const abrData = abrechnungen.items.filter((el) => {
            return el.paidFromPosition === this.props.position.positionID
        })
        if (abrData.length === 0) return <div></div>
        if (abrData.length === 1) {
            if (abrData[0].positionID === abrechnung.position.positionID) return <div></div>
        }
        return (
            <div className="view-pays-list-wrp">
                <ul className="view-pays-list">
                    {abrData.map((el, idx) => {
                        const user = userdata.items.filter((usr) => usr.personalID === el.personalID)
                        if (user.length > 0) {
                            if (el.positionID !== abrechnung.position.positionID) {
                                return (
                                    <li key={idx}>
                                        <div className="pays-position">
                                            {el.miscTitle === "" ? el.positionname : el.miscTitle}:{" "}
                                        </div>
                                        <div className="pays-name">{user[0].realName}</div>
                                        <div className="pays-salary">{el.salary} €</div>
                                    </li>
                                )
                            }
                        }
                        return <></>
                    })}
                </ul>
                <hr></hr>
                <div className="view-pays-footer">
                    aus Geldbeutel bezahlt: {abrechnung.payssum()} €
                </div>
            </div>
        )
    }

    private renderUberweisung(abrechnung: IAbrechnungItem) {
        return <div className="viewdata-paidType" >
            <span className="value">{abrechnung.ueberweisung === "1" ? "Lohn per Überweisung" : "Lohn in Bar"}</span >
        </div>
    }

    private renderGesamtumsatz(abrechnung) {
        return <div className="viewdata-summary-content">
            <span>Gesamtumsatz</span>
            <span>{abrechnung.earnings} €</span>
        </div>
    }

    private renderGesamtsumme(abrechnung) {
        return <div className="viewdata-summary-content">
            <span>Summe</span>
            <span >{abrechnung.sum} €</span>
        </div>
    }

    private renderInputs(): JSX.Element {
        const { position } = this.props
        const abrechnung = abrechnungen.get(position.positionID)
        if (abrechnung) {
            return (
                <div>
                    {this.renderUberweisung(abrechnung)}
                    <hr></hr>
                    {this.renderPays(abrechnung)}
                    {this.renderHoursEdit(abrechnung)}
                    <hr></hr>
                    {abrechnung.paidFromPosition !== "0" && (
                        <React.Fragment>
                            {abrechnung.paidFromPosition !== position.positionID &&
                                this.renderPaidFrom(abrechnung.paidFromPosition)}
                        </React.Fragment>
                    )}
                    <br></br>
                    <div className="viewdata-summary">
                        <span>{this.renderGesamtumsatz(abrechnung)}</span>
                        <br></br>
                        <span>{this.renderLohnResult(abrechnung)}</span>
                        <br></br>
                        <span>{this.renderGesamtsumme(abrechnung)}</span>
                    </div>
                </div>
            )
        }
        return <div>Noch keine Einträge gespeichert</div>
    }
}

export default ViewAbrechnungDetail
