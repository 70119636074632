import $ from "jquery"

import { getCookie } from "../utils/cookiemanager"
import { IAbrechnungResponse } from "./abrechung"

export interface IUserData {
    userID: string
    username: string
    realName: string
    mail: string
    stbID: string
    role: string
    locations: string
}

export interface IUserDataResponse extends IUserData {
    personalID: string
    lastonline: string
    entered: string[]
    confirmed: Array<{ einteilungID: string; position: string }>
    meta: string
    preferredclubs: string
    lastDay: string
}

export interface ISalariesResponse {
    date: string
    personalID: string
    salary: string
    earnings: string
    hours: string
    ueberweisung: string
    rechnung: string
    positionID: string
}

export interface IPersonalHoursResponse {
    personalID: string
    date: string
    location: string
    hours: string
    start: string
    end: string
    rechnung: string
}

export const getHoursForYear = (month, year, id): Promise<IPersonalHoursResponse[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getuserhours"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getUserHours`,
                user: id,
                month,
                year,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const getUserData = (): Promise<IUserDataResponse[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getuserdata"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getUserData`,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const getUserLohn = (id): Promise<IUserDataResponse[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getuserlohn"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getUserLohn`,
                id,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const getUserLohnAndEarningForYear = (id, year): Promise<ISalariesResponse[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getuserlohnandearningforyear"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getUserLohnAndEarningForYear`,
                id,
                year,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const getAbrechnungForMonth = (date): Promise<IAbrechnungResponse[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getabrechnungformonth"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getAbrechnungForMonth`,
                date,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const deleteUser = (personalID): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=deleteuser"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `deleteUser`,
                personalID,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                // console.log(res)
                if (res !== "fail") {
                    // filler
                }
                resolve(result)
            })
            .always((res: any) => {
                // console.log(res.responseText)
                resolve(res.responseText)
            })
    })
}

export const updateUserData = (data: IUserData): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=setstbid"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `updatePersonalData`,
                ...data,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                // console.log(res)
                if (res !== "fail") {
                    // filler
                }
                resolve(result)
            })
            .always((res: any) => {
                // console.log(res.responseText)
                resolve(res.responseText)
            })
    })
}

export const addUser = (data: { shortcode; fullname; userid; mail; locations }): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                action: `saveNewMitarbeiter`,
                shortcode: data.shortcode,
                fullname: data.fullname,
                userid: data.userid,
                mail: data.mail,
                locations: data.locations,
                stoken: getCookie("token"),
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                // console.log(res)
                if (res !== "fail") {
                    // filler
                }
                resolve(result)
            })
            .always((res: any) => {
                // console.log(res.responseText)
                resolve(res.responseText)
            })
    })
}
