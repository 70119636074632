import "./notepopout.scss"

import { observer } from "mobx-react"
import React, { Component } from "react"

import personalverzehr from "../../stores/personalverzehr"
import userdata from "../../stores/userdata"
import { getCookie } from "../../utils/cookiemanager"
import { AutoSuggest } from "../autosuggest"

interface IPersVerzPopoutState {
    open: boolean
    currentamount: string
    currentpersonalID: string
}

interface IPersVerzPopoutProps {
    location: string
    date: string
}

@observer
class PersVerzPopout extends Component<IPersVerzPopoutProps, IPersVerzPopoutState> {
    constructor(props: any) {
        super(props)

        this.state = {
            open: false,
            currentamount: "0",
            currentpersonalID: "",
        }

        this.onSelectName = this.onSelectName.bind(this)
    }

    public render() {
        const { open } = this.state
        const { date, location } = this.props
        const usernames = userdata.items.map((el) => {
            return { name: el.realName, id: el.userID, personalid: el.personalID }
        })
        // let persverzehrData = personalverzehr.findByLocAndDate(location, date)
        // if (!persverzehrData) {
        //     const userid = getCookie("userid")
        //     persverzehrData = PersonalVerzehrItem.create({
        //         locationID: location,
        //         date,
        //         content: [],
        //     })
        // }

        return (
            <React.Fragment>
                <div className="top-action-button"
                    onClick={() => {
                        personalverzehr.load({ locationID: location, date })
                        this.setState({
                            open: true,
                        })
                    }}
                >
                    Personalverzehr
                </div>
                {open && (
                    <div className={"popout-dialog"}>
                        <h4>Personalverzehr</h4>
                        <ul className="personalverzehr-list">
                            {personalverzehr.content.map((el, idx) => {
                                const personal = userdata.find(el.personalID + "")
                                if (personal) {
                                    return (
                                        <li key={idx}>
                                            {personal.realName}: {el.amount}€
                                        </li>
                                    )
                                }
                                return <></>
                            })}
                        </ul>
                        <div className="input-fields">
                            <AutoSuggest
                                default={{ name: "", personalid: "", id: "" }}
                                onSelectName={this.onSelectName}
                                list={usernames}
                            ></AutoSuggest>
                            <input
                                className="amount-input"
                                type="number"
                                value={this.state.currentamount}
                                onChange={(ev) => {
                                    ev.persist()
                                    let val = ev.target.value
                                    if (val === "") {
                                        val = "0"
                                    }
                                    this.setState({
                                        currentamount: val,
                                    })
                                }}
                            ></input>
                        </div>
                        <div
                            className="add-persverzehr"
                            onClick={() => {
                                personalverzehr.addToList({
                                    amount: this.state.currentamount,
                                    personalID: this.state.currentpersonalID,
                                })
                                this.setState({
                                    currentpersonalID: "",
                                    currentamount: "0",
                                })
                            }}
                        >
                            +
                        </div>
                        <div className="dialog-buttons">
                            <div
                                className="dialog-button"
                                onClick={() => {
                                    this.setState({
                                        open: false,
                                    })
                                }}
                            >
                                Schließen
                            </div>
                            <div
                                className="dialog-button"
                                onClick={() => {
                                    const userid = getCookie("userid")
                                    if (personalverzehr) {
                                        personalverzehr.save(date, location, userid, () => this.setState({ open: false }))
                                    }
                                }}
                            >
                                Speichern
                            </div>
                        </div>
                    </div>
                )}
            </React.Fragment>
        )
    }

    private onSelectName(name, id, personalid) {
        this.setState({
            currentpersonalID: id,
        })
    }
}

export default PersVerzPopout
