import "./App.scss"
import "react-toastify/dist/ReactToastify.css"

import dotenv from "dotenv"
import { DateTime } from "luxon"
import { observer } from "mobx-react"
import React from "react"
import { Redirect, Router, Switch } from "react-router"
import { Route } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import Earnings from "./apps/earnings"
import Employees from "./apps/employees"
import Overview from "./apps/overview"
import Personalverzehr from "./apps/personalverzehr"
import Profil from "./apps/profil"
import Salaries from "./apps/salaries"
import Tagesabrechnung from "./apps/tagesabrechnung"
import NewsButton from "./components/newsbutton"
import ProfileButton from "./components/profilebutton"
import SideMenu from "./components/sidebar"
import guistate from "./stores/guistate"
import menus from "./stores/menus"
import { history, historyHandler } from "./stores/router"
import { getCookie } from "./utils/cookiemanager"
import { periodicNewsRequest } from "./utils/liveservice"

dotenv.config()
const VERSION: string = process.env.REACT_APP_VERSION_NUM || "0.0.1"

export const renderClassName = (): string => {
    let classname = ""
    if (guistate.isAuth) {
        classname += " show"
    } else {
        classname += " hide"
    }
    return classname
}

interface IAppProps {
    setauth: (auth: boolean) => void
}

@observer
class App extends React.Component<IAppProps, {}> {
    constructor(props: any) {
        super(props)
        this.state = {}
        this.render = this.render.bind(this)
    }
    public render() {
        const basepath = history.location.pathname
        const { setauth } = this.props
        historyHandler(basepath)
        return (
            <>
                <div className="apphead">
                    <span className="version-no">{`v${VERSION}`}</span>
                    <span className="apphead-title">{guistate.apphead}</span>
                    <span className="openday"></span>
                </div>
                <ToastContainer></ToastContainer>
                <Router history={history}>
                    <div className={"content"}>
                        <SideMenu />
                        <div className="main-content">
                            <ProfileButton setauth={setauth} />
                            {<NewsButton setauth={setauth} />}
                            <Switch>
                                <Route path="/tagesabrechnung" component={Tagesabrechnung} />
                                <Route path="/profil" component={Profil} />
                                <Route path="/overview" component={Overview} />
                                <Route path="/loehne" component={Salaries} />
                                <Route path="/umsatze" component={Earnings} />
                                <Route path="/employees" component={Employees} />
                                <Route path="/personalverzehr" component={Personalverzehr} />
                                {this.renderRedirPath()}
                            </Switch>
                        </div>

                    </div>
                </Router>
            </>
        )
    }

    public componentDidMount() {
        DateTime.local().setZone("local")
        console.info("Are you looking for something, punk?!")
        if (getCookie("token").length > 0) {
            const token = getCookie("token")
            const tlen = token.length
            const role = token[tlen - 1]
            menus.load(parseInt(role, 10))
            guistate.setAuth(true)
            periodicNewsRequest()
        } else {
            console.info("no cookies set")
        }
    }

    private renderRedirPath() {
        return <Redirect to={"/tagesabrechnung"}></Redirect>
    }
}

export default App
