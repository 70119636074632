import "./detailview.scss"

import bind from "bind-decorator"
import { observer } from "mobx-react"
import React, { Component } from "react"

interface IDetailViewProps {
  header?: () => JSX.Element,
  content: () => JSX.Element | JSX.Element[],
  onClose?: () => void,
  onDelete?: () => void,
  onSave?: () => void,
  onCancel?: () => void,
  onEdit?: (allowed?: boolean) => void,
  allowSave?: boolean,
  allowEdit?: boolean,
  headerCls?: string
}

interface IDetailViewState {
  deleteconfirm: boolean
}

@observer
class DetailView extends Component<IDetailViewProps, IDetailViewState> {
  constructor(props: any) {
    super(props)

    this.state = {
      deleteconfirm: false
    }

    this.renderFooter = this.renderFooter.bind(this)
  }

  public render() {
    const { header, content, headerCls } = this.props
    const { deleteconfirm } = this.state
    return (
      <React.Fragment>
        {!deleteconfirm && <div className="flex flex-col">
          {header &&
            <div className={"section-head " + (headerCls ? headerCls : "")}>{header()}</div>
          }
          <div className="section-content">{content()}</div>
          <div className="section-footer">{this.renderFooter()}</div>
        </div>}
        {deleteconfirm && <div>
          {this.renderDeleteConfirm()}
        </div>}
      </React.Fragment>
    )
  }

  @bind
  private renderFooter() {
    const { onClose, onSave, onEdit, onCancel, allowEdit, onDelete } = this.props
    // const { deleteconfirm } = this.state
    return (
      <React.Fragment>
        {onClose && (
          <div className="formbutton close" onClick={() => onClose()}>
            Schließen
          </div>
        )}

        {onEdit && (
          <div className={"formbutton" + (allowEdit ? " allow" : " disallow")} onClick={() => onEdit()}>
            Bearbeiten
          </div>
        )}
        {onCancel && (
          <div className={"formbutton"} onClick={() => onCancel()}>
            Abbrechen
          </div>
        )}
        {onDelete && (
          <div className={"formbutton delete"} onClick={() => {
            this.setState({
              deleteconfirm: true
            })
          }}>
            Löschen
          </div>
        )}
        {onSave && (
          <div className={"formbutton save" + (allowEdit ? " allow" : " disallow")} onClick={() => onSave()}>
            Speichern
          </div>
        )}
      </React.Fragment>
    )
  }

  @bind
  private renderDeleteConfirm() {
    const { onDelete } = this.props

    if (onDelete) {
      return <div>
        <div className="section-head"></div>
        <div className="section-content single-item">
          <div className="formbutton delete" onClick={() => onDelete()}>
            Wirklich löschen?
          </div>
        </div>
        <div className="section-footer">
          <div className="formbutton cancel" onClick={() => this.setState({
            deleteconfirm: false
          })}>
            Abbrechen
          </div>
        </div>
      </div>
    }
    return <div></div>
  }

}

export default DetailView
