import React, { Component } from "react"
import { observer } from "mobx-react"
import guistate from "../../stores/guistate"
import { historyHandler } from "../../stores/router"
import { AccountBox } from "@material-ui/icons"
import "./profilebutton.scss"
import { getCookie } from "../../utils/cookiemanager"

interface IProfileButtonState {
    dropopen: boolean
}

interface IProfileButtonProps {
    setauth: (auth: boolean) => void
}

@observer
class ProfileButton extends Component<IProfileButtonProps, IProfileButtonState> {
    public nameRef: React.RefObject<HTMLInputElement>
    public pwRef: React.RefObject<HTMLInputElement>
    public wrapperRef: React.RefObject<any>
    constructor(props: any) {
        super(props)

        this.state = {
            dropopen: false,
        }

        this.nameRef = React.createRef()
        this.pwRef = React.createRef()
        this.wrapperRef = React.createRef()
        this.openProfileDrop = this.openProfileDrop.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    public render() {
        const { setauth } = this.props
        return (
            <div className={this.renderClassname()}>
                <AccountBox className="profilebutton" onClick={
                    () => {
                        this.openProfileDrop()
                    }
                } />
                {this.state.dropopen &&
                    <div className="buttons" ref={this.wrapperRef}>
                        <ul className="profiledrop">
                            <div className="realname">
                                <span>{getCookie("realname")}</span>
                            </div>
                            <li className="button" onClick={
                                () => {
                                    this.setState({
                                        dropopen: false,
                                    })
                                    historyHandler("/profil")
                                }
                            }>
                                <span>Profil</span>
                            </li>
                            <li className="button" onClick={
                                () => {
                                    guistate.logout()
                                    this.setState({
                                        dropopen: false,
                                    })
                                    setauth(false)
                                }
                            }>
                                <span>Ausloggen</span></li>
                        </ul>
                    </div>
                }
            </div>
        )
    }

    public componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside)
    }

    public componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside)
    }

    private renderClassname() {
        if (guistate.token == null) {
            if (getCookie("token").length === 0) {
                return "pbhidden"
            }
        }
    }

    private handleClickOutside(event) {
        if (event.target.classList[0] !== undefined) {
            this.setState({
                dropopen: false,
            })
        }
    }

    private openProfileDrop() {
        this.setState({
            dropopen: true,
        })
    }
}

export default ProfileButton
