import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect, Route, RouteComponentProps } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import genGetUrl, { TGetUrl } from "../../utils/geturl"
import userdata from "../../stores/userdata"
import overviews from "../../stores/overviews"
import locations from "../../stores/locations"
import { updateApphead, updateDay } from "../../utils/dommanip"
import Loading from "../../components/loading"
import OverviewList from "./overviewlist"

const basepath = "/overview"

export const { getUrl, jumpTo } = genGetUrl((opt: TGetUrl): string => {
    switch (opt.type) {
        case "view":
            return `${basepath}/view/${opt.id}`
        case "list":
            return `${basepath}/list/`
        default:
            return basepath
    }
})

interface ITagesabrechnungProps
    extends RouteComponentProps<{
        id: string
        posid: string
        location: string
    }> { }

@observer
class Tagesabrechnung extends Component<ITagesabrechnungProps, {}> {
    public nameRef: React.RefObject<HTMLInputElement>
    public pwRef: React.RefObject<HTMLInputElement>
    constructor(props: any, context?: any) {
        super(props, context)

        this.nameRef = React.createRef()
        this.pwRef = React.createRef()
        this.list = this.list.bind(this)
        this.showMain = this.showMain.bind(this)
        this.viewItem = this.viewItem.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        if (!overviews.loaded) {
            return <Loading></Loading>
        }
        return (
            <React.Fragment>
                <Route path={getUrl({ type: "" })} exact render={this.showMain} />
                <Route path={getUrl({ type: "list" })} exact render={this.list} />
                <Route path={getUrl({ type: "view", id: ":id" })} exact render={this.viewItem} />
            </React.Fragment>
        )
    }

    public componentDidMount() {
        updateApphead("Übersicht", basepath)
        updateDay("")
        userdata.load()
        // DateTime.local().setZone("local");
        overviews.load({})
        if (!locations.loaded) {
            // filler
        }
    }

    private showMain(): JSX.Element {
        return this.list()
    }

    private list(): JSX.Element {
        return <OverviewList></OverviewList>
    }

    private viewItem({ match }: ITagesabrechnungProps): JSX.Element {
        // if (einteilung) {
        //   return (
        //     <div></div>
        //   );
        // }
        return <Redirect to={getUrl({ type: "list" })}></Redirect>
    }
}

export default Tagesabrechnung
