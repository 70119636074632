import { applySnapshot, Instance, types } from "mobx-state-tree"
import { get as oGet } from "object-path"

import { getpositiontypes, IPositionTypeApi } from "../api/positions"
import { sortFn } from "../utils/sort"

export interface IPositionType extends Instance<typeof PositionType> {}
export interface IPositionTypes extends Instance<typeof PositionTypes> {}

let listInstance: IPositionTypes

export const PositionType = types
    .model({
        typeID: types.string,
        description: types.string,
        umsatzgenerierend: types.string,
        stundenlohn_assistent: types.string,
        stundenlohn_theke: types.string,
        umsatzanteil: types.string,
    })
    .volatile((self) => ({
        selected: false,
    }))
    .actions((self) => {
        const actions = {
            select() {
                self.selected = true
            },
            unselect() {
                self.selected = false
            },
        }
        return actions
    })
    .views((self) => {
        const views = {}
        return views
    })

export const getSortEl = (item: IPositionType) => {
    if (item.typeID != null) {
        return item.typeID || 0
    }
    return 0
}

const sortGetVAl = (obj: IPositionType, options: string): null | number | string => {
    return oGet(obj, options)
}

// const loadItems = (): Promise<IPositionTypeApi[]> => getpositiontypes()

export interface IClientFilter {
    query?: string
    id?: string[]
    domain?: string
}

const filterItems = (
    items: IPositionType[],
    query: string | null,
    sortCol: string,
    sortAsc: boolean,
): IPositionType[] => {
    if (query == null || !query.length) {
        return items.sort(sortFn(sortGetVAl, sortAsc, sortCol))
    }
    return items
        .filter((el) => {
            // const q = query.toLowerCase()
            if (el.typeID !== query) {
                return false
            }
            return true
        })
        .sort(sortFn(sortGetVAl, sortAsc, sortCol))
}

export const PositionTypes = types
    .model({
        items: types.array(PositionType),
    })
    .volatile((self) => ({
        searchstring: "",
        filterObj: {},
        sortAsc: true,
        sortCol: "created",
        loaded: false,
    }))
    .actions((self) => {
        const actions = {
            setSearchString: (crit: string) => {
                return (self.searchstring = crit)
            },
            setSortCol: (col: string) => {
                return (self.sortCol = col)
            },
            setSortDir: (asc: boolean) => {
                return (self.sortAsc = asc)
            },
            setFilterObj: (fObj: IClientFilter) => {
                return (self.filterObj = fObj)
            },
            clearList: () => {
                self.items.clear()
            },
            isContained: (id: string) => {
                let contains = 0
                self.items.toJSON().forEach((el) => {
                    if (el.typeID === id) {
                        contains++
                    }
                })
                return contains !== 0
            },
            setLoaded: () => {
                self.loaded = true
            },
            load: () => {
                // if (guistate.client_id == null || loaded) {
                //     return
                // }
                try {
                    actions.clearList()
                    getpositiontypes().then((res: IPositionTypeApi[]) => {
                        // res.map((item) => actions.addItems(item))
                        applySnapshot(self.items, res)
                        actions.setLoaded()
                    })
                } catch (err) {
                    console.error(err)
                }
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            get: (id: string) => {
                return self.items.find((el) => el.typeID === id)
            },
            first: () => {
                return self.items[0]
            },
            filteroptions: () => {
                return []
            },
            filter: (query: string | null, sortCol: string) => {
                return filterItems(self.items, query, self.sortCol, self.sortAsc)
            },
            filtered: (club: string) => {
                return views.filter(club, self.sortCol)
            },
            selected: () => {
                return views.filtered("").filter((el) => el)
            },
        }
        return views
    })

listInstance = PositionTypes.create({})
export default listInstance
// export default Locations
