import guistate from "../stores/guistate"

export const updateApphead = (head: string, path: string) => {
    if (document.getElementsByClassName("apphead-title")[0]) {
        document.getElementsByClassName("apphead-title")[0].innerHTML = head
        guistate.setActiveMenu(path.replace("/", ""))
    }
}

export const updateDay = (head: string) => {
    if (document.getElementsByClassName("openday")[0]) {
        document.getElementsByClassName("openday")[0].innerHTML = head
    }
}
