export const sortFn = <T>(
    fnGeVal: (obj: T, options: any) => null | number | string,
    asc: boolean = false,
    options?: any,
) => {
    return (elA: T, elB: T): number => {
        const factor = asc ? 1 : -1
        const vA = fnGeVal(elA, options)
        const vB = fnGeVal(elB, options)

        if (vA == null || vB == null) {
            return -1 * factor
        }
        if (typeof vA === "string" && typeof vB === "string") {
            return vA.localeCompare(vB) * factor
        }
        if (vA > vB) {
            return -1 * factor
        }
        if (vA < vB) {
            return 1 * factor
        }
        return 0
    }
}
