import "./employees.scss"

import { ChevronLeft, ToggleOffTwoTone, ToggleOnOutlined } from "@material-ui/icons"
import bind from "bind-decorator"
import { observer } from "mobx-react"
import React, { Component } from "react"
import { Redirect } from "react-router"

import { getAbrechnungForMonth, getUserLohnAndEarningForYear, ISalariesResponse } from "../../api/getpersonaldata"
import { AutoSuggest } from "../../components/autosuggest"
import Loading from "../../components/loading"
import { IAbrechnungItem } from "../../stores/abrechnung"
import userdata from "../../stores/userdata"
import { getCookie } from "../../utils/cookiemanager"
import { MONTHNAMES, YEARS } from "../../utils/dateformatter"
import { updateApphead, updateDay } from "../../utils/dommanip"
import genGetUrl, { TGetUrl } from "../../utils/geturl"
import { DateTime } from "luxon"

const basepath = "/umsatze"

export const { getUrl, jumpTo } = genGetUrl((opt: TGetUrl): string => {
    switch (opt.type) {
        default:
            return basepath
    }
})

interface IEmployeesState {
    year: string
    month: number
    club?: string
    employeeID: string
    searchname: string
    abrechnungen: IAbrechnungItem[]
    employees: Array<{ personalID: string; name: string; earnings: number }>
    loading: boolean
    requestPersonalID: string | null
    showmonths: boolean
    earningsdata: ISalariesResponse[]
    yearearnings: number
    monthsworked: number
    monthsearnings: any
    resetted: boolean
    daysworked: number
}

@observer
class Salaries extends Component<{}, IEmployeesState> {
    // tslint:disable-next-line: member-access
    state = {
        year: DateTime.now().year.toString(),
        month: 0,
        club: "",
        employeeID: "",
        searchname: "",
        abrechnungen: [],
        employees: [],
        loading: false,
        requestPersonalID: null,
        showmonths: false,
        earningsdata: [],
        monthsearnings: {
            1: { sortNo: 1, earnings: 0 },
            2: { sortNo: 2, earnings: 0 },
            3: { sortNo: 3, earnings: 0 },
            4: { sortNo: 4, earnings: 0 },
            5: { sortNo: 5, earnings: 0 },
            6: { sortNo: 6, earnings: 0 },
            7: { sortNo: 7, earnings: 0 },
            8: { sortNo: 8, earnings: 0 },
            9: { sortNo: 9, earnings: 0 },
            10: { sortNo: 10, earnings: 0 },
            11: { sortNo: 11, earnings: 0 },
            12: { sortNo: 12, earnings: 0 },
        },
        monthsworked: 0,
        yearearnings: 0,
        daysworked: 0,
        resetted: true,
    }

    public render() {
        const { showmonths } = this.state
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        if (!userdata.loaded) {
            return <Loading></Loading>
        }
        return (
            <div className="employees-panel">
                {this.yearPanel()}
                {this.monthPanel()}
                {showmonths && this.employeePanel()}
                {this.dayPanel()}
            </div>
        )
    }

    public componentDidMount() {
        updateApphead("Umsätze", basepath)
        updateDay("")
        userdata.load()
    }

    @bind
    private yearPanel() {
        const { showmonths, yearearnings, monthsearnings, resetted, year } = this.state
        return (
            <div className="panel">
                {YEARS.map((yearelem, idx) => {
                    return (
                        <div
                            key={idx}
                            onClick={() => {
                                this.setState({
                                    year: yearelem,
                                    monthsearnings: {
                                        1: { sortNo: 1, earnings: 0 },
                                        2: { sortNo: 2, earnings: 0 },
                                        3: { sortNo: 3, earnings: 0 },
                                        4: { sortNo: 4, earnings: 0 },
                                        5: { sortNo: 5, earnings: 0 },
                                        6: { sortNo: 6, earnings: 0 },
                                        7: { sortNo: 7, earnings: 0 },
                                        8: { sortNo: 8, earnings: 0 },
                                        9: { sortNo: 9, earnings: 0 },
                                        10: { sortNo: 10, earnings: 0 },
                                        11: { sortNo: 11, earnings: 0 },
                                        12: { sortNo: 12, earnings: 0 },
                                    },
                                    yearearnings: 0,
                                    daysworked: 0,
                                })
                            }}
                            className={`button ${year === yearelem ? "selected" : ""}`}
                        >
                            {yearelem}
                        </div>
                    )
                })}
                <div className="showmonths-toggle">
                    {showmonths && (
                        <ToggleOffTwoTone
                            onClick={() => {
                                this.setState({
                                    showmonths: false,
                                })
                            }}
                        ></ToggleOffTwoTone>
                    )}
                    {!showmonths && (
                        <ToggleOnOutlined
                            onClick={() => {
                                this.setState({
                                    showmonths: true,
                                    monthsearnings: {
                                        1: { sortNo: 1, earnings: 0 },
                                        2: { sortNo: 2, earnings: 0 },
                                        3: { sortNo: 3, earnings: 0 },
                                        4: { sortNo: 4, earnings: 0 },
                                        5: { sortNo: 5, earnings: 0 },
                                        6: { sortNo: 6, earnings: 0 },
                                        7: { sortNo: 7, earnings: 0 },
                                        8: { sortNo: 8, earnings: 0 },
                                        9: { sortNo: 9, earnings: 0 },
                                        10: { sortNo: 10, earnings: 0 },
                                        11: { sortNo: 11, earnings: 0 },
                                        12: { sortNo: 12, earnings: 0 },
                                    },
                                    yearearnings: 0,
                                    daysworked: 0,
                                })
                            }}
                        ></ToggleOnOutlined>
                    )}
                    <span>{showmonths ? "Gesamtübersicht" : "Einzelne Mitarbeiter"}</span>
                </div>
                {!showmonths && (
                    <>
                        <br></br>
                        <AutoSuggest
                            list={userdata.items.map((el) => {
                                return { name: el.realName, id: el.userID, personalid: el.personalID }
                            })}
                            default={{ name: "", personalid: "", id: "" }}
                            onSelectName={(name, id, personalid) =>
                                this.setState({
                                    requestPersonalID: personalid,
                                    monthsearnings: {
                                        1: { sortNo: 1, earnings: 0 },
                                        2: { sortNo: 2, earnings: 0 },
                                        3: { sortNo: 3, earnings: 0 },
                                        4: { sortNo: 4, earnings: 0 },
                                        5: { sortNo: 5, earnings: 0 },
                                        6: { sortNo: 6, earnings: 0 },
                                        7: { sortNo: 7, earnings: 0 },
                                        8: { sortNo: 8, earnings: 0 },
                                        9: { sortNo: 9, earnings: 0 },
                                        10: { sortNo: 10, earnings: 0 },
                                        11: { sortNo: 11, earnings: 0 },
                                        12: { sortNo: 12, earnings: 0 },
                                    },
                                    yearearnings: 0,
                                    daysworked: 0,
                                })
                            }
                        ></AutoSuggest>
                        {resetted && (
                            <button
                                className="h-10 border-0 rounded cursor-pointer request-salary-button w-36"
                                onClick={() => {
                                    getUserLohnAndEarningForYear(this.state.requestPersonalID, this.state.year).then(
                                        (res) => {
                                            this.setState({
                                                earningsdata: res,
                                            })
                                            let summedSalary = 0
                                            let counter = 0
                                            res.forEach((el) => {
                                                const month = parseInt(el.date.split("-")[1], 10)
                                                monthsearnings[month].earnings += parseFloat(el.earnings)
                                                summedSalary += parseFloat(el.earnings)
                                                if (el.earnings !== "0") {
                                                    counter++
                                                }
                                            })
                                            this.setState({
                                                yearearnings: summedSalary,
                                                resetted: false,
                                                daysworked: counter,
                                            })
                                        },
                                    )
                                }}
                            >
                                Abfragen
                            </button>
                        )}
                        {!resetted && (
                            <button
                                onClick={() => {
                                    this.setState({
                                        yearearnings: 0,
                                        daysworked: 0,
                                        monthsearnings: {
                                            1: { sortNo: 1, earnings: 0 },
                                            2: { sortNo: 2, earnings: 0 },
                                            3: { sortNo: 3, earnings: 0 },
                                            4: { sortNo: 4, earnings: 0 },
                                            5: { sortNo: 5, earnings: 0 },
                                            6: { sortNo: 6, earnings: 0 },
                                            7: { sortNo: 7, earnings: 0 },
                                            8: { sortNo: 8, earnings: 0 },
                                            9: { sortNo: 9, earnings: 0 },
                                            10: { sortNo: 10, earnings: 0 },
                                            11: { sortNo: 11, earnings: 0 },
                                            12: { sortNo: 12, earnings: 0 },
                                        },
                                        resetted: true,
                                    })
                                }}
                                className="reset-button"
                            >
                                Suche zurücksetzen
                            </button>
                        )}
                        <br></br>
                        <br></br>
                        <span className={"yearlohn"}>
                            {year}: {yearearnings} €
                        </span>
                        <br></br>
                        <br></br>
                        <span className={"yearlohn"}>
                            Schnitt:
                            {this.renderAvg()} €
                        </span>
                        <br></br>
                        <br></br>
                        <span className={"yearlohn"}>
                            Tagesschnitt:
                            {this.renderDayAvg()} €
                        </span>
                    </>
                )}
            </div>
        )
    }

    @bind
    private renderAvg() {
        const { monthsearnings, yearearnings } = this.state
        let counter = 0
        for (let i = 1; i < 13; i++) {
            if (monthsearnings[i].earnings > 0) {
                counter++
            }
        }
        return yearearnings === 0 ? " 0" : ` ${(yearearnings / counter).toFixed(2)}`
    }

    @bind
    private renderDayAvg() {
        const { yearearnings, daysworked } = this.state
        return yearearnings === 0 ? " 0" : ` ${(yearearnings / daysworked).toFixed(2)}`
    }

    @bind
    private monthPanel() {
        const { showmonths, earningsdata, monthsearnings } = this.state
        if (showmonths) {
            return (
                <div className="panel">
                    <div className="scroll-wrp">
                        {MONTHNAMES.map((month, idx) => {
                            return (
                                <div
                                    key={idx}
                                    onClick={() => {
                                        this.setState({
                                            month: idx + 1,
                                        })
                                        this.getAbrData(idx + 1)
                                    }}
                                    className={`button ${this.state.month === idx + 1 ? "selected" : ""}`}
                                >
                                    {month}
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        } else if (earningsdata.length > 0) {
            return (
                <div className="panel">
                    <div className="scroll-wrp">
                        {MONTHNAMES.map((month, idx) => {
                            return (
                                <div
                                    key={idx}
                                    onClick={() => {
                                        this.setState({
                                            month: idx + 1,
                                        })
                                        this.getAbrData(idx + 1)
                                    }}
                                    className={`button ${this.state.month === idx + 1 ? "selected" : ""}`}
                                >
                                    {month}: {monthsearnings[idx + 1].earnings} €
                                </div>
                            )
                        })}
                    </div>
                </div>
            )
        }
        return undefined
    }

    @bind
    private employeePanel() {
        const { searchname, employees, loading } = this.state
        return (
            <div className="panel scrollable">
                {loading && <Loading></Loading>}
                {!loading && (
                    <>
                        {employees.length > 0 && (
                            <>
                                <input
                                    className="panel-search"
                                    type="text"
                                    placeholder="Suche"
                                    value={searchname}
                                    onChange={(ev) => {
                                        ev.persist()
                                        this.setState({
                                            searchname: ev.target.value,
                                        })
                                    }}
                                />
                                <div className="scroll-wrp">
                                    {employees
                                        .filter((elem: { personalID; name; earnings }) =>
                                            elem.name.includes(searchname),
                                        )
                                        .map((el: { id; name; earnings }, items) => {
                                            return (
                                                <div key={items} className="button">
                                                    <span>
                                                        {el.name}: {el.earnings}€
                                                    </span>
                                                </div>
                                            )
                                        })}
                                </div>
                            </>
                        )}
                        {employees.length === 0 && (
                            <p className="empty-msg">
                                <ChevronLeft></ChevronLeft>
                                <span>Bitte wählen Sie einen Monat aus</span>
                            </p>
                        )}
                    </>
                )}
            </div>
        )
    }

    @bind
    private getAbrData(month) {
        const { year } = this.state
        this.setState({
            loading: true,
        })
        const parsedNumber = month < 10 ? "0" + month : month.toString()
        const date = `${year}-${parsedNumber}`
        const newList: Array<{ personalID: string; name: string; earnings: number }> = []
        getAbrechnungForMonth(date).then((el) => {
            el.forEach((entry) => {
                const existingEntry = newList.filter((item: { personalID; name; earnings }) => {
                    return item.personalID === entry.personalID
                })
                if (existingEntry.length > 0) {
                    newList.forEach((elem) => {
                        if (elem.personalID === entry.personalID) {
                            elem.earnings = parseFloat((elem.earnings + parseFloat(entry.earnings)).toFixed(2))
                        }
                    })
                } else {
                    const userData = userdata.findByPersonalID(entry.personalID)
                    if (userData) {
                        if (entry.earnings !== "0") {
                            newList.push({
                                personalID: entry.personalID,
                                name: userData.realName,
                                earnings: parseFloat(entry.earnings),
                            })
                        }
                    }
                }
            })
            this.setState({
                employees: newList,
                loading: false,
            })
        })
    }

    private dayPanel() {
        return <div className="panel"></div>
    }
}

export default Salaries
