import $ from "jquery"

import { getCookie } from "../utils/cookiemanager"

export interface IOverviewApi {
    overviewID: string
    date: string
    locationID: string
    guestcount: string
    guestlistcount: string
    entrancePrice: string
    earnings: string
    pku: string
    entranceEarnings: string
    eventName: string
    gainPositions: string
    salaries: string
    barrechnungen: string
    garderobeneinnahmen: string
    gastroumsatz: string
    cardpayments: string
    personalverzehr: string
    savedBy: string
}

// export interface ILocationResponse {
//     response: ILocation
// }

export interface ISavePersonalverzehrApi {
    date: string
    locationID: string
    content: Array<{ amount: string; personalID: string }>
    userID: string
}

export const savePersverzehr = (data: ISavePersonalverzehrApi): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=savepersverzehr"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `savePersonalverzehrTest`,
                date: data.date,
                locationID: data.locationID,
                content: data.content,
                userID: data.userID,
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                resolve(result)
            })
            .catch((xy: any) => resolve(xy.responseText))
    })
}

export const savePersverzehrForID = (data: {
    personalID: string
    amount: string
    date: string
    locationID?: string
}): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=savepersverzehrforid"
    const { personalID, amount, date, locationID } = data
    const userID = getCookie("userid")
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `savePersonalverzehrForID`,
                date,
                locationID,
                personalID,
                userID,
                amount,
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                resolve(result)
            })
            .catch((xy: any) => resolve(xy.responseText))
    })
}

export const getPersverzehr = (locationID, date): Promise<any[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getpersverzehr"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getPersonalverzehrFromDay`,
                locationID,
                date,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const getAllPersverzehr = (): Promise<any[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getallpersverzehr"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getPersonalverzehrTest`,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}

export const getPersverzehrFor = (personalID): Promise<any[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getpersverzehrfor"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                personalID,
                action: `getPersonalverzehrForID`,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}
