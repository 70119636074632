import { DateTime } from "luxon"
import { applySnapshot, getParent, hasParent, Instance, types } from "mobx-state-tree"
import { get as oGet } from "object-path"

import { getAbrechnungen, IAbrechnungResponse, IRequestAbrechnung } from "../api/abrechung"
import { removeLeadingZero } from "../utils/numvalidator"
import { sortFn } from "../utils/sort"
import positions, { IPosition } from "./positions"
import userdata, { IUserItem } from "./userdata"

// import { getlocations, ILocationApi } from "../api/locations";
export interface IAbrechnungItem extends Instance<typeof AbrechnungItem> {}
export interface IAbrechnung extends Instance<typeof Abrechnung> {}
export interface IWallet extends Instance<typeof TWallet> {}

export let cachedAbrechnung: any = ""

const diff = (obj1, obj2) => {
    const result = {}
    const res: any = []
    if (Object.is(obj1, obj2)) {
        return undefined
    }
    if (!obj2 || typeof obj2 !== "object") {
        return obj2
    }
    Object.keys(obj1 || {})
        .concat(Object.keys(obj2 || {}))
        .forEach((key) => {
            if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
                res.push(obj2.positionID)
                result[key] = obj2[key]
            }
            if (typeof obj2[key] === "object" && typeof obj1[key] === "object") {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const value = diff(obj1[key], obj2[key])
                if (value !== undefined) {
                    result[key] = value
                }
            }
        })
    return result
}

let listInstance: IAbrechnung

const TWallet = types.model({
    coins: types.number,
    fives: types.number,
    tens: types.number,
    twenties: types.number,
    fifties: types.number,
    hundreds: types.number,
    twohundreds: types.number,
    fivehundreds: types.number,
})

const TOverview = types.model({
    title: types.string,
})

// let checkObj: ISaveAbrechnung[] = []

export const changedAbrechnung: string[] = []

export const AbrechnungItem = types
    .model({
        id: types.maybeNull(types.string),
        earningID: types.optional(types.string, ""),
        date: types.string,
        timestamp: types.string,
        positionID: types.string,
        personalID: types.optional(types.string, ""),
        locationID: types.string,
        earnings: types.optional(types.string, "0"),
        salary: types.optional(types.string, "0"),
        customwage: types.optional(types.string, "0"),
        sum: types.optional(types.string, "0"),
        start: types.optional(types.string, "00:00"),
        end: types.optional(types.string, "00:00"),
        hours: types.optional(types.string, "0"),
        savedBy: types.optional(types.string, ""),
        miscType: types.optional(types.string, ""),
        miscName: types.optional(types.string, ""),
        miscTitle: types.optional(types.string, ""),
        rechnung: types.optional(types.string, "0"),
        ueberweisung: types.optional(types.string, "0"),
        iscvd: types.optional(types.string, "0"),
        paidType: types.string,
        savedAtDate: types.optional(types.string, DateTime.local().toFormat("HH:mm dd.MM.yyyy")),
        paidFromPosition: types.string,
    })
    .volatile((self) => ({
        selected: false,
    }))
    .actions((self) => {
        const actions = {
            setID: (id) => {
                self.id = id
            },
            changePersonalID: (id) => {
                self.personalID = id
            },
            updateMiscTitle: (val) => {
                self.miscTitle = val
            },
            updateMiscName: (val) => {
                self.miscName = val
            },
            pays: (): string[] => {
                const pays: string[] = []
                if (hasParent(self)) {
                    const parentElem = getParent(self) as IAbrechnungItem[]
                    if (parentElem) {
                        parentElem.forEach((elem) => {
                            if (self.positionID === elem.paidType) {
                                if (self.positionID !== elem.positionID) {
                                    if (pays.indexOf(elem.positionID) === -1) {
                                        pays.push(elem.positionID)
                                    }
                                }
                            }
                        })
                    }
                }
                return pays
            },
            fullPaysData: (): IAbrechnungItem[] => {
                const pays: string[] = []
                const paysData: IAbrechnungItem[] = []
                if (hasParent(self)) {
                    const parentElem = getParent(self) as IAbrechnungItem[]
                    if (parentElem) {
                        parentElem.forEach((elem) => {
                            if (self.positionID === elem.paidType) {
                                if (self.positionID !== elem.positionID) {
                                    if (pays.indexOf(elem.positionID) === -1) {
                                        pays.push(elem.positionID)
                                        paysData.push(elem)
                                    }
                                }
                            }
                        })
                    }
                }
                return paysData
            },
            payssum: (): number => {
                let payssum = 0
                if (actions.pays().length > 0) {
                    if (hasParent(self)) {
                        const parentElem = getParent(self) as IAbrechnungItem[]
                        if (parentElem) {
                            parentElem.forEach((elem) => {
                                if (actions.pays().indexOf(elem.positionID) > -1) {
                                    if (self.positionID !== elem.positionID) {
                                        payssum += parseFloat(elem.salary)
                                    }
                                }
                            })
                        }
                    }
                }
                return payssum
            },
            setUberweisung: (val) => {
                self.ueberweisung = val
                if (self.ueberweisung === "0") {
                    actions.setSum(
                        (
                            parseFloat(self.earnings) -
                            (actions.payssum() + parseFloat(self.salary.replace(",", ".")))
                        ).toString(),
                    )
                } else {
                    actions.setSum((parseFloat(self.earnings) - actions.payssum()).toString())
                }
            },
            setPaidFrom: (val) => {
                self.paidFromPosition = val
                self.paidType = val
            },
            setRechnung: (val) => {
                self.rechnung = val
            },
            setCvd: (val) => {
                self.iscvd = val
                actions.calcSalary()
            },
            setCustomWage: (val) => {
                self.customwage = val.replace(",", ".")
                actions.calcSalary()
            },
            setEarnings: (earnings: string, overwrite?: boolean) => {
                const allearnings = removeLeadingZero(earnings).replace(",", ".")
                self.earnings = allearnings
                actions.calcSalary()
            },
            setSalary: (value: string, overwrite?: boolean) => {
                const salary = removeLeadingZero(value)
                if (overwrite) {
                    self.salary = salary.replace(",", ".")
                    actions.calcSum(self.earnings, actions.payssum(), salary)
                } else {
                    if (self.iscvd === "0") {
                        self.salary = salary.replace(",", ".")
                    } else {
                        self.salary = (parseFloat(salary) + 15).toFixed(2).replace(",", ".")
                    }
                }
            },
            setSum: (value: string, overwrite?: boolean) => {
                const pos = positions.find(self.positionID)
                const sum = removeLeadingZero(value)
                if (overwrite) {
                    self.sum = sum
                } else if (pos) {
                    if (pos.haswallet()) {
                        if (parseFloat(sum) > 0) {
                            self.sum = sum
                        }
                    } else {
                        self.sum = "0"
                    }
                } else {
                    self.sum = "0"
                }
            },
            setStart: (start) => {
                self.start = start
                actions.calcHours()
            },
            setEnd: (end) => {
                self.end = end
                actions.calcHours()
            },
            setHours: (hours) => {
                self.hours = hours
                actions.calcSalary()
            },
            calcSum: (earnings, payssum, salary) => {
                if (self.paidFromPosition !== self.positionID) {
                    salary = 0
                }
                let sum = "0"
                if (self.ueberweisung === "0") {
                    sum = (parseFloat(earnings) - (payssum + parseFloat(salary))).toFixed(2)
                } else {
                    sum = (parseFloat(earnings) - payssum).toFixed(2)
                }
                if (parseFloat(sum) < 0) {
                    sum = "0"
                }
                self.sum = sum
                return sum
            },
            calcSalary: () => {
                const posdata = positions.find(self.positionID)
                if (posdata) {
                    const umsatzanteilPerc = posdata.umsatzbet()
                    let lohn =
                        parseFloat(self.hours) *
                        (self.customwage === "0"
                            ? posdata.stundenlohnFull()
                            : parseFloat(self.customwage.replace(",", ".")))
                    const stundenLohnTheke = parseFloat(self.hours) * posdata.stundenlohnTheke()
                    const umsatzanteil = umsatzanteilPerc * parseFloat(self.earnings.replace(",", "."))
                    if (umsatzanteil + stundenLohnTheke > lohn) {
                        if (self.locationID === "1" || self.locationID === "6") {
                            const checkRounding = (umsatzanteil + stundenLohnTheke) % 0.5
                            if (checkRounding === 0) {
                                lohn = umsatzanteil + stundenLohnTheke
                            } else if (checkRounding > 0) {
                                lohn = Math.ceil(umsatzanteil + stundenLohnTheke)
                            } else if (checkRounding < 0) {
                                lohn = Math.floor(umsatzanteil + stundenLohnTheke)
                            }
                        } else {
                            lohn = Math.round(umsatzanteil + stundenLohnTheke)
                        }
                    } else {
                        if (self.locationID === "1" || self.locationID === "6") {
                            const checkRounding = lohn % 0.5
                            if (checkRounding !== 0) {
                                if (checkRounding < 0.5) {
                                    lohn = lohn - checkRounding + 0.5
                                } else if (checkRounding > 0.5) {
                                    lohn = Math.ceil(lohn)
                                }
                            }
                            // if (checkRounding === 0) {
                            //     lohn = lohn * 1
                            // } else if (checkRounding > 0) {
                            //     lohn = Math.ceil(lohn)
                            // } else if (checkRounding < 0) {
                            //     lohn = Math.floor(lohn)
                            // }
                        } else {
                            // const checkRounding = lohn % 0.5
                            // if (checkRounding < 0.5) {
                            //     lohn = lohn - checkRounding + 0.5
                            // } else if (checkRounding > 0.5) {
                            //     lohn = Math.ceil(lohn)
                            // }
                            lohn = Math.ceil(lohn)
                        }
                    }
                    let salary = "0"
                    if (self.iscvd === "0") {
                        salary = lohn.toFixed(2)
                    } else {
                        if (self.locationID !== "1" && self.locationID !== "6") {
                            salary = (lohn + 25).toFixed(2)
                        } else {
                            salary = (lohn + 30).toFixed(2)
                        }
                    }
                    if (
                        self.personalID === "151" ||
                        self.personalID === "52" ||
                        self.personalID === "127" ||
                        self.personalID === "148" ||
                        self.personalID === "808"
                    ) {
                        salary = "0"
                    }
                    self.salary = salary
                    actions.calcSum(self.earnings, actions.payssum(), salary)
                    if (hasParent(self)) {
                        const parentElem = getParent(self)
                        if (hasParent(parentElem)) {
                            const grandParent = getParent(parentElem) as IAbrechnung
                            grandParent.changePays(self.paidFromPosition, self.paidFromPosition)
                        }
                    }
                    return salary
                }
                return "0"
            },
            calcHours: () => {
                const difference = (start, end) => {
                    let hourdifference = (end.getTime() - start.getTime()) / 1000
                    hourdifference /= 60 * 60
                    return Math.abs(hourdifference)
                }
                const newStartDate = new Date("01/01/2007 " + self.start)
                const newEndDate = new Date("01/02/2007 " + self.end)
                const hourDiff = difference(newStartDate, newEndDate)
                const res = hourDiff >= 24 ? hourDiff - 24 : hourDiff
                actions.setHours(res.toFixed(2))
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            get position(): IPosition {
                const position = positions.items.filter((el) => el.positionID === self.positionID)
                return position[0]
            },
            get positionname(): string {
                const position = positions.items.filter((el) => el.positionID === self.positionID)
                if (position.length > 0) {
                    return position[0].positionName
                }
                return ""
            },
            get personalData(): IUserItem | null {
                const user = userdata.items.filter((usr) => usr.personalID === self.personalID)
                return user.length > 0 ? user[0] : null
            },
            get poscolor() {
                const position = positions.items.filter((el) => el.positionID === self.positionID)
                if (position.length > 0) {
                    return position[0].color
                }
                return ""
            },
        }
        return views
    })

export const getSortEl = (item: IAbrechnungItem) => {
    if (item.positionID != null) {
        return item.positionID || 0
    }
    return 0
}

const sortGetVAl = (obj: IAbrechnungItem, options: string): null | number | string => {
    return oGet(obj, options)
}

// const loadItems = (): Promise<IAbrechnungResponse[]> => getAbrechnungen({ date: "", locationID: "" })

export interface IClientFilter {
    query?: string
    id?: string[]
    domain?: string
}

const filterItems = (
    items: IAbrechnungItem[],
    query: string | null,
    sortCol: string,
    sortAsc: boolean,
): IAbrechnungItem[] => {
    if (query == null || !query.length) {
        return items.sort(sortFn(sortGetVAl, sortAsc, sortCol))
    }
    return items
        .filter((el) => {
            // const q = query.toLowerCase()
            return false
        })
        .sort(sortFn(sortGetVAl, sortAsc, sortCol))
}

export const Abrechnung = types
    .model({
        items: types.optional(types.array(AbrechnungItem), []),
        subjectsToChange: types.optional(types.array(types.string), []),
        overview: types.maybeNull(TOverview),
    })
    .volatile((self) => ({
        searchstring: "",
        filterObj: {},
        sortAsc: true,
        sortCol: "created",
        loaded: false,
    }))
    .actions((self) => {
        const actions = {
            checkForChange: () => {
                const actual = self.items.map((item) => {
                    return {
                        id: item.id,
                        earningID: item.earningID,
                        date: item.date,
                        timestamp: item.timestamp,
                        positionID: item.positionID,
                        personalID: item.personalID,
                        locationID: item.locationID,
                        earnings: item.earnings,
                        salary: item.salary,
                        sum: item.sum,
                        start: item.start,
                        end: item.end,
                        hours: item.hours,
                        savedBy: item.savedBy,
                        miscType: item.miscType,
                        miscName: item.miscName,
                        miscTitle: item.miscTitle,
                        rechnung: item.rechnung,
                        ueberweisung: item.ueberweisung,
                        iscvd: item.iscvd,
                        paidType: item.paidType,
                        savedAtDate: item.savedAtDate,
                        paidFromPosition: item.paidFromPosition,
                    }
                })
                const cached = cachedAbrechnung.items
                actual.forEach((el) => {
                    // const different: string[] = []
                    cached.forEach((elem) => {
                        if (elem.positionID === el.positionID) {
                            // filler
                        }
                    })
                })
            },
            addSubjectToChange: (positionid: string) => {
                if (self.subjectsToChange.indexOf(positionid) === -1) {
                    self.subjectsToChange.push(positionid)
                }
            },
            clearSubjects: () => {
                self.subjectsToChange.clear()
            },
            get: (positionID: string) => {
                const res = self.items.filter((el) => el.positionID === positionID)
                if (res.length > 0) {
                    return res[0]
                }
                return null
            },
            setSearchString: (crit: string) => {
                return (self.searchstring = crit)
            },
            setSortCol: (col: string) => {
                return (self.sortCol = col)
            },
            setSortDir: (asc: boolean) => {
                return (self.sortAsc = asc)
            },
            setFilterObj: (fObj: IClientFilter) => {
                return (self.filterObj = fObj)
            },
            addItems: (item: IAbrechnungItem) => {
                if (!actions.isContained(item.positionID)) {
                    self.items.push(item)
                }
            },
            changePays: (id, addto: string | null) => {
                let before = ""
                self.items.forEach((el) => {
                    if (el.pays().indexOf(id) !== -1) {
                        before = el.positionID
                        //  changedAbrechnung.push(before)
                        actions.addSubjectToChange(before)
                    }
                })
                self.items.forEach((el) => {
                    if (id === el.positionID) {
                        el.setPaidFrom(addto)
                        //  changedAbrechnung.push(el.positionID)
                        actions.addSubjectToChange(el.positionID)
                    }
                })
                self.items.forEach((el) => {
                    if (el.positionID === before) {
                        const newsum = el.calcSum(el.earnings, el.payssum(), el.salary)
                        el.setSum(newsum)
                    }
                })
                self.items.forEach((el) => {
                    if (addto) {
                        if (addto === el.positionID) {
                            const newsum = el.calcSum(el.earnings, el.payssum(), el.salary)
                            el.setSum(newsum)
                            //  changedAbrechnung.push(el.positionID)
                            actions.addSubjectToChange(el.positionID)
                        }
                    }
                })
            },
            gatherPaysEntries: () => {
                self.items.forEach((el) => {
                    if (el.positionID !== el.paidFromPosition) {
                        const payerItem = actions.get(el.paidFromPosition)
                        if (payerItem) {
                            //  payerItem.addPays(el.positionID)
                        }
                    }
                })
            },
            clearList: () => {
                self.items.clear()
            },
            isContained: (id: string) => {
                let contains = 0
                self.items.toJSON().forEach((el) => {
                    if (el.positionID === id) {
                        contains++
                    }
                })
                return contains !== 0
            },
            setLoaded: () => {
                self.loaded = true
            },
            load: (data: IRequestAbrechnung) => {
                // if (guistate.client_id == null || loaded) {
                //     return
                // }
                try {
                    actions.clearList()
                    getAbrechnungen({ date: data.date, locationID: data.locationID }).then(
                        (res: IAbrechnungResponse[]) => {
                            // res.map((item) => actions.addItems(item))
                            applySnapshot(self, { items: res })
                            cachedAbrechnung = { items: res }
                            actions.setLoaded()
                        },
                    )
                } catch (err) {
                    console.error(err)
                }
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            count: () => {
                return self.items.length
            },
            find: (id: string) => {
                return self.items.find((el) => el.locationID === id)
            },
            first: () => {
                return self.items[0]
            },
            filteroptions: () => {
                return []
            },
            filter: (query: string | null, sortCol: string) => {
                return filterItems(self.items, query, self.sortCol, self.sortAsc)
            },
            filtered: () => {
                return views.filter(self.searchstring, self.sortCol)
            },
            selected: () => {
                return views.filtered().filter((el) => el)
            },
        }
        return views
    })

// export const Abrechnungen = types.model({
//     abrechnungID: types.maybeNull(types.string),
//     abrechnungitems: types.maybeNull(Abrechnung)
// }).actions(self => {
//     const actions = {}
//     return actions;
// })

listInstance = Abrechnung.create({})
export default listInstance
// export default Locations
