import { observer } from "mobx-react"
import * as React from "react"
import guistate from "./stores/guistate"
import App from "./App"
import Login from "./apps/login"

interface ILoginState {
    authenticated: boolean
}

@observer
class AuthCheck extends React.Component<{}, ILoginState> {

    constructor(props: any) {
        super(props)
        this.state = {
            authenticated: false,
        }
        this.render = this.render.bind(this)
        this.setAuthState = this.setAuthState.bind(this)
    }

    public render() {
        if (!this.state.authenticated) {
            return (
                <Login setauth={this.setAuthState}></Login>
            )
        }
        return (<App setauth={this.setAuthState} />)
    }

    public componentDidMount() {
        guistate.checkAuth().then((res) => {
            this.setState({
                authenticated: res,
            })
        })
    }

    private setAuthState(auth: boolean) {
        this.setState({
            authenticated: auth,
        })
    }

}

export default AuthCheck
