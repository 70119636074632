import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect, Route, RouteComponentProps } from "react-router"
import genGetUrl, { TGetUrl } from "../../utils/geturl"
import userdata from "../../stores/userdata"
import { updateApphead } from "../../utils/dommanip"
import Loading from "../../components/loading"
import "./employees.scss"
import "rc-tooltip/assets/bootstrap.css"
import EmployeesList from "./employees_list"
import EmployeesNew from "./employees_new"
import EmployeesView from "./employees_view"
import EmployeesEdit from "./employees_edit"
import bind from "bind-decorator"
import { DateTime } from "luxon"
import { getHoursForYear, IPersonalHoursResponse } from "api/getpersonaldata"

const basepath = "/employees"

export interface IAbrechnungItemPos {
    name: string | JSX.Element
    start: string
    end: string
    uberweisung: string
    rechnung: string
    hours: number
    earnings: string | number
    salary: string | number | JSX.Element
    sum: string | number | JSX.Element
    paidFrom: string
    pays: string[]
    positionname: string
}

export const { getUrl, jumpTo } = genGetUrl((opt: TGetUrl): string => {
    switch (opt.type) {
        case "new":
            return `${basepath}/new`
        case "view":
            return `${basepath}/view/${opt.id}`
        case "edit":
            return `${basepath}/edit/${opt.id}`
        case "list":
            return `${basepath}/list/`
        default:
            return basepath
    }
})

interface IEmployeeProps
    extends RouteComponentProps<{
        id: string
    }> { }

interface IEmployeeState {
    hoursresult?: IPersonalHoursResponse[]
}

@observer
class Employees extends Component<IEmployeeProps, IEmployeeState> {

    constructor(props: any) {
        super(props)

        this.state = {
            hoursresult: undefined
        }
    }

    public render() {
        // if (getCookie("token").length === 0) {
        //     return <Redirect to="/"></Redirect>
        // }
        if (!userdata.loaded) {
            return <Loading></Loading>
        }
        return (
            <React.Fragment>
                <Route path={getUrl({ type: "" })} exact render={this.list} />
                <Route path={getUrl({ type: "list" })} exact render={this.list} />
                <Route path={getUrl({ type: "new" })} exact render={this.newItem} />
                <Route path={getUrl({ type: "edit", id: ":id" })} exact render={this.editItem} />
                <Route path={getUrl({ type: "view", id: ":id" })} exact render={this.viewItem} />
            </React.Fragment>
        )
    }

    public componentDidMount() {
        updateApphead("Mitarbeiter", basepath)
        userdata.load()
    }

    @bind
    private list(): JSX.Element {
        if (userdata.loaded) {
            return <EmployeesList onSelect={() => this.setState({
                hoursresult: undefined
            })} />
        }
        return <Loading></Loading>
    }

    @bind
    private newItem(): JSX.Element {
        return (
            <React.Fragment>
                <div className="leftsection">{this.list()}</div>
                <div className="rightsection">{<EmployeesNew></EmployeesNew>}</div>
            </React.Fragment>
        )
    }

    @bind
    private editItem({ match }: IEmployeeProps): JSX.Element {
        const { id } = match.params
        const user = userdata.find(id)
        if (user) {
            return (
                <React.Fragment>
                    <div className="leftsection">{this.list()}</div>
                    <div className="rightsection">{<EmployeesEdit user={user}></EmployeesEdit>}</div>
                </React.Fragment>
            )
        }
        return <Redirect to={getUrl({ type: "list" })}></Redirect>
    }

    @bind
    private viewItem({ match }: IEmployeeProps): JSX.Element {
        const { id } = match.params
        const { hoursresult } = this.state
        const user = userdata.find(id)
        if (user) {

            const today = DateTime.now().toJSDate()
            let month = (today.getMonth() + 1).toString()
            if (month.length === 1) {
                month = "0" + month
            }


            if (hoursresult === undefined) {
                getHoursForYear(month, today.getFullYear().toString(), user.personalID).then((res) => {
                    this.setState({
                        hoursresult: res
                    })
                })
            }

            if (hoursresult != null) {
                return (
                    <React.Fragment>
                        <div className="leftsection">{this.list()}</div>
                        <div className="rightsection">{<EmployeesView userhours={hoursresult} user={user}></EmployeesView>}</div>
                    </React.Fragment>
                )
            }

            return (
                <React.Fragment>
                    <div className="leftsection">{this.list()}</div>
                    <div className="rightsection">{<Loading />}</div>
                </React.Fragment>
            )
        }
        return <Redirect to={getUrl({ type: "list" })}></Redirect>
    }
}

export default Employees
