import React, { Component } from "react"
import { observer } from "mobx-react"
import { Redirect } from "react-router"
import { getCookie } from "../../utils/cookiemanager"
import "./employees.scss"

import "react-toastify/dist/ReactToastify.css"
import DetailView from "../../components/detailview"
import { jumpTo } from "."
import { toast } from "react-toastify"
import userdata, { IUserItem, ROLES } from "../../stores/userdata"
import { deleteUser, updateUserData } from "../../api/getpersonaldata"
import bind from "bind-decorator"
import Card from "../../components/card"
import { isNum } from "../../utils/numvalidator"
import { LOCATIONS } from "stores/locations"
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons"
// import Uploader from "components/upload"
// import Filelist from "components/filelist"

interface IEmployeesEditProps {
    user: IUserItem
}

interface IEmployeesEditState {
    deleteQuestion: boolean
    stbIDinput: string
    email: string
    name: string
    role: string
    locations: string[]
}

@observer
class EmployeesEdit extends Component<IEmployeesEditProps, IEmployeesEditState> {
    constructor(props: any) {
        super(props)
        this.state = {
            deleteQuestion: false,
            stbIDinput: this.props.user.stbID,
            email: this.props.user.readableMail,
            name: this.props.user.realName,
            role: this.props.user.role,
            locations: this.props.user.preferredLocations()
        }
    }

    public render() {
        const { user } = this.props

        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        return (
            <>
                {this.state.deleteQuestion && (
                    <DetailView
                        header={() => {
                            return <h3>{user.realName} löschen?</h3>
                        }}
                        content={() => this.renderDeleteContent(user)}
                        onCancel={() => {
                            this.setState({
                                deleteQuestion: false,
                            })
                        }}
                    />
                )}
                {!this.state.deleteQuestion && (
                    <DetailView
                        header={() => this.renderHeader(user)}
                        content={() => this.renderInfo()}
                        onClose={() => jumpTo({ type: "view", id: user.userID })}
                        onSave={this.onSave}
                        onDelete={() => {
                            this.setState({
                                deleteQuestion: true,
                            })
                        }}
                    />
                )}
            </>
        )
    }

    private renderHeader(user: IUserItem) {
        return <h3>{user.realName}</h3>
    }

    private renderDeleteContent(user) {
        return (
            <div className="deletequestion">
                <p>
                    Soll dieser Eintrag wirklich gelöscht werden?<br></br>Diese Aktion kann nicht rückgängig gemacht
                    werden
                </p>
                <br></br>
                <div
                    className="formbutton delete"
                    onClick={() => {
                        const id = user.personalID
                        deleteUser(id).then((res) => {
                            if (res === "success") {
                                toast.success("Erfolgreich gelöscht", { autoClose: 2500 })
                                userdata.remove(user)
                                jumpTo({ type: "list" })
                            } else {
                                toast.error("Löschen fehlgeschlagen", { autoClose: 2500 })
                            }
                        })
                    }}
                >
                    Wirklich löschen?
                </div>
            </div>
        )
    }

    @bind
    private renderInfo() {
        const { user } = this.props
        const { stbIDinput, email, name, locations } = this.state
        return (
            <>
                <Card
                    type="input"
                    dark={true}
                    content={
                        <input
                            type="text"
                            value={name}
                            onChange={(ev) => {
                                ev.persist()
                                const val = ev.target.value
                                this.setState({
                                    name: val,
                                })
                            }}
                        ></input>
                    }
                    label="Name"
                />
                <br></br>
                <Card dark={true} content={user.username} label="Nutzername"></Card>
                <br></br>
                <Card
                    type="input"
                    dark={true}
                    content={
                        <input
                            type="mail"
                            value={email}
                            onChange={(ev) => {
                                ev.persist()
                                const val = ev.target.value
                                this.setState({
                                    email: val,
                                })
                            }}
                        ></input>
                    }
                    label="E-Mail"
                />
                <br></br>
                <span className="flex flex-col">
                    <select onChange={(ev) => this.setState({
                        role: ev.target.value
                    })} value={this.state.role}>
                        {Object.keys(ROLES).map((key) => {
                            return <option key={key} value={key} disabled={key === "1"}>{ROLES[key]}</option>
                        })}
                    </select>
                    <span className="text-sm subtitle">Rolle</span>
                </span>
                <br></br>
                <Card dark={true} content={user.readableonline} label="Zuletzt online"></Card>
                <br></br>
                <Card
                    type="input"
                    dark={true}
                    content={
                        <input
                            type="number"
                            value={stbIDinput}
                            onChange={(ev) => {
                                ev.persist()
                                const val = ev.target.value
                                if (isNum(val)) {
                                    this.setState({
                                        stbIDinput: val,
                                    })
                                }
                            }}
                        ></input>
                    }
                    label="ID Steuerbüro"
                />
                <br />
                <div className="flex flex-col">
                    {["2", "3", "4", "6"].map((el) => {
                        let selected = locations.includes(el)
                        return <div className="flex cursor-pointer" onClick={() => {
                            let newlist = locations
                            if (selected) {
                                newlist = locations.filter((locationID) => locationID !== el)
                            } else {
                                newlist.push(el)
                            }
                            this.setState({
                                ...this.state,
                                locations: newlist
                            })
                        }}>
                            {selected && <CheckBox />}
                            {!selected && <CheckBoxOutlineBlank />}
                            <span>{LOCATIONS[el]}</span>
                        </div>
                    })}
                </div>

                {/* <Filelist edit={true} userID={user.userID} path="contracts/" />
                <Uploader id={"contract-employee"} fileprefix={`contracts/${user.userID}_${user.stbID}_contract___`} className="mt-3" /> */}
            </>
        )
    }

    @bind
    private onSave() {
        const { user } = this.props
        const { stbIDinput, name, email, role, locations } = this.state
        const { userID, username } = user
        updateUserData({ username, userID, realName: name, mail: email, stbID: stbIDinput, role, locations: "[" + locations.join(",") + "]" }).then((res) => {
            if (res === "successful") {
                toast.success("Erfolgreich gespeichert", { autoClose: 2500 })
                user.updateStbID(stbIDinput)
                user.updateName(name)
                user.updateMail(email)

                jumpTo({ type: "view", id: user.userID })
            } else {
                toast.warn("Speichern fehlgeschlagen", { autoClose: 2500 })
            }
        })
    }
}

export default EmployeesEdit
