import { DateTime } from "luxon"
import { applySnapshot, Instance, types } from "mobx-state-tree"
import { get as oGet } from "object-path"
import uuid from "uuid"

import { getoverviews, getsingleoverview, IOverviewApi, saveoverview } from "../api/overviews"
import { renderTableContentWCurrency } from "../apps/overview/overviewlist"
import { ITableObject } from "../components/table"
import { toHumanDate } from "../utils/dateformatter"
import { sortFn } from "../utils/sort"
import abrechnung from "./abrechnung"
import locations from "./locations"
import positions from "./positions"

export interface IOverview extends Instance<typeof Overview> {}
export interface IOverviews extends Instance<typeof Overviews> {}

let listInstance: IOverviews

export const Overview = types
    .model({
        id: types.maybeNull(types.string),
        overviewID: types.optional(types.string, uuid()),
        date: types.string,
        locationID: types.string,
        guestcount: types.optional(types.string, "0"),
        guestlistcount: types.optional(types.string, "0"),
        entrancePrice: types.optional(types.string, "7"),
        earnings: types.optional(types.string, "0"),
        pku: types.optional(types.string, "0"),
        entranceEarnings: types.optional(types.string, "0"),
        eventName: types.optional(types.string, ""),
        gainPositions: types.optional(types.string, "0"),
        salaries: types.optional(types.string, "0"),
        barrechnungen: types.optional(types.string, "0"),
        garderobeneinnahmen: types.optional(types.string, "0"),
        gastroumsatz: types.optional(types.string, "0"),
        cardpayments: types.optional(types.string, "0"),
        personalverzehr: types.optional(types.string, "0"),
        savedBy: types.string,
    })
    .actions((self) => {
        const actions = {
            updateEC: (val) => {
                self.cardpayments = val
                actions.updateGuestCount()
            },
            updateGL: (val) => {
                self.guestlistcount = val
                actions.updateGuestCount()
            },
            updateEntrancePrice: (val) => {
                self.entrancePrice = val
                actions.updateGuestCount()
            },
            updateEventName: (val) => {
                self.eventName = val
            },
            updateEarnings: () => {
                let earnings = 0
                let gesamt = 0
                let wardrobeearnings = 0
                let entranceearnings = 0
                let gainPositions = 0
                abrechnung.items.forEach((el) => {
                    const pos = positions.find(el.positionID)
                    if (pos) {
                        switch (pos.positionType) {
                            case "4":
                            case "13":
                                wardrobeearnings += parseFloat(el.earnings)
                                // gesamt += parseFloat(el.earnings)
                                if (parseFloat(el.earnings) > 0) {
                                    gainPositions++
                                }
                                break
                            case "3":
                            case "12":
                                entranceearnings += parseFloat(el.earnings)
                                // gesamt += parseFloat(el.earnings)
                                if (parseFloat(el.earnings) > 0) {
                                    gainPositions++
                                }
                                break
                            case "16":
                                // gesamt += parseFloat(el.earnings)
                                // earnings += parseFloat(el.earnings)
                                actions.updatePersVerzehr(el.earnings)
                                break
                            default:
                                earnings += parseFloat(el.earnings)
                                gesamt += parseFloat(el.earnings)
                                if (parseFloat(el.earnings) > 0) {
                                    gainPositions++
                                }
                                break
                        }
                    }
                })
                // gesamt += parseFloat(self.cardpayments)
                // earnings += parseFloat(self.cardpayments)
                self.earnings = gesamt.toFixed(2)
                self.gastroumsatz = earnings.toFixed(2)
                self.entranceEarnings = entranceearnings.toFixed(2)
                self.garderobeneinnahmen = wardrobeearnings.toFixed(2)
                self.gainPositions = gainPositions.toString()
                actions.updateGuestCount()
            },
            updateGuestCount: () => {
                const entranceEarnings = parseFloat(self.entranceEarnings)
                const entrancePrice = parseInt(self.entrancePrice, 10)
                const glCount = parseInt(self.guestlistcount, 10)
                const guestcount = Math.floor(entranceEarnings / entrancePrice) + glCount
                self.guestcount = guestcount.toString()
                actions.updatePKU()
            },
            updatePKU: () => {
                // (gesamtumsatz-(eintrittseinnahmen+garderobeneinnahmen)) / (guestcount + guestlistcount) ;
                const pku = parseFloat(self.gastroumsatz) / parseInt(self.guestcount, 10)
                self.pku = pku.toFixed(2)
            },
            updatePersVerzehr: (val: string) => {
                self.personalverzehr = parseFloat(val).toFixed(2)
            },
            updateSalaries: () => {
                // barlöhne
                let salaries = 0
                let barrechnungen = 0
                abrechnung.items.forEach((el) => {
                    if (el.ueberweisung === "0") {
                        if (el.rechnung === "0") {
                            salaries += parseFloat(el.salary)
                        } else {
                            barrechnungen += parseFloat(el.salary)
                        }
                    }
                })
                self.salaries = salaries.toFixed(2)
                self.barrechnungen = barrechnungen.toFixed(2)
            },
            save: (onSuccess: () => void) => {
                saveoverview({ overviewdata: self }).then((res) => {
                    if (res === "success") {
                        // tslint:disable-next-line: no-unused-expression
                        onSuccess()
                    } else {
                        alert("Fehlgeschlagen")
                    }
                })
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            detailView: () => {
                const data = [
                    {
                        title: "Eintritt des Abends",
                        value: self.entrancePrice,
                        unit: "€",
                    },
                    {
                        title: "Gäste (inkl. Gästeliste)",
                        value: parseInt(self.guestcount),
                    },
                    {
                        title: "Umsatz Gastro",
                        value: self.gastroumsatz,
                        unit: "€",
                    },
                    {
                        title: "Umsatz Eintritt",
                        value: self.entranceEarnings,
                        unit: "€",
                    },
                    {
                        title: "Umsatz Garderobe",
                        value: self.garderobeneinnahmen,
                        unit: "€",
                    },
                    {
                        title: "Umsatz Kartenzahlungen",
                        value: self.cardpayments,
                        unit: "€",
                    },
                    {
                        title: "Umsatz Personalverzehr",
                        value: self.personalverzehr,
                        unit: "€",
                    },
                    {
                        title: "Umsatz Gesamt",
                        subtitle: "(Gastro, Eintritt, EC, Garderobe)",
                        value: (
                            parseFloat(self.earnings) +
                            parseFloat(self.garderobeneinnahmen) +
                            parseFloat(self.entranceEarnings) +
                            parseFloat(self.cardpayments)
                        ).toFixed(2),
                        unit: "€",
                    },
                    {
                        title: "PKU (nur Gastro)",
                        value: self.pku,
                        unit: "€",
                    },
                    {
                        title: "Gästeliste",
                        value: self.guestlistcount,
                    },
                    {
                        title: "Löhne",
                        value: self.salaries,
                        unit: "€",
                    },
                    {
                        title: "Rechnungen (bar)",
                        value: self.barrechnungen,
                        unit: "€",
                    },
                ]
                return data
            },
        }
        return views
    })

export const getSortEl = (item: IOverview) => {
    if (item.date != null) {
        return item.date || 0
    }
    return 0
}

const sortGetVAl = (obj: IOverview, options: string): null | number | string => {
    return oGet(obj, options)
}

// const loadItems = (): Promise<IOverviewApi[]> => getoverviews()

export interface IOverviewFilter {
    query?: string
    locationIDs?: string[]
}

const filterItems = (
    items: IOverview[],
    filter: IOverviewFilter = {},
    sortCol: string,
    sortAsc: boolean,
): IOverview[] => {
    if (Object.keys(filter).length <= 0) {
        return items.slice().sort(sortFn(sortGetVAl, sortAsc, sortCol))
    }
    return items
        .filter((item) => {
            if (filter.locationIDs) {
                if (!(filter.locationIDs.indexOf(item.locationID) > -1)) {
                    return false
                }
            }
            return true
        })
        .sort(sortFn(sortGetVAl, sortAsc, sortCol))
}

export const Overviews = types
    .model({
        items: types.array(Overview),
    })
    .volatile((self) => ({
        searchstring: "",
        filterObj: {},
        sortAsc: true,
        sortCol: "date",
        loaded: false,
    }))
    .actions((self) => {
        const actions = {
            setSearchString: (crit: string) => {
                return (self.searchstring = crit)
            },
            setSortCol: (col: string) => {
                return (self.sortCol = col)
            },
            setSortDir: (asc: boolean) => {
                return (self.sortAsc = asc)
            },
            setFilterObj: (fObj: IOverviewFilter) => {
                return (self.filterObj = fObj)
            },
            addItems: (item: IOverview) => {
                // if (!actions.isContained(item.overviewID)) {

                // }
                self.items.push(item)
            },
            clearList: () => {
                self.items.clear()
            },
            //   isContained: (id: string) => {
            //     let contains = 0;
            //     self.items.toJSON().map(el => {
            //       if (el.positionIDs === id) {
            //         contains++;
            //       }
            //     });
            //     return contains !== 0;
            //   },
            setLoaded: () => {
                self.loaded = true
            },
            load: (loadopts: { from?: number; to?: number }) => {
                // if (guistate.client_id == null || loaded) {
                //     return
                // }
                let from = loadopts.from
                let to = loadopts.to
                if (loadopts.from == null) {
                    from = Math.round(DateTime.local().plus({ week: 2 }).toMillis() / 1000)
                }
                if (loadopts.to == null) {
                    to = Math.round(DateTime.local().minus({ week: 2 }).toMillis() / 1000)
                }
                try {
                    actions.clearList()
                    getoverviews(from, to).then((res: IOverviewApi[]) => {
                        // res.map((item) => actions.addItems(item))
                        if (res) {
                            applySnapshot(self, { items: res })
                        }
                        actions.setLoaded()
                    })
                } catch (err) {
                    console.error(err)
                }
            },
            loadSingle: (date, location) => {
                try {
                    actions.clearList()
                    getsingleoverview(date, location).then((res: IOverviewApi[]) => {
                        // res.map((item) => actions.addItems(item))
                        if (res) {
                            applySnapshot(self, { items: res })
                            actions.setLoaded()
                            return res.length > 0 ? res[0] : null
                        }
                    })
                } catch (err) {
                    console.error(err)
                }
                return null
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            count: () => {
                return self.items.length
            },
            find: (id: string) => {
                const res = self.items.find((el) => el.overviewID === id)
                if (res) {
                    return res
                }
                return null
            },
            findByLocAndDate: (loc: string, date: string) => {
                const res = self.items.find((el) => el.locationID === loc && el.date === date)
                if (res) {
                    return res
                }
                return null
            },
            first: () => {
                return self.items[0]
            },
            filteroptions: () => {
                return []
            },
            filter: (query: string | null | any) => {
                if (query === null) {
                    return self.items
                }
                // return self.items
                if (typeof query === "string") {
                    return self.items
                }
                return filterItems(self.items, query, self.sortCol, self.sortAsc)
            },
            filtered: () => {
                if (self.filterObj !== {}) {
                    return views.filter(self.filterObj)
                }
                return views.filter(self.searchstring)
            },
            selected: () => {
                return views.filtered().filter((el) => el)
            },
            tableView: () => {
                // const weekday = (obj: IOverview) => {
                //     return weekDay(obj.date, true)
                // }

                // const parsedDate = (obj: IOverview) => {
                //     return toHumanDate(obj.date) + " " + weekday(obj)
                // }

                const dateOnly = (obj: IOverview) => {
                    return toHumanDate(obj.date)
                }

                const parsedClub = (obj: IOverview) => {
                    const location = locations.find(obj.locationID)
                    if (location) {
                        return location.locationName
                    }
                    return ""
                }

                const data: ITableObject = {
                    columns: [
                        {
                            label: "Datum",
                            small: true,
                            viewFn: dateOnly,
                            valkey: "date",
                            sortable: true,
                        },
                        {
                            label: "Titel",
                            small: true,
                            valkey: "eventName",
                            sortable: false,
                        },
                        {
                            label: "Club",
                            small: true,
                            viewFn: parsedClub,
                            valkey: "location",
                            sortable: false,
                        },
                        {
                            label: "Eintritt",
                            small: true,
                            valkey: "entrancePrice",
                            viewFn: (obj) => renderTableContentWCurrency(obj, "entrancePrice"),
                            sortable: false,
                        },
                        {
                            label: "Gäste",
                            small: true,
                            valkey: "guestcount",
                            sortable: false,
                        },
                        {
                            label: "Gästeliste",
                            small: true,
                            valkey: "guestlistcount",
                            sortable: false,
                        },
                        {
                            label: "Umsatz",
                            small: true,
                            valkey: "earnings",
                            viewFn: (obj) => renderTableContentWCurrency(obj, "earnings"),
                            sortable: false,
                        },
                        {
                            label: "Gastro",
                            small: true,
                            valkey: "gastroumsatz",
                            viewFn: (obj) => renderTableContentWCurrency(obj, "gastroumsatz"),
                            sortable: false,
                        },
                        {
                            label: "PKU",
                            small: true,
                            valkey: "pku",
                            viewFn: (obj) => renderTableContentWCurrency(obj, "pku"),
                            sortable: false,
                        },
                        {
                            label: "Einn. Eintritt",
                            small: true,
                            valkey: "entranceEarnings",
                            viewFn: (obj) => renderTableContentWCurrency(obj, "entranceEarnings"),
                            sortable: false,
                        },
                        {
                            label: "Garderobe",
                            small: true,
                            valkey: "garderobeneinnahmen",
                            viewFn: (obj) => renderTableContentWCurrency(obj, "garderobeneinnahmen"),
                            sortable: false,
                        },
                        {
                            label: "Löhne",
                            small: true,
                            valkey: "salaries",
                            viewFn: (obj) => renderTableContentWCurrency(obj, "salaries"),
                            sortable: false,
                        },
                        {
                            label: "Barrechnungen",
                            small: true,
                            valkey: "barrechnungen",
                            viewFn: (obj) => renderTableContentWCurrency(obj, "barrechnungen"),
                            sortable: false,
                        },
                        {
                            label: "EC",
                            small: true,
                            valkey: "cardpayments",
                            viewFn: (obj) => renderTableContentWCurrency(obj, "cardpayments"),
                            sortable: false,
                        },
                        {
                            label: "Personalverzehr",
                            small: true,
                            valkey: "personalverzehr",
                            viewFn: (obj) => renderTableContentWCurrency(obj, "personalverzehr"),
                            sortable: false,
                        },
                        {
                            label: "Umsatzgenerierend",
                            small: true,
                            valkey: "gainPositions",
                            sortable: false,
                        },
                    ],
                    data: views.filtered(),
                }
                return data
            },
        }
        return views
    })

listInstance = Overviews.create({})
export default listInstance
// export default Overviews
