import React, { Component } from "react"
import { observer } from "mobx-react"
import { historyHandler } from "../../stores/router"
import guistate from "../../stores/guistate"
import {
    Edit,
    Storage,
    Assessment,
    LocalBar,
    SupervisedUserCircle,
    Money,
    AccountBalanceWallet,
} from "@material-ui/icons"
import "./sidemenu.scss"
import menus from "../../stores/menus"
import { getCookie } from "../../utils/cookiemanager"

@observer
class SideMenu extends Component<{}, {}> {
    // constructor(props: any) {
    //     super(props)
    // }

    public render() {
        return (
            <React.Fragment>
                <div className={"sidemenu"}>
                    {menus.items.map((el, idx) => {
                        return (
                            <div
                                key={idx}
                                className={this.renderClassName(el)}
                                onClick={() => {
                                    historyHandler(el.path)
                                }}
                            >
                                {this.returnIcon(el.icon)}
                                <div className="sidemenu-title" key={idx}>
                                    {el.title}
                                </div>
                            </div>
                        )
                    })}
                    {/* <Download></Download> */}
                </div>
            </React.Fragment>
        )
    }

    public componentDidMount() {
        if (!menus.loaded) {
            const token = getCookie("token")
            const tlen = token.length
            const role = token[tlen - 1]
            menus.load(parseInt(role, 10))
        }
    }

    private renderClassName(el): string {
        const classname = "side-button" + (el.path === guistate.activemenu ? " active" : " inactive")
        return classname
    }

    private returnIcon(icon: string): JSX.Element {
        switch (icon) {
            case "calc":
                return <Edit></Edit>
            case "overview":
                return <Assessment></Assessment>
            case "personalverzehr":
                return <LocalBar></LocalBar>
            case "users":
                return <SupervisedUserCircle></SupervisedUserCircle>
            case "wallet":
                return <AccountBalanceWallet></AccountBalanceWallet>
            case "money":
                return <Money></Money>
            default:
                return <Storage></Storage>
        }
    }
}

export default SideMenu
