import Autosuggest from "react-autosuggest"
import React, { Component } from "react"
import "./autosuggest.scss"

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = (suggestion) => suggestion.name

let usernames: Array<{ name: string; id: string; personalid: string }> = []

interface IAutoSuggestState {
    value: string
    id: string
    personalid: string
    suggestions: any[]
}

interface IAutoSuggestProps {
    list: Array<{ name: string; id: string; personalid: string }>
    default: { name: string; id: string; personalid: string }
    onSelectName: (name: string, id: string, personalid: string) => void
}

export class AutoSuggest extends Component<IAutoSuggestProps, IAutoSuggestState> {
    constructor(props) {
        super(props)

        // Autosuggest is a controlled component.
        // This means that you need to provide an input value
        // and an onChange handler that updates this value (see below).
        // Suggestions also need to be provided to the Autosuggest,
        // and they are initially empty because the Autosuggest is closed.
        this.state = {
            value: this.props.default.name,
            id: this.props.default.id,
            personalid: this.props.default.personalid,
            suggestions: [],
        }

        this.getSuggestions = this.getSuggestions.bind(this)
        this.renderSuggestion = this.renderSuggestion.bind(this)
        this.onKeyDown = this.onKeyDown.bind(this)
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this)
    }

    public render() {
        const { value, id, personalid, suggestions } = this.state

        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: "Name",
            value,
            id,
            personalid,
            onChange: this.onChange,
            onKeyDown: this.onKeyDown
        }

        usernames = this.props.list.map((item) => {
            return { name: item.name, id: item.id, personalid: item.personalid }
        })

        // Finally, render it!
        return (
            <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={(data) => getSuggestionValue(data)}
                onSuggestionSelected={this.onSuggestionSelected}
                renderSuggestion={this.renderSuggestion}
                onSuggestionHighlighted={this.onSuggestionHighlighted}
                focusInputOnSuggestionClick={true}
                inputProps={inputProps}
            />
        )
    }

    private renderSuggestion(suggestion) {
        const { onSelectName } = this.props
        return (
            <div
                id={suggestion.id}
                onClick={() => {
                    onSelectName(suggestion.name, suggestion.id, suggestion.personalid)
                }}
            >
                {suggestion.name}
            </div>
        )
    }

    private getSuggestions = (value) => {
        if (value) {
            const inputValue = value.trim().toLowerCase()
            const inputLength = inputValue.length

            return inputLength === 0
                ? []
                : usernames.filter(
                    (lang) => lang.name.toLowerCase().includes(inputValue),
                    // lang => inputValue.includes(lang.name.toLowerCase().slice(0, inputLength))
                )
        }
        return []
    }

    private onSuggestionSelected = (data) => {
        const { value } = this.state

        this.setState({
            value,
            id: data.target.id,
            personalid: data.target.getAttribute("personalid")
        })
    }

    private onSuggestionHighlighted = ({ suggestion }) => {
        const { onSelectName } = this.props
        if (suggestion != null) {
            this.setState({
                value: suggestion.name,
                id: suggestion.id,
                personalid: suggestion.personalid,
            })
            onSelectName(suggestion.name, suggestion.id, suggestion.personalid)
        }
    }

    private onChange = (event, data) => {
        const { suggestions } = this.state

        if (suggestions.length === 1) {
            this.setState({
                value: data.newValue,
                id: suggestions[0].id,
                personalid: suggestions[0].personalid,
            })
        } else {
            this.setState({
                value: data.newValue,
            })
        }
    }

    private onKeyDown = (event, data) => {
        const { onSelectName } = this.props
        const { id, personalid, value } = this.state
        event.persist()
        if (event.keyCode === 13) {
            onSelectName(value, id, personalid)
        }
    }

    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    private onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value),
        })
    }

    // Autosuggest will call this function every time you need to clear suggestions.
    private onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: [],
        })
    }
}
