import { KeyboardArrowDown, KeyboardArrowUp, UnfoldMoreTwoTone } from "@material-ui/icons/"
import { observer } from "mobx-react"
import * as React from "react"

import { ITableColumn } from "."

interface ITableHeaderProps {
    col: ITableColumn
    colSort?: boolean
    sortKey?: string
    onSort?: (key: string, sortDir: boolean) => void
    onSelectAll?: (state: boolean) => void
    // TODO set sort col and direction
    // onSetSortCol?: (col: boolean) => void
}
@observer
export default class TableHeader extends React.Component<ITableHeaderProps, {}> {
    public render() {
        const { onSelectAll, col } = this.props
        const { valkey, label, sortable, className } = col
        if (valkey === "_sel" && onSelectAll != null) {
            return (
                <th key={valkey} className={className || ""}>
                    <input
                        type="checkbox"
                        onChange={(ev) => {
                            onSelectAll(ev.target.checked)
                        }}
                    ></input>
                </th>
            )
        }
        if (valkey === "_detail") {
            return (
                <th key={valkey} className={className || ""}>
                    <span className="detail-header"></span>
                </th>
            )
        }
        return (
            <th key={valkey} className={className || ""}>
                <div className="headercontents">{this.sortDirection(label, valkey, sortable)}</div>
            </th>
        )
    }

    public componentDidMount() {
        return
    }

    private changeSortDir(key: any) {
        if (this.props.onSort !== undefined) {
            this.props.onSort(key, !this.props.colSort)
        }
    }

    private sortDirection(label: string, valkey: string, sortable: boolean) {
        const scrollhoriz = label.length > 8
        if (sortable) {
            if (valkey === this.props.sortKey) {
                if (this.props.colSort) {
                    return (
                        <div className="sort-down">
                            <span
                                className={"sortableHeader" + scrollhoriz ? " wide-text" : ""}
                                onClick={() => {
                                    this.changeSortDir(valkey)
                                }}
                            >
                                {label}
                            </span>
                            <KeyboardArrowDown
                                className="sortdirection"
                                onClick={() => {
                                    this.changeSortDir(valkey)
                                }}
                            />
                        </div>
                    )
                } else if (!this.props.colSort) {
                    return (
                        <div className="sort-up">
                            <span
                                className={"sortableHeader" + scrollhoriz ? " wide-text" : ""}
                                onClick={() => {
                                    this.changeSortDir(valkey)
                                }}
                            >
                                {label}
                            </span>
                            <KeyboardArrowUp
                                className="sortdirection"
                                onClick={() => {
                                    this.changeSortDir(valkey)
                                }}
                            />
                        </div>
                    )
                }
            } else {
                return (
                    <div className="sort-possible">
                        <span
                            className={"sortableHeader" + scrollhoriz ? " wide-text" : ""}
                            onClick={() => {
                                this.changeSortDir(valkey)
                            }}
                        >
                            {label}
                        </span>
                        <UnfoldMoreTwoTone
                            className="sortdirection"
                            onClick={() => {
                                this.changeSortDir(valkey)
                            }}
                        />
                    </div>
                )
            }
        } else {
            return <p className={scrollhoriz ? " wide-text" : ""}>{label}</p>
        }
    }
}
