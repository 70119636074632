import { applySnapshot, Instance, types } from "mobx-state-tree"
import { get as oGet } from "object-path"

import { getlocations, ILocationApi } from "../api/locations"
import { sortFn } from "../utils/sort"

export const LOCATIONNAMES = [
    "HEART Regensburg",
    "MIA Nightclub Cham",
    "Cafe Zinnober Cham",
    "Cafe Zinnober Bad Kötzting",
    "",
    "HEART Regensburg",
]

export const LOCATIONS = {
    "1": "Heart Regensburg (alt)",
    "2": "MIA Nightclub",
    "3": "Cafe Zinnober Cham",
    "4": "Cafe Zinnober Bad Kötzting",
    "5": "Event Location",
    "6": "Heart Regensburg",
}

export interface ILocation extends Instance<typeof Location> {}
export interface ILocations extends Instance<typeof Locations> {}

let listInstance: ILocations

export const Location = types
    .model({
        locationID: types.string,
        locationName: types.string,
        locationShort: types.string,
        locationCity: types.string,
        positionIDs: types.string,
        defaultpay: types.string,
        umsatzanteilPerc: types.optional(types.number, 0.05),
    })
    .volatile((self) => ({
        selected: false,
    }))
    .actions((self) => {
        const actions = {
            select() {
                self.selected = true
            },
            unselect() {
                self.selected = false
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            get defaultPays(): string[] {
                return self.defaultpay.split(";")
            },
        }
        return views
    })

export const getSortEl = (item: ILocation) => {
    if (item.locationName != null) {
        return item.locationName || 0
    }
    return 0
}

const sortGetVAl = (obj: ILocation, options: string): null | number | string => {
    return oGet(obj, options)
}

// const loadItems = (): Promise<ILocationApi[]> => getlocations()

export interface IClientFilter {
    query?: string
    id?: string[]
    domain?: string
}

const filterItems = (items: ILocation[], query: string | null, sortCol: string, sortAsc: boolean): ILocation[] => {
    if (query == null || !query.length) {
        return items.sort(sortFn(sortGetVAl, sortAsc, sortCol))
    }
    return items
        .filter((el) => {
            // const q = query.toLowerCase()
            return false
        })
        .sort(sortFn(sortGetVAl, sortAsc, sortCol))
}

export const Locations = types
    .model({
        items: types.array(Location),
        filled: types.optional(types.boolean, false),
    })
    .volatile((self) => ({
        searchstring: "",
        filterObj: {},
        sortAsc: true,
        sortCol: "created",
        loaded: false,
    }))
    .actions((self) => {
        const actions = {
            setSearchString: (crit: string) => {
                return (self.searchstring = crit)
            },
            setSortCol: (col: string) => {
                return (self.sortCol = col)
            },
            setSortDir: (asc: boolean) => {
                return (self.sortAsc = asc)
            },
            setFilterObj: (fObj: IClientFilter) => {
                return (self.filterObj = fObj)
            },
            addItems: (item: ILocation) => {
                if (!actions.isContained(item.positionIDs)) {
                    self.items.push(item)
                }
            },
            clearList: () => {
                self.items.clear()
            },
            isContained: (id: string) => {
                let contains = 0
                self.items.toJSON().forEach((el) => {
                    if (el.positionIDs === id) {
                        contains++
                    }
                })
                return contains !== 0
            },
            setLoaded: () => {
                self.filled = true
            },
            load: () => {
                // if (guistate.client_id == null || loaded) {
                //     return
                // }
                try {
                    actions.clearList()
                    getlocations().then((res: ILocationApi[]) => {
                        // res.map((item) => actions.addItems(item))
                        applySnapshot(self, { items: res })
                        actions.setLoaded()
                    })
                } catch (err) {
                    console.error(err)
                }
            },
        }
        return actions
    })
    .views((self) => {
        const views = {
            count: () => {
                return self.items.length
            },
            find: (id: string): ILocation | null => {
                const res = self.items.filter((el) => el.locationID === id)
                return res.length > 0 ? res[0] : null
            },
            first: () => {
                return self.items[0]
            },
            filteroptions: () => {
                return []
            },
            filter: (query: string | null, sortCol: string) => {
                return filterItems(self.items, query, self.sortCol, self.sortAsc)
            },
            filtered: () => {
                return views.filter(self.searchstring, self.sortCol)
            },
            selected: () => {
                return views.filtered().filter((el) => el)
            },
        }
        return views
    })

listInstance = Locations.create({})
export default listInstance
// export default Locations
