import "./tagesabrechnung.scss"
import "react-toastify/dist/ReactToastify.css"

import {
    CheckBox,
    CheckBoxOutlineBlank,
    ChevronRight,
    ExpandMore,
    ToggleOffTwoTone,
    ToggleOnOutlined,
} from "@material-ui/icons"
import { bind } from "bind-decorator"
import { DateTime } from "luxon"
import { observer } from "mobx-react"
import React, { Component } from "react"
import { Redirect } from "react-router"
import { toast } from "react-toastify"

import { jumpTo } from "."
import { deleteAbrechnungItem, saveAbrechnung } from "../../api/savetagesabrechnung"
import { AutoSuggest } from "../../components/autosuggest"
import DetailView from "../../components/detailview"
import abrechnungen, { AbrechnungItem, IAbrechnungItem } from "../../stores/abrechnung"
import { IAbrechnungForm } from "../../stores/abrechnungforms"
import overviews, { Overview } from "../../stores/overviews"
import positions, { IPosition, UMSATZPOSITIONS } from "../../stores/positions"
import userdata from "../../stores/userdata"
import wallets, { IWallet, Wallet } from "../../stores/wallets"
import { getCookie } from "../../utils/cookiemanager"
import { isNum } from "../../utils/numvalidator"
import { IAbrechnungItemPos, renderAbrechnungData } from "./tablerowelem"
import guistate from "stores/guistate"

interface IEnterEinteilungProps {
    abrechnungid: string,
    // wallet: IWallet
    date: string,
    location: string,
    einteilung: IAbrechnungForm,
    position: IPosition,
    umsatzanteilPerc: number,
    entered: Array<{ positionID: string; personalID: string; starttime: string }>
}

interface IEditDataState {
    walletdata: {
        fivehundreds: number,
        twohundreds: number,
        hundreds: number,
        fifties: number,
        twenties: number,
        tens: number,
        fives: number,
        coins: number
    },
    unknownPersonal: boolean,
    formdata: IAbrechnungItemPos,
    paysopen: boolean,
    walletopen: boolean
}

@observer
class EditAbrechnungDetail extends Component<IEnterEinteilungProps, IEditDataState> {
    constructor(props: any) {
        super(props)
        const { position, entered } = this.props
        const abrData = renderAbrechnungData(position, entered)

        this.state = {
            formdata: {
                name: abrData.name,
                start: abrData.start,
                end: abrData.end,
                uberweisung: abrData.uberweisung,
                rechnung: abrData.rechnung,
                hours: abrData.hours,
                earnings: abrData.earnings,
                salary: abrData.salary,
                sum: abrData.sum,
                paidFrom: abrData.paidFrom,
                pays: abrData.pays,
                positionname: abrData.positionname,
                misc: abrData.misc
            },
            walletdata: {
                fivehundreds: 0,
                twohundreds: 0,
                hundreds: 0,
                fifties: 0,
                twenties: 0,
                tens: 0,
                fives: 0,
                coins: 0,
            },
            unknownPersonal: abrData.misc,
            paysopen: false,
            walletopen: false,
        }
        this.onSelectName = this.onSelectName.bind(this)
        this.renderEarningsEdit = this.renderEarningsEdit.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        const { einteilung, position, abrechnungid } = this.props
        const { unknownPersonal } = this.state
        const abrechnung = abrechnungen.get(abrechnungid)
        let wallet = wallets.get(position.positionID)
        const headerCls = position.positionType === "17" ? "misc-pos" : ""
        if (abrechnung) {
            if (!wallet) {
                wallet = Wallet.create({
                    positionID: abrechnung.positionID,
                    earnings: abrechnung.earnings,
                })
                wallets.addWallet(wallet)
            }

            return (
                <React.Fragment>
                    <DetailView
                        headerCls={headerCls}
                        header={() => this.renderHeader(abrechnung, position, wallet)}
                        content={() => this.renderInputs(abrechnung, wallet)}
                        onClose={() => jumpTo({ type: "viewlist", id: einteilung.einteilungID })}
                        onDelete={() => {
                            const id = abrechnung.id
                            deleteAbrechnungItem(id).then((res) => {
                                if (res === "success") {
                                    toast.success("Erfolgreich gelöscht", { autoClose: 2500 })
                                    jumpTo({ type: "viewlist", id: einteilung.einteilungID })
                                } else {
                                    toast.error("Löschen fehlgeschlagen", { autoClose: 2500 })
                                }
                            })
                        }}
                        onSave={() => {
                            abrechnungen.addSubjectToChange(abrechnung.positionID)
                            let counter = 0
                            const targetNum = abrechnungen.subjectsToChange.length
                            abrechnungen.subjectsToChange.forEach((el) => {
                                const toBeSaved = abrechnungen.get(el)
                                if (toBeSaved) {
                                    const abrItem = abrechnungen.get(position.positionID)
                                    let userid = "X"
                                    if (abrItem) {
                                        const personalid = abrItem.personalID
                                        const user = userdata.items.filter((el) => el.personalID === personalid && el.personalID !== "0")
                                        if (user.length > 0) {
                                            userid = user[0].userID
                                        }
                                    }
                                    const savedObj = { ...toBeSaved, savedBy: guistate.userID ?? "", savedAtDate: DateTime.local().toFormat("HH:mm dd.MM.yyyy"), userid }
                                    if (unknownPersonal) {
                                        savedObj.personalID = "629" // set to "Extern"
                                    }

                                    saveAbrechnung(savedObj).then((res) => {
                                        if (typeof res !== "string") {
                                            if (toBeSaved.id == null) {
                                                toBeSaved.setID(res + "")
                                            }
                                            counter++
                                            if (counter === targetNum) {
                                                abrechnungen.clearSubjects()

                                                toast.success("Erfolgreich gespeichert", { autoClose: 2500 })
                                                this.updateOverviewData(() =>
                                                    jumpTo({
                                                        type: "view",
                                                        id: einteilung.einteilungID,
                                                        posid: position.positionID,
                                                        location: einteilung.locationID,
                                                    }),
                                                )
                                            }
                                        } else {
                                            if (typeof res === "string" && res.includes("fail")) {
                                                if (res.includes("no_personal_id")) {
                                                    toast.error("Kein Mitarbeiter angegeben. Bitte prüfe die Eingabe", { autoClose: 2500 })
                                                } else {
                                                    toast.error("Eintrag konnte nicht gespeichert werden", { autoClose: 2500 })
                                                }
                                            }
                                            counter++
                                        }
                                    })
                                }
                            })
                        }}
                    />
                </React.Fragment>
            )
        } else {
            const newAbrechnung = AbrechnungItem.create({
                locationID: einteilung.locationID,
                date: einteilung.date,
                positionID: position.positionID,
                paidType: position.positionID,
                paidFromPosition: position.positionID,
                timestamp: DateTime.local().toMillis().toString(),
            })
            if (!wallet) {
                wallet = Wallet.create({
                    positionID: position.positionID,
                    earnings: "0",
                })
                wallets.addWallet(wallet)
            }

            return (
                <React.Fragment>
                    <DetailView
                        headerCls={headerCls}
                        header={() => this.renderHeader(newAbrechnung, position, wallet)}
                        content={() => this.renderInputs(newAbrechnung, wallet)}
                        onClose={() => jumpTo({ type: "viewlist", id: einteilung.einteilungID })}
                        onSave={() => {
                            let userid = "X"
                            const abrItem = abrechnungen.get(position.positionID)
                            if (abrItem) {
                                const personalid = abrItem.personalID
                                const user = userdata.items.filter((el) => el.personalID === personalid && el.personalID !== "0")
                                if (user.length > 0) {
                                    userid = user[0].userID
                                }
                            }
                            saveAbrechnung({ ...newAbrechnung, savedAtDate: DateTime.local().toFormat("HH:mm dd.MM.yyyy"), userid }).then((res) => {
                                jumpTo({
                                    type: "view",
                                    id: einteilung.einteilungID,
                                    posid: position.positionID,
                                    location: einteilung.locationID,
                                })
                            })
                        }}
                    />
                </React.Fragment>
            )
        }
    }

    private renderMiscTitle(abrechnung: IAbrechnungItem) {
        return (
            <input
                className="misc-title"
                value={abrechnung.miscTitle}
                placeholder={abrechnung.positionname}
                onChange={(ev) => {
                    ev.persist()
                    const val = ev.target.value
                    abrechnung.updateMiscTitle(val)
                }}
            />
        )
    }

    private renderMiscName(abrechnung: IAbrechnungItem) {
        return (
            <input
                type="text"
                className="misc-name"
                value={abrechnung.miscName}
                placeholder={"Name externe Person / Dienstleister"}
                onChange={(ev) => {
                    ev.persist()
                    const val = ev.target.value
                    abrechnung.updateMiscName(val)
                }}
            />
        )
    }

    @bind
    private renderAdminUserAdd(abrechnung: IAbrechnungItem, list, allowExternal) {
        const { unknownPersonal } = this.state
        const personalid = abrechnung.personalID
        const personal = userdata.items.filter((el) => el.personalID === personalid && el.personalID !== "" && el.personalID !== "0")
        if (personal.length > 0) {
            const name = personal[0].realName
            const userid = personal[0].userID
            return (
                <div className="nameinput">
                    <AutoSuggest
                        default={{ name, personalid, id: userid }}
                        onSelectName={this.onSelectName}
                        list={list}
                    />
                    {allowExternal && !unknownPersonal && <div className="misc-external-check">
                        <CheckBoxOutlineBlank
                            onClick={() => {
                                this.setState({
                                    unknownPersonal: true,
                                })
                            }}
                        />
                        <span>Extern</span>
                    </div>
                    }
                </div>
            )
        }
        return (
            <div className="nameinput">
                <AutoSuggest
                    default={{ name: "", personalid: "", id: "" }}
                    onSelectName={this.onSelectName}
                    list={list}
                />
                {allowExternal && !unknownPersonal && <div className="misc-external-check">
                    <CheckBoxOutlineBlank
                        onClick={() => {
                            this.setState({
                                unknownPersonal: true,
                            })
                        }}
                    />
                    <span>Extern</span>
                </div>
                }
            </div>
        )
    }

    private onSelectName(name, id, personalid) {
        const { abrechnungid } = this.props
        const abrechnung = abrechnungen.get(abrechnungid)
        if (abrechnung) {
            abrechnung.changePersonalID(personalid)
        }
    }

    private updateOverviewData(onDone: () => void) {
        const { location, date } = this.props
        let overview = overviews.findByLocAndDate(location, date)
        if (!overview) {
            const userid = getCookie("userid")
            overview = Overview.create({
                locationID: location,
                date,
                savedBy: userid,
            })
        }
        overview.updateEarnings()
        overview.updateGuestCount()
        overview.updateSalaries()
        overview.save(onDone)
    }

    private renderHeader(abrechnung: IAbrechnungItem, position: IPosition, wallet) {
        const { unknownPersonal } = this.state
        if (position.positionType === "16") {
            abrechnung.changePersonalID("471")
        }
        if (position.positionType === "18") {
            abrechnung.changePersonalID("628")
        }
        const abrItem = abrechnungen.get(position.positionID)
        const usernames = userdata.items.map((el) => {
            return { name: el.realName, id: el.userID, personalid: el.personalID }
        })
        // let name = ""
        if (abrItem) {
            const personalid = abrItem.personalID
            const user = userdata.items.filter((el) => el.personalID === personalid && el.personalID !== "0")
            if (user.length > 0) {
                // name = user[0].realName
            }
        }
        return (
            <React.Fragment>
                {position.positionType === "17" && this.renderMiscTitle(abrechnung)}
                {position.positionType !== "17" && <div>
                    <span>{position.positionName}</span>
                </div>}

                {position.positionType === "17" && unknownPersonal && <div className="nameinput">
                    {this.renderMiscName(abrechnung)}
                    <div className="misc-external-check">
                        {unknownPersonal && (
                            <CheckBox
                                onClick={() => {
                                    this.setState({
                                        unknownPersonal: false,
                                    })
                                }}
                            />
                        )}
                        <span>Extern</span>
                    </div>
                </div>}
                {!unknownPersonal && this.renderAdminUserAdd(abrechnung, usernames, position.positionType === "17")}
                {/* <input value={ name } onChange={() => {

                }} className="abrechnung-item-name"></input> */}
                <div>
                    {this.renderUberweisungToggle(abrechnung, wallet)}
                    {this.renderRechnungToggle(abrechnung, wallet)}
                    {this.renderCvdToggle(abrechnung, wallet)}
                </div>
            </React.Fragment>
        )
    }

    private renderHoursEdit(abrechnung: IAbrechnungItem) {
        const { start, end, hours } = abrechnung

        return (
            <div className="hours-calc">
                <input
                    type={"time"}
                    value={start}
                    onChange={(ev) => {
                        ev.persist()
                        let input = ev.target.value
                        if (input === "") {
                            input = "00:00"
                        }
                        abrechnung.setStart(input)
                    }}
                />
                <br></br>
                <input
                    type={"time"}
                    value={end}
                    onChange={(ev) => {
                        ev.persist()
                        let input = ev.target.value
                        if (input === "") {
                            input = "00:00"
                        }
                        abrechnung.setEnd(input)
                    }}
                />
                <br></br>
                <input
                    type={"number"}
                    value={hours}
                    onChange={(ev) => {
                        ev.persist()
                        const rawinput = ev.target.value
                        let input = "0"
                        if (rawinput !== "") {
                            input = parseFloat(rawinput).toString(2)
                        }
                        abrechnung.setHours(input)
                    }}
                />
                <br></br>
            </div>
        )
    }

    private renderEarningsEdit(abrechnung: IAbrechnungItem, wallet: IWallet): JSX.Element | undefined {
        const { walletopen } = this.state
        const { fivehundreds, twohundreds, hundreds, fifties, twenties, tens, fives, coins, billsum } = wallet
        // scrollable
        return (
            <React.Fragment>
                {walletopen && (
                    <div>
                        <div
                            className="toggle-line"
                            onClick={() => {
                                this.setState({
                                    walletopen: false,
                                })
                            }}
                        >
                            <ExpandMore></ExpandMore>
                            <span>Rechenhilfe</span>
                        </div>
                        <div className="wallet-calc">
                            <hr></hr>
                            <div className="wallet-calc-input">
                                <div className="calc-label">Münzgeld</div>
                                <input
                                    type="text"
                                    value={coins}
                                    onChange={(ev) => {
                                        ev.persist()
                                        let input = ev.target.value
                                        if (input === "") {
                                            input = "0"
                                        }
                                        if (isNum(input)) {
                                            wallet.setCoins(input)
                                        }
                                    }}
                                ></input>
                                <span>{coins} €</span>
                            </div>
                            <hr></hr>
                            <div className="wallet-calc-input">
                                <div className="calc-label">500€</div>
                                <input
                                    type={"number"}
                                    value={fivehundreds}
                                    onChange={(ev) => {
                                        ev.persist()
                                        let input = ev.target.value
                                        if (input === "") {
                                            input = "0"
                                        }
                                        wallet.setFivehundreds(parseInt(input, 10))
                                    }}
                                ></input>
                                <span>{500 * fivehundreds} €</span>
                            </div>
                            <div className="wallet-calc-input">
                                <div className="calc-label">200€</div>
                                <input
                                    type={"number"}
                                    value={twohundreds}
                                    onChange={(ev) => {
                                        ev.persist()
                                        let input = ev.target.value
                                        if (input === "") {
                                            input = "0"
                                        }
                                        wallet.setTwohundreds(parseInt(input, 10))
                                    }}
                                ></input>
                                <span>{200 * twohundreds} €</span>
                            </div>
                            <div className="wallet-calc-input">
                                <div className="calc-label">100€</div>
                                <input
                                    type={"number"}
                                    value={hundreds}
                                    onChange={(ev) => {
                                        ev.persist()
                                        let input = ev.target.value
                                        if (input === "") {
                                            input = "0"
                                        }
                                        wallet.setHundreds(parseInt(input, 10))
                                    }}
                                ></input>
                                <span>{100 * hundreds} €</span>
                            </div>
                            <div className="wallet-calc-input">
                                <div className="calc-label">50€</div>
                                <input
                                    type={"number"}
                                    value={fifties}
                                    onChange={(ev) => {
                                        ev.persist()
                                        let input = ev.target.value
                                        if (input === "") {
                                            input = "0"
                                        }
                                        wallet.setFifties(parseInt(input, 10))
                                    }}
                                ></input>
                                <span>{50 * fifties} €</span>
                            </div>
                            <div className="wallet-calc-input">
                                <div className="calc-label">20€</div>
                                <input
                                    type={"number"}
                                    value={twenties}
                                    onChange={(ev) => {
                                        ev.persist()
                                        let input = ev.target.value
                                        if (input === "") {
                                            input = "0"
                                        }
                                        wallet.setTwenties(parseInt(input, 10))
                                    }}
                                ></input>
                                <span>{20 * twenties} €</span>
                            </div>
                            <div className="wallet-calc-input">
                                <div className="calc-label">10€</div>
                                <input
                                    type={"number"}
                                    value={tens}
                                    onChange={(ev) => {
                                        ev.persist()
                                        let input = ev.target.value
                                        if (input === "") {
                                            input = "0"
                                        }
                                        wallet.setTens(parseInt(input, 10))
                                    }}
                                ></input>
                                <span>{10 * tens} €</span>
                            </div>
                            <div className="wallet-calc-input">
                                <div className="calc-label">5€</div>
                                <input
                                    type={"number"}
                                    value={fives}
                                    onChange={(ev) => {
                                        ev.persist()
                                        let input = ev.target.value
                                        if (input === "") {
                                            input = "0"
                                        }
                                        wallet.setFives(parseInt(input, 10))
                                    }}
                                ></input>
                                <span>{5 * fives} €</span>
                            </div>
                            <div className="wallet-calc-input">
                                <div className="calc-label">Scheine Gesamt</div>
                                <input
                                    type={"number"}
                                    value={billsum}
                                    onChange={(ev) => {
                                        ev.persist()
                                        let input = ev.target.value
                                        if (input === "") {
                                            input = "0"
                                        }
                                        wallet.setBillsum(parseInt(input, 10))
                                    }}
                                ></input>
                            </div>
                        </div>
                    </div>
                )}
                {!walletopen && (
                    <div
                        className="toggle-line"
                        onClick={() => {
                            this.setState({
                                walletopen: true,
                            })
                        }}
                    >
                        <ChevronRight></ChevronRight>
                        <span>Rechenhilfe</span>
                    </div>
                )}
            </React.Fragment>
        )
    }

    private renderPaidFrom(position, abrechnungitem: IAbrechnungItem, mode): JSX.Element | undefined {
        const posItem = positions.find(position)

        const possiblePositions = positions.items
            .filter((el) => el.modes.includes(mode))
            .filter((el) => UMSATZPOSITIONS.includes(el.positionID))
            .filter((el) => el.locationID === abrechnungitem.locationID)
        if (posItem) {
            return (
                <select
                    defaultValue={posItem.positionID}
                    onChange={(ev) => {
                        ev.persist()
                        abrechnungen.changePays(abrechnungitem.positionID, ev.target.value)
                    }}
                >
                    {possiblePositions.map((el, idx) => {
                        return (
                            <option key={idx} value={el.positionID}>
                                {el.positionName}
                            </option>
                        )
                    })}
                </select>
            )
        }
        return
    }

    private renderPays(abrechnung: IAbrechnungItem, wallet) {
        const { paysopen } = this.state
        const { position } = this.props
        const abrData = abrechnungen.items.filter((el) => {
            return el.paidFromPosition === this.props.position.positionID
        })
        return (
            <div>
                {paysopen && (
                    <div
                        className="toggle-line"
                        onClick={() => {
                            this.setState({
                                paysopen: false,
                            })
                        }}
                    >
                        <ExpandMore></ExpandMore>
                        <span>Aus Geldbeutel bezahlt: {abrechnung.payssum()} €</span>
                        <ul className="pays-list">
                            {abrData.map((el, idx) => {
                                const user = userdata.items.filter((usr) => usr.personalID === el.personalID)
                                if (user.length > 0) {
                                    if (el.positionID !== position.positionID) {
                                        return (
                                            <li key={idx}>
                                                <div className="pays-position">
                                                    {el.miscTitle === "" ? el.positionname : el.miscTitle}:{" "}
                                                </div>
                                                <div className="pays-name">{user[0].realName}</div>
                                                <div className="pays-salary">{el.salary} €</div>
                                            </li>
                                        )
                                    }
                                }
                                return <></>
                            })}
                        </ul>
                    </div>
                )}
                {!paysopen && (
                    <div
                        className="toggle-line"
                        onClick={() => {
                            this.setState({
                                paysopen: true,
                            })
                        }}
                    >
                        <ChevronRight></ChevronRight>
                        <span>Aus Geldbeutel bezahlt: {abrechnung.payssum()} €</span>
                    </div>
                )}
            </div>
        )
    }

    private renderRechnungToggle(abrechnung: IAbrechnungItem, wallet) {
        return (
            <div className="rechnung-toggle">
                {abrechnung.rechnung === "1" && (
                    <ToggleOffTwoTone
                        onClick={() => {
                            abrechnung.setRechnung("0")
                        }}
                    ></ToggleOffTwoTone>
                )}
                {abrechnung.rechnung !== "1" && (
                    <ToggleOnOutlined
                        onClick={() => {
                            abrechnung.setRechnung("1")
                        }}
                    ></ToggleOnOutlined>
                )}
                <span>{abrechnung.rechnung === "1" ? "Rechnung" : "ohne Rechnung"}</span>
            </div>
        )
    }

    private renderCvdToggle(abrechnung: IAbrechnungItem, wallet) {
        return (
            <div className="cvd-toggle">
                {abrechnung.iscvd === "1" && (
                    <ToggleOffTwoTone
                        onClick={() => {
                            abrechnung.setCvd("0")
                        }}
                    ></ToggleOffTwoTone>
                )}
                {abrechnung.iscvd !== "1" && (
                    <ToggleOnOutlined
                        onClick={() => {
                            abrechnung.setCvd("1")
                        }}
                    ></ToggleOnOutlined>
                )}
                <span>{abrechnung.iscvd === "1" ? "CVD" : "kein CVD"}</span>
            </div>
        )
    }

    private renderUberweisungToggle(abrechnung: IAbrechnungItem, wallet) {
        return (
            <div className="uberweisung-toggle">
                {abrechnung.ueberweisung === "1" && (
                    <ToggleOnOutlined
                        onClick={() => {
                            abrechnung.setUberweisung("0")
                            abrechnungen.changePays(abrechnung.positionID, abrechnung.positionID)
                        }}
                    />
                )}
                {abrechnung.ueberweisung !== "1" && (
                    <ToggleOffTwoTone
                        onClick={() => {
                            abrechnung.setUberweisung("1")
                            abrechnungen.changePays(abrechnung.positionID, abrechnung.positionID)
                        }}
                    />
                )}
                <span>{abrechnung.ueberweisung === "1" ? "Überweisung" : "Lohn in Bar"}</span>
            </div>
        )
    }

    private renderLohnResult(abrechnung: IAbrechnungItem, wallet, position: IPosition) {
        return (
            <div className="wallet-calc-input">
                <div className="calc-wrp">
                    <div className="calc-label wage">Stundenlohn</div>
                    <input
                        className="wageinput"
                        type="text"
                        value={
                            abrechnung.customwage === "0"
                                ? position.stundenlohnFull().toString()
                                : abrechnung.customwage
                        }
                        onChange={(ev: any) => {
                            ev.persist()
                            let input = ev.target.value
                            if (input === "") {
                                input = "0"
                            }
                            if (isNum(input)) {
                                abrechnung.setCustomWage(input)
                            }
                        }}
                    ></input>
                </div>
                <br></br>
                <div className="calc-wrp">
                    <div className="calc-label salary">Lohn</div>
                    <input
                        className="salaryinput"
                        type="text"
                        value={abrechnung.salary}
                        onChange={(ev: any) => {
                            ev.persist()
                            let input = ev.target.value
                            if (input === "") {
                                input = "0"
                            }
                            if (isNum(input)) {
                                abrechnung.setSalary(input, true)
                                abrechnungen.changePays(abrechnung.paidFromPosition, abrechnung.paidFromPosition)
                            }
                        }}
                    ></input>
                </div>
            </div>
        )
    }

    private renderGesamtsumme(abrechnung: IAbrechnungItem, wallet) {
        return (
            <div className="wallet-calc-input">
                <div className="calc-label">Summe</div>
                <input
                    type="text"
                    value={abrechnung.sum}
                    onChange={(ev: any) => {
                        ev.persist()
                        let input = ev.target.value
                        if (input === "") {
                            input = "0"
                        }
                        if (isNum(input)) {
                            abrechnung.setSum(input, true)
                        }
                    }}
                ></input>
            </div>
        )
    }

    private renderInputs(abrechnung: IAbrechnungItem, wallet): JSX.Element {
        const { position, einteilung } = this.props
        return (
            <div className="inputs-area">
                {position.haswallet() && this.renderPays(abrechnung, wallet)}
                {this.renderHoursEdit(abrechnung)}
                {position.haswallet() && this.renderEarningsEdit(abrechnung, wallet)}
                <hr></hr>
                {position.haswallet() && (
                    <React.Fragment>
                        <div className="wallet-calc-input">
                            <div className="calc-label">Gesamtumsatz</div>
                            <input
                                type="text"
                                value={abrechnung.earnings}
                                onChange={(ev) => {
                                    ev.persist()
                                    let input = ev.target.value
                                    if (input === "") {
                                        input = "0"
                                    }
                                    if (isNum(input)) {
                                        abrechnung.setEarnings(input, true)
                                    }
                                }}
                            />
                        </div>
                        <hr />
                    </React.Fragment>
                )}
                <React.Fragment>{abrechnung.ueberweisung !== "1" && this.renderPaidFrom(abrechnung.paidFromPosition, abrechnung, einteilung.mode)}</React.Fragment>
                {this.renderLohnResult(abrechnung, wallet, position)}
                {position.haswallet() && this.renderGesamtsumme(abrechnung, wallet)}
            </div>
        )
    }
}

export default EditAbrechnungDetail
