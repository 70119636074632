import { DateTime } from "luxon"
import { observer } from "mobx-react"
import React, { Component } from "react"
import { Redirect } from "react-router"

import { jumpTo } from "."
import HeaderFilter from "../../components/Headerfilter"
import Loading from "../../components/loading"
import Table from "../../components/table"
import einteilungforms, { IAbrechnungForm } from "../../stores/abrechnungforms"
import guistate from "../../stores/guistate"
import locations from "../../stores/locations"
import overviews from "../../stores/overviews"
import { getCookie } from "../../utils/cookiemanager"

interface IOverviewListState {
    clubfilter: string[]
}

export const renderTableContentWCurrency = (obj, key) => {
    return <span className="currency">{obj[key]}€</span>
}

@observer
class OverviewList extends Component<{}, IOverviewListState> {
    constructor(props: any) {
        super(props)

        this.state = {
            clubfilter: ["1", "2", "3", "4", "6"],
        }

        this.filter = this.filter.bind(this)
        this.confirmFilter = this.confirmFilter.bind(this)
    }

    public render() {
        if (getCookie("token").length === 0) {
            return <Redirect to="/"></Redirect>
        }
        const { clubfilter } = this.state
        const { columns, data } = overviews.tableView()
        return (
            <React.Fragment>
                {!overviews.loaded && <Loading />}
                <HeaderFilter
                    filteroptions={einteilungforms.filteroptions(false, false)}
                    currentstate={{
                        Club: clubfilter,
                    }}
                    onConfirm={this.confirmFilter}
                    onDateConfirm={this.onDateConfirm}
                    onOptionFilter={this.filter}
                    withdatefilter={true}
                ></HeaderFilter>
                {
                    <div className={"large-table"}>
                        <Table
                            columns={columns}
                            data={data}
                            onSort={this.onSort}
                            // unfolded={tag.unfolded}
                            sortKey={einteilungforms.sortCol}
                            colSort={einteilungforms.sortAsc}
                        // onOpen={this.onOpen}
                        ></Table>
                    </div>
                }
            </React.Fragment>
        )
    }

    public componentDidMount() {
        if (!locations.loaded) {
            locations.load()
        }
        if (einteilungforms.loaded) {
            einteilungforms.setFilterObj({ status: ["0", "1", "2"] })
        } else {
            einteilungforms.load({})
        }
    }

    private onSort(key: string, dir: boolean) {
        einteilungforms.setSortCol(key)
        einteilungforms.setSortDir(dir)
    }

    private onOpen(item: IAbrechnungForm) {
        jumpTo({ type: "view", id: item.einteilungID })
    }

    private onDateConfirm(data) {
        let from = Math.round(DateTime.fromISO(data.from).toMillis() / 1000)
        let to = Math.round(DateTime.fromISO(data.to).toMillis() / 1000)
        if (data.from == null) {
            from = Math.round(DateTime.local().plus({ week: 2 }).toMillis() / 1000)
        }
        if (data.to == null) {
            to = Math.round(DateTime.local().minus({ week: 6 }).toMillis() / 1000)
        }
        guistate.setFrom(from)
        guistate.setTo(to)
        overviews.load({ from, to })
        // console.log(from, to)
    }

    private filter(filterstr: string, add: boolean, truncate: boolean, source: string) {
        const { clubfilter } = this.state
        const newfilter: string[] = []
        clubfilter.forEach((el) => {
            newfilter.push(el)
        })

        if (truncate) {
            this.setState({
                clubfilter: [],
            })
        } else {
            if (source === "Club") {
                if (add) {
                    newfilter.push(filterstr)
                    this.setState({
                        clubfilter: newfilter,
                    })
                } else if (!add) {
                    newfilter.splice(newfilter.indexOf(filterstr), 1)
                    this.setState({
                        clubfilter: newfilter,
                    })
                }
            }
        }
    }

    private confirmFilter(filter) {
        overviews.setFilterObj({
            locationIDs: filter.Club,
        })
    }
}

export default OverviewList
