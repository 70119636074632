import $ from "jquery"

import { getCookie } from "../utils/cookiemanager"

export interface IOverviewApi {
    overviewID: string
    date: string
    locationID: string
    guestcount: string
    guestlistcount: string
    entrancePrice: string
    earnings: string
    pku: string
    entranceEarnings: string
    eventName: string
    gainPositions: string
    salaries: string
    barrechnungen: string
    garderobeneinnahmen: string
    gastroumsatz: string
    cardpayments: string
    personalverzehr: string
    savedBy: string
}

// export interface ILocationResponse {
//     response: ILocation
// }

export interface ISaveNoteApi {
    noteID: string
    date: string
    locationID: string
    content: string
    userID: string
}

export const savenotes = (data: ISaveNoteApi): Promise<any> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=savenotes"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `saveNotesTest`,
                noteID: data.noteID,
                date: data.date,
                locationID: data.locationID,
                content: data.content,
                userID: data.userID,
                type: "json",
            },
            dataType: "json",
        })
            .then((res, err, result) => {
                resolve(result)
            })
            .catch((xy: any) => resolve(xy.responseText))
    })
}

export const getnotes = (locationID, date): Promise<any[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getnotes"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getNotesTest`,
                locationID,
                date,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}
