import React, { Component } from "react"
import { observer } from "mobx-react"
import "./loading.scss"
import LoadingIcon from "../../img/25.svg"



@observer
class Loading extends Component<{}, {}> {

    public render() {
        return (
            <>
                <img alt={"Lädt..."} src={LoadingIcon} />
            </>
        )
    }
}

export default Loading
