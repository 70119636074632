import $ from "jquery"

import { getCookie } from "../utils/cookiemanager"

export interface ILocationApi {
    locationID: string
    locationName: string
    locationShort: string
    locationCity: string
    positionIDs: string
    defaultpay: string
}

// export interface ILocationResponse {
//     response: ILocation
// }

export const getlocations = (): Promise<ILocationApi[]> => {
    const url = "https://aurora.uber.space/zinnoberapi/dbconnection/db.php?type=getlocations"
    return new Promise((resolve, reject) => {
        $.ajax({
            url,
            type: "POST",
            crossDomain: true,
            data: {
                stoken: getCookie("token"),
                action: `getLocations`,
                type: "json",
            },
            dataType: "json",
        }).then((res, err, result) => {
            resolve(result)
        })
    })
}
